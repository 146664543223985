export const bodyStyleMap = {
  Sedan: 'Car',
  Hatchback: 'Car',
  Coupe: 'Car',
  Convertible: 'Car',
  Wagon: 'Car',
  Pickup: 'Truck',
  'Van/Minivan': 'Van',
  SUV: 'SUV'
};

export const colorList = [
  'Black',
  'White',
  'Gray',
  'Silver',
  'Red',
  'Orange',
  'Blue',
  'Green',
  'Yellow',
  'Brown',
  'Tan',
  'Gold',
  'Purple'
];

function getVehicleList(year) {
  let _vehicleList = vehicleList.filter((vehicle) => vehicle.year == year);

  if (!_vehicleList.length) {
    _vehicleList = vehicleList;
  }

  return _vehicleList;
}

export function getMakeList(year) {
  let makeDictionary = {};
  let makeList = [];

  getVehicleList(year).map((vehicle) => {
    if (!makeDictionary[vehicle.make]) {
      makeDictionary[vehicle.make] = true;
      makeList.push(vehicle.make);
    }
  });

  return makeList.sort((a, b) => a.localeCompare(b));
}

export function getModelList(year, make) {
  let modelDictionary = {};
  let modelList = [];

  getVehicleList(year)
    .filter((vehicle) => vehicle.make === make)
    .map((vehicle) => {
      if (!modelDictionary[vehicle.model]) {
        modelDictionary[vehicle.model] = true;
        modelList.push(vehicle.model);
      }
    });

  return modelList.sort((a, b) => a.localeCompare(b));
}

export function getYearList() {
  let _yearList = [];

  for (let year = new Date().getFullYear() + 1; year >= 1900; year--) {
    _yearList.push(year);
  }

  return _yearList;
}

export const vehicleTypeList = ['Car', 'Truck', 'SUV', 'Van', 'Motorcycle'];

export const vehicleList = [
  {
    year: 1992,
    make: 'Acura',
    model: 'Integra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 1992,
    make: 'Acura',
    model: 'Legend',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1992,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Acura',
    model: 'Vigor',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Alfa Romeo',
    model: '164',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Alfa Romeo',
    model: 'Spider',
    body_styles: ['Convertible']
  },
  {
    year: 1992,
    make: 'Audi',
    model: '100',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Audi',
    model: '80',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Audi',
    model: 'Quattro',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 1992,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'BMW',
    model: '8 Series',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1992,
    make: 'Buick',
    model: 'Riviera',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Buick',
    model: 'Roadmaster',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Buick',
    model: 'Skylark',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1992,
    make: 'Cadillac',
    model: 'Allante',
    body_styles: ['Convertible']
  },
  {
    year: 1992,
    make: 'Cadillac',
    model: 'Brougham',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1992,
    make: 'Cadillac',
    model: 'Eldorado',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Cadillac',
    model: 'Fleetwood',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1992,
    make: 'Cadillac',
    model: 'Seville',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: '1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: '2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: '3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'APV Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Beretta',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Caprice',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Coupe', 'Sedan', 'Wagon', 'Convertible']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Corsica',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'G-Series G10',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'G-Series G20',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'G-Series G30',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Lumina',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Lumina APV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'S10 Blazer',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'S10 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'S10 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Sportvan G10',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Sportvan G20',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Sportvan G30',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Chrysler',
    model: 'Fifth Ave',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Chrysler',
    model: 'Imperial',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Chrysler',
    model: 'LeBaron',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 1992,
    make: 'Chrysler',
    model: 'New Yorker',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Daihatsu',
    model: 'Charade',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 1992,
    make: 'Daihatsu',
    model: 'Rocky',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Colt',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'D150 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'D150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'D250 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'D250 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'D350 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'D350 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Dakota Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Daytona',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Dynasty',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Monaco',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Ram 50 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Ram Van B150',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Ram Van B250',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Ram Van B350',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Ram Wagon B150',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Ram Wagon B250',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Ram Wagon B350',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Ramcharger',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Shadow',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Spirit',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Stealth',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Convertible']
  },
  {
    year: 1992,
    make: 'Eagle',
    model: 'Premier',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Eagle',
    model: 'Summit',
    body_styles: ['Sedan', 'Hatchback', 'Wagon']
  },
  {
    year: 1992,
    make: 'Eagle',
    model: 'Talon',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Aerostar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Aerostar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Bronco',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Club Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Econoline E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Econoline E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Econoline E350 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Escort',
    body_styles: ['Hatchback', 'Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'F250 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'F250 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'F350 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'F350 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'F350 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Festiva',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Probe',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Tempo',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1992,
    make: 'Ford',
    model: 'Thunderbird',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Geo',
    model: 'Metro',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1992,
    make: 'Geo',
    model: 'Prizm',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Geo',
    model: 'Storm',
    body_styles: ['Coupe', 'Hatchback']
  },
  {
    year: 1992,
    make: 'Geo',
    model: 'Tracker',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'GMC',
    model: '1500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'GMC',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'GMC',
    model: '2500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'GMC',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'GMC',
    model: '3500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'GMC',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'GMC',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Jimmy',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Rally Wagon 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Rally Wagon 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Rally Wagon 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Sonoma Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Sonoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Vandura 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Vandura 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Vandura 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Coupe', 'Wagon', 'Sedan']
  },
  {
    year: 1992,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1992,
    make: 'Honda',
    model: 'Prelude',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Hyundai',
    model: 'Excel',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 1992,
    make: 'Hyundai',
    model: 'Scoupe',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'INFINITI',
    model: 'M',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1992,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Isuzu',
    model: 'Amigo',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Isuzu',
    model: 'Impulse',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Isuzu',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Isuzu',
    model: 'Rodeo',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Isuzu',
    model: 'Spacecab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Isuzu',
    model: 'Stylus',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Isuzu',
    model: 'Trooper',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 1992,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Jeep',
    model: 'Comanche Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Lincoln',
    model: 'Mark VII',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'MAZDA',
    model: '323',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'MAZDA',
    model: '626',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'MAZDA',
    model: '929',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'MAZDA',
    model: 'B-Series Cab Plus',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'MAZDA',
    model: 'MX-3',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 1992,
    make: 'MAZDA',
    model: 'MX-6',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'MAZDA',
    model: 'Navajo',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'MAZDA',
    model: 'Protege',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '190 E',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '300 CE',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '300 D',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '300 E',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '300 SD',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '300 SE',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '300 SL',
    body_styles: ['Convertible']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '300 TE',
    body_styles: ['Wagon']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '400 E',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '400 SE',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '500 E',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '500 SEL',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '500 SL',
    body_styles: ['Convertible']
  },
  {
    year: 1992,
    make: 'Mercedes-Benz',
    model: '600 SEL',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mercury',
    model: 'Capri',
    body_styles: ['Convertible']
  },
  {
    year: 1992,
    make: 'Mercury',
    model: 'Cougar',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Mercury',
    model: 'Topaz',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1992,
    make: 'Mercury',
    model: 'Tracer',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Mitsubishi',
    model: '3000GT',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Mitsubishi',
    model: 'Diamante',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Mitsubishi',
    model: 'Expo',
    body_styles: ['Wagon']
  },
  {
    year: 1992,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Mitsubishi',
    model: 'Mighty Max Macro Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Mitsubishi',
    model: 'Mighty Max Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1992,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Mitsubishi',
    model: 'Precis',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Nissan',
    model: '240SX',
    body_styles: ['Hatchback', 'Coupe', 'Convertible']
  },
  {
    year: 1992,
    make: 'Nissan',
    model: '300ZX',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Nissan',
    model: 'King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Nissan',
    model: 'NX',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Nissan',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1992,
    make: 'Nissan',
    model: 'Stanza',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Oldsmobile',
    model: '88',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Oldsmobile',
    model: '98',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Oldsmobile',
    model: 'Achieva',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1992,
    make: 'Oldsmobile',
    model: 'Bravada',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Oldsmobile',
    model: 'Custom Cruiser',
    body_styles: ['Wagon']
  },
  {
    year: 1992,
    make: 'Oldsmobile',
    model: 'Cutlass Ciera',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Oldsmobile',
    model: 'Cutlass Supreme',
    body_styles: ['Sedan', 'Convertible', 'Coupe']
  },
  {
    year: 1992,
    make: 'Oldsmobile',
    model: 'Silhouette',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Oldsmobile',
    model: 'Toronado',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Plymouth',
    model: 'Acclaim',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Plymouth',
    model: 'Colt',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Plymouth',
    model: 'Colt Vista',
    body_styles: ['Wagon']
  },
  {
    year: 1992,
    make: 'Plymouth',
    model: 'Grand Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Plymouth',
    model: 'Laser',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Plymouth',
    model: 'Sundance',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Plymouth',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Pontiac',
    model: 'Firebird',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1992,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1992,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1992,
    make: 'Pontiac',
    model: 'LeMans',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1992,
    make: 'Pontiac',
    model: 'Sunbird',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 1992,
    make: 'Pontiac',
    model: 'Trans Sport',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1992,
    make: 'Porsche',
    model: '968',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1992,
    make: 'Saab',
    model: '900',
    body_styles: ['Sedan', 'Hatchback', 'Convertible']
  },
  {
    year: 1992,
    make: 'Saab',
    model: '9000',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 1992,
    make: 'Saturn',
    model: 'S-Series',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1992,
    make: 'Subaru',
    model: 'Justy',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Subaru',
    model: 'Loyale',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Subaru',
    model: 'SVX',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Suzuki',
    model: 'Samurai',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Suzuki',
    model: 'Sidekick',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Suzuki',
    model: 'Swift',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 1992,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Coupe', 'Convertible', 'Hatchback']
  },
  {
    year: 1992,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Toyota',
    model: 'Cressida',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 1992,
    make: 'Toyota',
    model: 'MR2',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Toyota',
    model: 'Paseo',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Toyota',
    model: 'Previa',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1992,
    make: 'Toyota',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Toyota',
    model: 'Supra',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Toyota',
    model: 'Tercel',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Toyota',
    model: 'Xtra Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1992,
    make: 'Volkswagen',
    model: 'Cabriolet',
    body_styles: ['Convertible']
  },
  {
    year: 1992,
    make: 'Volkswagen',
    model: 'Corrado',
    body_styles: ['Coupe']
  },
  {
    year: 1992,
    make: 'Volkswagen',
    model: 'Fox',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1992,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 1992,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 1992,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Volvo',
    model: '240',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1992,
    make: 'Volvo',
    model: '740',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1992,
    make: 'Volvo',
    model: '940',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1992,
    make: 'Volvo',
    model: '960',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Acura',
    model: 'Integra',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1993,
    make: 'Acura',
    model: 'Legend',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1993,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Acura',
    model: 'Vigor',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Alfa Romeo',
    model: '164',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Alfa Romeo',
    model: 'Spider',
    body_styles: ['Convertible']
  },
  {
    year: 1993,
    make: 'Audi',
    model: '100',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Audi',
    model: '90',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Audi',
    model: 'Quattro',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 1993,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1993,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'BMW',
    model: '8 Series',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Sedan', 'Wagon', 'Coupe']
  },
  {
    year: 1993,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1993,
    make: 'Buick',
    model: 'Riviera',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Buick',
    model: 'Roadmaster',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Buick',
    model: 'Skylark',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1993,
    make: 'Cadillac',
    model: 'Allante',
    body_styles: ['Convertible']
  },
  {
    year: 1993,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1993,
    make: 'Cadillac',
    model: 'Eldorado',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Cadillac',
    model: 'Fleetwood',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Cadillac',
    model: 'Seville',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Cadillac',
    model: 'Sixty Special',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: '1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: '2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: '3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'APV Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Beretta',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Caprice Classic',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Coupe', 'Sedan', 'Wagon', 'Convertible']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Corsica',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'G-Series G10',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'G-Series G20',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'G-Series G30',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Lumina',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Lumina APV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'S10 Blazer',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'S10 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'S10 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Sportvan G10',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Sportvan G20',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Sportvan G30',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Chrysler',
    model: 'Concorde',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Chrysler',
    model: 'Fifth Ave',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Chrysler',
    model: 'Imperial',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Chrysler',
    model: 'LeBaron',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 1993,
    make: 'Chrysler',
    model: 'New Yorker',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Colt',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'D150 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'D150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'D250 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'D250 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'D350 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'D350 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Dakota Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Daytona',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Dynasty',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Intrepid',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Ram 50 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Ram Van B150',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Ram Van B250',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Ram Van B350',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Ram Wagon B150',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Ram Wagon B250',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Ram Wagon B350',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Ramcharger',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Shadow',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Spirit',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Stealth',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Convertible']
  },
  {
    year: 1993,
    make: 'Eagle',
    model: 'Summit',
    body_styles: ['Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 1993,
    make: 'Eagle',
    model: 'Talon',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Eagle',
    model: 'Vision',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Aerostar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Aerostar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Bronco',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Club Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Econoline E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Econoline E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Econoline E350 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Escort',
    body_styles: ['Hatchback', 'Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'F250 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'F250 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'F350 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'F350 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'F350 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Festiva',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Probe',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Tempo',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1993,
    make: 'Ford',
    model: 'Thunderbird',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Geo',
    model: 'Metro',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1993,
    make: 'Geo',
    model: 'Prizm',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Geo',
    model: 'Storm',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Geo',
    model: 'Tracker',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'GMC',
    model: '1500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'GMC',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'GMC',
    model: '2500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'GMC',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'GMC',
    model: '3500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'GMC',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'GMC',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Jimmy',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Rally Wagon 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Rally Wagon 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Rally Wagon 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Sonoma Club Coupe Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Sonoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Vandura 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Vandura 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Vandura 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Coupe', 'Wagon', 'Sedan']
  },
  {
    year: 1993,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Hatchback', 'Sedan']
  },
  {
    year: 1993,
    make: 'Honda',
    model: 'del Sol',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Honda',
    model: 'Prelude',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'HUMMER',
    model: 'H1',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Hyundai',
    model: 'Excel',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1993,
    make: 'Hyundai',
    model: 'Scoupe',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'INFINITI',
    model: 'J',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Isuzu',
    model: 'Amigo',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Isuzu',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Isuzu',
    model: 'Rodeo',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Isuzu',
    model: 'Spacecab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Isuzu',
    model: 'Stylus',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Isuzu',
    model: 'Trooper',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 1993,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Land Rover',
    model: 'Defender 110',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Lincoln',
    model: 'Mark VIII',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'MAZDA',
    model: '323',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'MAZDA',
    model: '626',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'MAZDA',
    model: '929',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'MAZDA',
    model: 'B-Series Cab Plus',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'MAZDA',
    model: 'MX-3',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 1993,
    make: 'MAZDA',
    model: 'MX-6',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'MAZDA',
    model: 'Navajo',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'MAZDA',
    model: 'Protege',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'MAZDA',
    model: 'RX-7',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '190 E',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '300 CE',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '300 D',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '300 E',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '300 SD',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '300 SE',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '300 SL',
    body_styles: ['Convertible']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '300 TE',
    body_styles: ['Wagon']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '400 E',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '400 SEL',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '500 E',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '500 SEC',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '500 SEL',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '500 SL',
    body_styles: ['Convertible']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '600 SEC',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '600 SEL',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Mercedes-Benz',
    model: '600 SL',
    body_styles: ['Convertible']
  },
  {
    year: 1993,
    make: 'Mercury',
    model: 'Capri',
    body_styles: ['Convertible']
  },
  {
    year: 1993,
    make: 'Mercury',
    model: 'Cougar',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Mercury',
    model: 'Topaz',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1993,
    make: 'Mercury',
    model: 'Tracer',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Mercury',
    model: 'Villager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Mitsubishi',
    model: '3000GT',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Mitsubishi',
    model: 'Diamante',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Mitsubishi',
    model: 'Expo',
    body_styles: ['Wagon']
  },
  {
    year: 1993,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Mitsubishi',
    model: 'Mighty Max Macro Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Mitsubishi',
    model: 'Mighty Max Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1993,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Mitsubishi',
    model: 'Precis',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Nissan',
    model: '240SX',
    body_styles: ['Hatchback', 'Coupe', 'Convertible']
  },
  {
    year: 1993,
    make: 'Nissan',
    model: '300ZX',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1993,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Nissan',
    model: 'King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Nissan',
    model: 'NX',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Nissan',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1993,
    make: 'Oldsmobile',
    model: '88',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Oldsmobile',
    model: '98',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Oldsmobile',
    model: 'Achieva',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1993,
    make: 'Oldsmobile',
    model: 'Bravada',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Oldsmobile',
    model: 'Cutlass Ciera',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Oldsmobile',
    model: 'Cutlass Cruiser',
    body_styles: ['Wagon']
  },
  {
    year: 1993,
    make: 'Oldsmobile',
    model: 'Cutlass Supreme',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 1993,
    make: 'Oldsmobile',
    model: 'Silhouette',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Plymouth',
    model: 'Acclaim',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Plymouth',
    model: 'Colt',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1993,
    make: 'Plymouth',
    model: 'Colt Vista',
    body_styles: ['Wagon']
  },
  {
    year: 1993,
    make: 'Plymouth',
    model: 'Grand Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Plymouth',
    model: 'Laser',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Plymouth',
    model: 'Sundance',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Plymouth',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Pontiac',
    model: 'Firebird',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1993,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1993,
    make: 'Pontiac',
    model: 'LeMans',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1993,
    make: 'Pontiac',
    model: 'Sunbird',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 1993,
    make: 'Pontiac',
    model: 'Trans Sport',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1993,
    make: 'Porsche',
    model: '928',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Porsche',
    model: '968',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1993,
    make: 'Saab',
    model: '900',
    body_styles: ['Hatchback', 'Sedan', 'Convertible']
  },
  {
    year: 1993,
    make: 'Saab',
    model: '9000',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1993,
    make: 'Saturn',
    model: 'S-Series',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1993,
    make: 'Subaru',
    model: 'Justy',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Subaru',
    model: 'Loyale',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Subaru',
    model: 'SVX',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Suzuki',
    model: 'Samurai',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Suzuki',
    model: 'Sidekick',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Suzuki',
    model: 'Swift',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 1993,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1993,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Coupe', 'Hatchback', 'Convertible']
  },
  {
    year: 1993,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 1993,
    make: 'Toyota',
    model: 'MR2',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Toyota',
    model: 'Paseo',
    body_styles: ['Coupe']
  },
  {
    year: 1993,
    make: 'Toyota',
    model: 'Previa',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Toyota',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Toyota',
    model: 'Supra',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Toyota',
    model: 'T100 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Toyota',
    model: 'Tercel',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1993,
    make: 'Toyota',
    model: 'Xtra Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1993,
    make: 'Volkswagen',
    model: 'Cabriolet',
    body_styles: ['Convertible']
  },
  {
    year: 1993,
    make: 'Volkswagen',
    model: 'Corrado',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Volkswagen',
    model: 'Eurovan',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1993,
    make: 'Volkswagen',
    model: 'Fox',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1993,
    make: 'Volkswagen',
    model: 'Golf III',
    body_styles: ['Hatchback']
  },
  {
    year: 1993,
    make: 'Volkswagen',
    model: 'Jetta III',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Volvo',
    model: '240',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Volvo',
    model: '850',
    body_styles: ['Sedan']
  },
  {
    year: 1993,
    make: 'Volvo',
    model: '940',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1993,
    make: 'Volvo',
    model: '960',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'Acura',
    model: 'Integra',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1994,
    make: 'Acura',
    model: 'Legend',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1994,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Acura',
    model: 'Vigor',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Alfa Romeo',
    model: '164',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Alfa Romeo',
    model: 'Spider',
    body_styles: ['Convertible']
  },
  {
    year: 1994,
    make: 'Audi',
    model: '100',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1994,
    make: 'Audi',
    model: '90',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Audi',
    model: 'Cabriolet',
    body_styles: ['Convertible']
  },
  {
    year: 1994,
    make: 'Audi',
    model: 'Quattro',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 1994,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'BMW',
    model: '8 Series',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1994,
    make: 'Buick',
    model: 'Roadmaster',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'Buick',
    model: 'Skylark',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1994,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Cadillac',
    model: 'Eldorado',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Cadillac',
    model: 'Fleetwood',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Cadillac',
    model: 'Seville',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: '1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: '2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: '3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Beretta',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Convertible', 'Hatchback']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Caprice Classic',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Sedan', 'Coupe', 'Wagon', 'Convertible']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Corsica',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'G-Series G10',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'G-Series G20',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'G-Series G30',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Lumina',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Lumina Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Lumina Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'S10 Blazer',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'S10 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'S10 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Sportvan G20',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Sportvan G30',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Chrysler',
    model: 'Concorde',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Chrysler',
    model: 'LeBaron',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 1994,
    make: 'Chrysler',
    model: 'LHS',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Chrysler',
    model: 'New Yorker',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Colt',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Dakota Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Intrepid',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Ram Van B150',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Ram Van B250',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Ram Van B350',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Ram Wagon B150',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Ram Wagon B250',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Ram Wagon B350',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Shadow',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Spirit',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Stealth',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Convertible']
  },
  {
    year: 1994,
    make: 'Eagle',
    model: 'Summit',
    body_styles: ['Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 1994,
    make: 'Eagle',
    model: 'Talon',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'Eagle',
    model: 'Vision',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Aerostar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Aerostar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Aspire',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Bronco',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Club Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Econoline E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Econoline E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Econoline E350 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Escort',
    body_styles: ['Hatchback', 'Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'F250 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'F250 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'F350 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'F350 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'F350 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Probe',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Tempo',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Ford',
    model: 'Thunderbird',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Geo',
    model: 'Metro',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'Geo',
    model: 'Prizm',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Geo',
    model: 'Tracker',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'GMC',
    model: '1500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'GMC',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'GMC',
    model: '2500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'GMC',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'GMC',
    model: '3500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'GMC',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'GMC',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'GMC',
    model: 'Jimmy',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'GMC',
    model: 'Rally Wagon 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'GMC',
    model: 'Rally Wagon 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'GMC',
    model: 'Sonoma Club Coupe Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'GMC',
    model: 'Sonoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'GMC',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'GMC',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'GMC',
    model: 'Vandura 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'GMC',
    model: 'Vandura 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'GMC',
    model: 'Vandura 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 1994,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Hatchback', 'Sedan', 'Coupe']
  },
  {
    year: 1994,
    make: 'Honda',
    model: 'del Sol',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Honda',
    model: 'Prelude',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'HUMMER',
    model: 'H1',
    body_styles: ['SUV', 'Wagon']
  },
  {
    year: 1994,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Hyundai',
    model: 'Excel',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1994,
    make: 'Hyundai',
    model: 'Scoupe',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'INFINITI',
    model: 'J',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Isuzu',
    model: 'Amigo',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Isuzu',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Isuzu',
    model: 'Rodeo',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Isuzu',
    model: 'Spacecab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Isuzu',
    model: 'Trooper',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 1994,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Kia',
    model: 'Sephia',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Land Rover',
    model: 'Defender 90',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Lincoln',
    model: 'Mark VIII',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'MAZDA',
    model: '323',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'MAZDA',
    model: '626',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'MAZDA',
    model: '929',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'MAZDA',
    model: 'B-Series Cab Plus',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'MAZDA',
    model: 'MX-3',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 1994,
    make: 'MAZDA',
    model: 'MX-6',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'MAZDA',
    model: 'Navajo',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'MAZDA',
    model: 'Protege',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'MAZDA',
    model: 'RX-7',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Coupe', 'Convertible', 'Wagon', 'Sedan']
  },
  {
    year: 1994,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1994,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 1994,
    make: 'Mercury',
    model: 'Capri',
    body_styles: ['Convertible']
  },
  {
    year: 1994,
    make: 'Mercury',
    model: 'Cougar',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1994,
    make: 'Mercury',
    model: 'Topaz',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Mercury',
    model: 'Tracer',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1994,
    make: 'Mercury',
    model: 'Villager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Mitsubishi',
    model: '3000GT',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Mitsubishi',
    model: 'Diamante',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Mitsubishi',
    model: 'Expo',
    body_styles: ['Wagon']
  },
  {
    year: 1994,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Mitsubishi',
    model: 'Mighty Max Macro Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Mitsubishi',
    model: 'Mighty Max Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1994,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Mitsubishi',
    model: 'Precis',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'Nissan',
    model: '240SX',
    body_styles: ['Convertible']
  },
  {
    year: 1994,
    make: 'Nissan',
    model: '300ZX',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1994,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Nissan',
    model: 'King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Nissan',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Oldsmobile',
    model: '88',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Oldsmobile',
    model: '98',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Oldsmobile',
    model: 'Achieva',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1994,
    make: 'Oldsmobile',
    model: 'Bravada',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Oldsmobile',
    model: 'Cutlass Ciera',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Oldsmobile',
    model: 'Cutlass Cruiser',
    body_styles: ['Wagon']
  },
  {
    year: 1994,
    make: 'Oldsmobile',
    model: 'Cutlass Supreme',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 1994,
    make: 'Oldsmobile',
    model: 'Silhouette',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Plymouth',
    model: 'Acclaim',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Plymouth',
    model: 'Colt',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Plymouth',
    model: 'Colt Vista',
    body_styles: ['Wagon']
  },
  {
    year: 1994,
    make: 'Plymouth',
    model: 'Grand Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Plymouth',
    model: 'Laser',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'Plymouth',
    model: 'Sundance',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'Plymouth',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Pontiac',
    model: 'Firebird',
    body_styles: ['Convertible', 'Hatchback']
  },
  {
    year: 1994,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1994,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1994,
    make: 'Pontiac',
    model: 'Sunbird',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 1994,
    make: 'Pontiac',
    model: 'Trans Sport',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1994,
    make: 'Porsche',
    model: '928',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'Porsche',
    model: '968',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1994,
    make: 'Saab',
    model: '900',
    body_styles: ['Coupe', 'Hatchback', 'Convertible']
  },
  {
    year: 1994,
    make: 'Saab',
    model: '9000',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1994,
    make: 'Saturn',
    model: 'S-Series',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'Subaru',
    model: 'Justy',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'Subaru',
    model: 'Loyale',
    body_styles: ['Wagon']
  },
  {
    year: 1994,
    make: 'Subaru',
    model: 'SVX',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Suzuki',
    model: 'Samurai',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Suzuki',
    model: 'Sidekick',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Suzuki',
    model: 'Swift',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 1994,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Coupe', 'Wagon', 'Sedan']
  },
  {
    year: 1994,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Hatchback', 'Coupe']
  },
  {
    year: 1994,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 1994,
    make: 'Toyota',
    model: 'MR2',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Toyota',
    model: 'Paseo',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Toyota',
    model: 'Previa',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1994,
    make: 'Toyota',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Toyota',
    model: 'Supra',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'Toyota',
    model: 'T100 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Toyota',
    model: 'Tercel',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Toyota',
    model: 'Xtra Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1994,
    make: 'Volkswagen',
    model: 'Corrado',
    body_styles: ['Coupe']
  },
  {
    year: 1994,
    make: 'Volkswagen',
    model: 'Golf III',
    body_styles: ['Hatchback']
  },
  {
    year: 1994,
    make: 'Volkswagen',
    model: 'Jetta III',
    body_styles: ['Sedan']
  },
  {
    year: 1994,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'Volvo',
    model: '850',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1994,
    make: 'Volvo',
    model: '940',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1994,
    make: 'Volvo',
    model: '960',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1995,
    make: 'Acura',
    model: 'Integra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 1995,
    make: 'Acura',
    model: 'Legend',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1995,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Convertible']
  },
  {
    year: 1995,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Alfa Romeo',
    model: '164',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Audi',
    model: '90',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1995,
    make: 'Audi',
    model: 'Cabriolet',
    body_styles: ['Convertible']
  },
  {
    year: 1995,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1995,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Hatchback', 'Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 1995,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1995,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'BMW',
    model: '8 Series',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1995,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1995,
    make: 'Buick',
    model: 'Riviera',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Buick',
    model: 'Roadmaster',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1995,
    make: 'Buick',
    model: 'Skylark',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1995,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Cadillac',
    model: 'Eldorado',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Cadillac',
    model: 'Fleetwood',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Cadillac',
    model: 'Seville',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: '1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: '2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: '3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: '3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Beretta',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Convertible', 'Hatchback']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Caprice Classic',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Corsica',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Convertible', 'Hatchback']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'G-Series G10',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'G-Series G20',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'G-Series G30',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Lumina',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Lumina Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Lumina Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Monte Carlo',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'S10 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'S10 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Sportvan G20',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Sportvan G30',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Chrysler',
    model: 'Cirrus',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Chrysler',
    model: 'Concorde',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Chrysler',
    model: 'LeBaron',
    body_styles: ['Convertible']
  },
  {
    year: 1995,
    make: 'Chrysler',
    model: 'LHS',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Chrysler',
    model: 'New Yorker',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Avenger',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Dakota Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Intrepid',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Neon',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Ram 1500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Ram 2500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Ram 3500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Ram Van 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Ram Van 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Ram Van 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Ram Wagon 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Ram Wagon 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Ram Wagon 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Spirit',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Stealth',
    body_styles: ['Hatchback']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Stratus',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Convertible']
  },
  {
    year: 1995,
    make: 'Eagle',
    model: 'Summit',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 1995,
    make: 'Eagle',
    model: 'Talon',
    body_styles: ['Hatchback']
  },
  {
    year: 1995,
    make: 'Eagle',
    model: 'Vision',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Aerostar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Aerostar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Aspire',
    body_styles: ['Hatchback']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Bronco',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Club Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Contour',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Econoline E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Econoline E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Econoline E350 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Escort',
    body_styles: ['Hatchback', 'Wagon']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'F250 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'F250 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'F350 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'F350 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'F350 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Probe',
    body_styles: ['Hatchback']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Thunderbird',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Windstar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Ford',
    model: 'Windstar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Geo',
    model: 'Metro',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1995,
    make: 'Geo',
    model: 'Prizm',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Geo',
    model: 'Tracker',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'GMC',
    model: '1500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'GMC',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'GMC',
    model: '2500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'GMC',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'GMC',
    model: '3500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'GMC',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'GMC',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'GMC',
    model: 'Jimmy',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'GMC',
    model: 'Rally Wagon G2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'GMC',
    model: 'Rally Wagon G3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'GMC',
    model: 'Sonoma Club Coupe Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'GMC',
    model: 'Sonoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'GMC',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'GMC',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'GMC',
    model: 'Vandura G1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'GMC',
    model: 'Vandura G2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'GMC',
    model: 'Vandura G3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Wagon', 'Coupe']
  },
  {
    year: 1995,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Hatchback', 'Sedan', 'Coupe']
  },
  {
    year: 1995,
    make: 'Honda',
    model: 'del Sol',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Honda',
    model: 'Prelude',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'HUMMER',
    model: 'H1',
    body_styles: ['SUV', 'Wagon']
  },
  {
    year: 1995,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1995,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Hyundai',
    model: 'Scoupe',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'INFINITI',
    model: 'J',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Isuzu',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Isuzu',
    model: 'Rodeo',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Isuzu',
    model: 'Trooper',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 1995,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Kia',
    model: 'Sephia',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Land Rover',
    model: 'Defender 90',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Lincoln',
    model: 'Mark VIII',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'MAZDA',
    model: '626',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'MAZDA',
    model: '929',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'MAZDA',
    model: 'B-Series Cab Plus',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'MAZDA',
    model: 'Millenia',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'MAZDA',
    model: 'MX-3',
    body_styles: ['Hatchback']
  },
  {
    year: 1995,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 1995,
    make: 'MAZDA',
    model: 'MX-6',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'MAZDA',
    model: 'Protege',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'MAZDA',
    model: 'RX-7',
    body_styles: ['Hatchback']
  },
  {
    year: 1995,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Coupe', 'Convertible', 'Sedan', 'Wagon']
  },
  {
    year: 1995,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1995,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 1995,
    make: 'Mercury',
    model: 'Cougar',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Mercury',
    model: 'Mystique',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1995,
    make: 'Mercury',
    model: 'Tracer',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1995,
    make: 'Mercury',
    model: 'Villager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Mitsubishi',
    model: '3000GT',
    body_styles: ['Convertible', 'Hatchback']
  },
  {
    year: 1995,
    make: 'Mitsubishi',
    model: 'Diamante',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1995,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Hatchback']
  },
  {
    year: 1995,
    make: 'Mitsubishi',
    model: 'Expo',
    body_styles: ['Wagon']
  },
  {
    year: 1995,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Mitsubishi',
    model: 'Mighty Max Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1995,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Nissan',
    model: '200SX',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Nissan',
    model: '240SX',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Nissan',
    model: '300ZX',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1995,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Nissan',
    model: 'King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Nissan',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Oldsmobile',
    model: '88',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Oldsmobile',
    model: '98',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Oldsmobile',
    model: 'Achieva',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1995,
    make: 'Oldsmobile',
    model: 'Aurora',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Oldsmobile',
    model: 'Cutlass Ciera',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1995,
    make: 'Oldsmobile',
    model: 'Cutlass Supreme',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 1995,
    make: 'Oldsmobile',
    model: 'Silhouette',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Plymouth',
    model: 'Acclaim',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Plymouth',
    model: 'Grand Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Plymouth',
    model: 'Neon',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1995,
    make: 'Plymouth',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Pontiac',
    model: 'Firebird',
    body_styles: ['Convertible', 'Hatchback']
  },
  {
    year: 1995,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1995,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1995,
    make: 'Pontiac',
    model: 'Sunfire',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 1995,
    make: 'Pontiac',
    model: 'Trans Sport',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1995,
    make: 'Porsche',
    model: '928',
    body_styles: ['Hatchback']
  },
  {
    year: 1995,
    make: 'Porsche',
    model: '968',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1995,
    make: 'Saab',
    model: '900',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1995,
    make: 'Saab',
    model: '9000',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1995,
    make: 'Saturn',
    model: 'S-Series',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 1995,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon', 'Coupe']
  },
  {
    year: 1995,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1995,
    make: 'Subaru',
    model: 'SVX',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Suzuki',
    model: 'Esteem',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Suzuki',
    model: 'Samurai',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Suzuki',
    model: 'Sidekick',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Suzuki',
    model: 'Swift',
    body_styles: ['Hatchback']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Hatchback', 'Convertible', 'Coupe']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'MR2',
    body_styles: ['Hatchback']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'Paseo',
    body_styles: ['Coupe']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'Previa',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'Supra',
    body_styles: ['Hatchback']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'T100 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'T100 Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'Tacoma Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'Tercel',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Toyota',
    model: 'Xtra Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1995,
    make: 'Volkswagen',
    model: 'Cabrio',
    body_styles: ['Convertible']
  },
  {
    year: 1995,
    make: 'Volkswagen',
    model: 'Golf III',
    body_styles: ['Hatchback']
  },
  {
    year: 1995,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 1995,
    make: 'Volkswagen',
    model: 'Jetta III',
    body_styles: ['Sedan']
  },
  {
    year: 1995,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1995,
    make: 'Volvo',
    model: '850',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1995,
    make: 'Volvo',
    model: '940',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1995,
    make: 'Volvo',
    model: '960',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Acura',
    model: 'Integra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 1996,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1996,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Acura',
    model: 'SLX',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Audi',
    model: 'Cabriolet',
    body_styles: ['Convertible']
  },
  {
    year: 1996,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Hatchback', 'Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 1996,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'BMW',
    model: '8 Series',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'BMW',
    model: 'Z3',
    body_styles: ['Convertible']
  },
  {
    year: 1996,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1996,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1996,
    make: 'Buick',
    model: 'Riviera',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Buick',
    model: 'Roadmaster',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Buick',
    model: 'Skylark',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1996,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Cadillac',
    model: 'Eldorado',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Cadillac',
    model: 'Fleetwood',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Cadillac',
    model: 'Seville',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: '1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: '2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: '2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: '3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Beretta',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Caprice Classic',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Corsica',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'G-Series 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'G-Series 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'G-Series G30',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Lumina',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Lumina Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Lumina Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Monte Carlo',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'S10 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'S10 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Sportvan G30',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Chrysler',
    model: 'Cirrus',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Chrysler',
    model: 'Concorde',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Chrysler',
    model: 'LHS',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Chrysler',
    model: 'New Yorker',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1996,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Avenger',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Dakota Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Intrepid',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Neon',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Ram 1500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Ram 2500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Ram 3500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Ram Van 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Ram Van 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Ram Van 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Ram Wagon 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Ram Wagon 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Ram Wagon 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Stealth',
    body_styles: ['Hatchback']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Stratus',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1996,
    make: 'Eagle',
    model: 'Summit',
    body_styles: ['Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 1996,
    make: 'Eagle',
    model: 'Talon',
    body_styles: ['Hatchback']
  },
  {
    year: 1996,
    make: 'Eagle',
    model: 'Vision',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Aerostar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Aerostar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Aspire',
    body_styles: ['Hatchback']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Bronco',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Club Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Contour',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Econoline E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Econoline E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Econoline E350 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Escort',
    body_styles: ['Hatchback', 'Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'F250 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'F250 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'F250 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'F350 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'F350 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'F350 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Probe',
    body_styles: ['Hatchback']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Thunderbird',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Windstar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Ford',
    model: 'Windstar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Geo',
    model: 'Metro',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1996,
    make: 'Geo',
    model: 'Prizm',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Geo',
    model: 'Tracker',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'GMC',
    model: '1500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'GMC',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'GMC',
    model: '2500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'GMC',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'GMC',
    model: '3500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'GMC',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'GMC',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Jimmy',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Rally Wagon G3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Sonoma Club Coupe Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Sonoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Vandura G3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Wagon', 'Coupe']
  },
  {
    year: 1996,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan', 'Coupe', 'Hatchback']
  },
  {
    year: 1996,
    make: 'Honda',
    model: 'del Sol',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Honda',
    model: 'Prelude',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'HUMMER',
    model: 'H1',
    body_styles: ['SUV', 'Wagon']
  },
  {
    year: 1996,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1996,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'INFINITI',
    model: 'I',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'INFINITI',
    model: 'J',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Isuzu',
    model: 'Hombre Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Isuzu',
    model: 'Oasis',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Isuzu',
    model: 'Rodeo',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Isuzu',
    model: 'Trooper',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 1996,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Kia',
    model: 'Sephia',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Lincoln',
    model: 'Mark VIII',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'MAZDA',
    model: '626',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'MAZDA',
    model: 'B-Series Cab Plus',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'MAZDA',
    model: 'Millenia',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 1996,
    make: 'MAZDA',
    model: 'MX-6',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'MAZDA',
    model: 'Protege',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1996,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 1996,
    make: 'Mercury',
    model: 'Cougar',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Mercury',
    model: 'Mystique',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Mercury',
    model: 'Tracer',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Mercury',
    model: 'Villager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Mitsubishi',
    model: '3000GT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1996,
    make: 'Mitsubishi',
    model: 'Diamante',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1996,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Mitsubishi',
    model: 'Mighty Max Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1996,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Nissan',
    model: '200SX',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Nissan',
    model: '240SX',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Nissan',
    model: '300ZX',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1996,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Nissan',
    model: 'King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Nissan',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Oldsmobile',
    model: '88',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Oldsmobile',
    model: '98',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Oldsmobile',
    model: 'Achieva',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1996,
    make: 'Oldsmobile',
    model: 'Aurora',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Oldsmobile',
    model: 'Bravada',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Oldsmobile',
    model: 'Ciera',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Oldsmobile',
    model: 'Cutlass Supreme',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1996,
    make: 'Oldsmobile',
    model: 'Silhouette',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Plymouth',
    model: 'Breeze',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Plymouth',
    model: 'Grand Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Plymouth',
    model: 'Neon',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1996,
    make: 'Plymouth',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Pontiac',
    model: 'Firebird',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1996,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1996,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1996,
    make: 'Pontiac',
    model: 'Sunfire',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 1996,
    make: 'Pontiac',
    model: 'Trans Sport',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1996,
    make: 'Saab',
    model: '900',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1996,
    make: 'Saab',
    model: '9000',
    body_styles: ['Hatchback']
  },
  {
    year: 1996,
    make: 'Saturn',
    model: 'S-Series',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Subaru',
    model: 'SVX',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Suzuki',
    model: 'Esteem',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Suzuki',
    model: 'Sidekick',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Suzuki',
    model: 'Swift',
    body_styles: ['Hatchback']
  },
  {
    year: 1996,
    make: 'Suzuki',
    model: 'X-90',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Hatchback', 'Convertible', 'Coupe']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'Paseo',
    body_styles: ['Coupe']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'Previa',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'Supra',
    body_styles: ['Hatchback']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'T100 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'T100 Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'Tacoma Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 1996,
    make: 'Toyota',
    model: 'Tercel',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Volkswagen',
    model: 'Cabrio',
    body_styles: ['Convertible']
  },
  {
    year: 1996,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 1996,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 1996,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1996,
    make: 'Volvo',
    model: '850',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1996,
    make: 'Volvo',
    model: '960',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1997,
    make: 'Acura',
    model: 'CL',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'Acura',
    model: 'Integra',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1997,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1997,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Acura',
    model: 'SLX',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1997,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Audi',
    model: 'Cabriolet',
    body_styles: ['Convertible']
  },
  {
    year: 1997,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Coupe', 'Hatchback', 'Convertible', 'Sedan']
  },
  {
    year: 1997,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'BMW',
    model: '8 Series',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1997,
    make: 'BMW',
    model: 'Z3',
    body_styles: ['Convertible']
  },
  {
    year: 1997,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Buick',
    model: 'Riviera',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'Buick',
    model: 'Skylark',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1997,
    make: 'Cadillac',
    model: 'Catera',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Cadillac',
    model: 'Eldorado',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'Cadillac',
    model: 'Seville',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: '1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: '2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: '2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: '3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Hatchback']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'G-Series 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'G-Series 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'G-Series 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Lumina',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Monte Carlo',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'S10 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'S10 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Chevrolet',
    model: 'Venture Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Chrysler',
    model: 'Cirrus',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Chrysler',
    model: 'Concorde',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Chrysler',
    model: 'LHS',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1997,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Avenger',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Dakota Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Intrepid',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Neon',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Ram 1500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Ram 2500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Ram 3500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Ram Van 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Ram Van 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Ram Van 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Ram Wagon 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Ram Wagon 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Ram Wagon 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Stratus',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'Eagle',
    model: 'Talon',
    body_styles: ['Hatchback']
  },
  {
    year: 1997,
    make: 'Eagle',
    model: 'Vision',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Aerostar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Aerostar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Aspire',
    body_styles: ['Hatchback']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Club Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Contour',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Econoline E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Econoline E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Econoline E350 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Escort',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'F250 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'F250 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'F250 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'F350 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'F350 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'F350 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Probe',
    body_styles: ['Hatchback']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Thunderbird',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Windstar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Ford',
    model: 'Windstar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Geo',
    model: 'Metro',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1997,
    make: 'Geo',
    model: 'Prizm',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Geo',
    model: 'Tracker',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'GMC',
    model: '1500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'GMC',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'GMC',
    model: '2500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'GMC',
    model: '2500 HD Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'GMC',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'GMC',
    model: '3500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'GMC',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'GMC',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Jimmy',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Sonoma Club Coupe Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Sonoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Wagon', 'Coupe']
  },
  {
    year: 1997,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Hatchback', 'Sedan']
  },
  {
    year: 1997,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Honda',
    model: 'del Sol',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Honda',
    model: 'Prelude',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'HUMMER',
    model: 'H1',
    body_styles: ['SUV', 'Wagon']
  },
  {
    year: 1997,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1997,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1997,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Hyundai',
    model: 'Tiburon',
    body_styles: ['Hatchback']
  },
  {
    year: 1997,
    make: 'INFINITI',
    model: 'I',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'INFINITI',
    model: 'J',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Isuzu',
    model: 'Hombre Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Isuzu',
    model: 'Hombre Spacecab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Isuzu',
    model: 'Oasis',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Isuzu',
    model: 'Rodeo',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Isuzu',
    model: 'Trooper',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 1997,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Kia',
    model: 'Sephia',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Land Rover',
    model: 'Defender 90',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Lincoln',
    model: 'Mark VIII',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'MAZDA',
    model: '626',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'MAZDA',
    model: 'B-Series Cab Plus',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'MAZDA',
    model: 'Millenia',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 1997,
    make: 'MAZDA',
    model: 'MX-6',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'MAZDA',
    model: 'Protege',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1997,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 1997,
    make: 'Mercury',
    model: 'Cougar',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Mercury',
    model: 'Mystique',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1997,
    make: 'Mercury',
    model: 'Tracer',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1997,
    make: 'Mercury',
    model: 'Villager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Mitsubishi',
    model: '3000GT',
    body_styles: ['Hatchback']
  },
  {
    year: 1997,
    make: 'Mitsubishi',
    model: 'Diamante',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1997,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1997,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Mitsubishi',
    model: 'Montero Sport',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Nissan',
    model: '200SX',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'Nissan',
    model: '240SX',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Nissan',
    model: 'King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Nissan',
    model: 'Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Oldsmobile',
    model: '88',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Oldsmobile',
    model: 'Achieva',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1997,
    make: 'Oldsmobile',
    model: 'Aurora',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Oldsmobile',
    model: 'Bravada',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Oldsmobile',
    model: 'Cutlass',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Oldsmobile',
    model: 'Cutlass Supreme',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1997,
    make: 'Oldsmobile',
    model: 'LSS',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Oldsmobile',
    model: 'Regency',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Oldsmobile',
    model: 'Silhouette',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Plymouth',
    model: 'Breeze',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Plymouth',
    model: 'Grand Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Plymouth',
    model: 'Neon',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1997,
    make: 'Plymouth',
    model: 'Prowler',
    body_styles: ['Convertible']
  },
  {
    year: 1997,
    make: 'Plymouth',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Pontiac',
    model: 'Firebird',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1997,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1997,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1997,
    make: 'Pontiac',
    model: 'Sunfire',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 1997,
    make: 'Pontiac',
    model: 'Trans Sport',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1997,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 1997,
    make: 'Saab',
    model: '900',
    body_styles: ['Coupe', 'Hatchback', 'Convertible']
  },
  {
    year: 1997,
    make: 'Saab',
    model: '9000',
    body_styles: ['Hatchback']
  },
  {
    year: 1997,
    make: 'Saturn',
    model: 'S-Series',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 1997,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Coupe', 'Wagon', 'Sedan']
  },
  {
    year: 1997,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1997,
    make: 'Subaru',
    model: 'SVX',
    body_styles: ['Coupe']
  },
  {
    year: 1997,
    make: 'Suzuki',
    model: 'Esteem',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Suzuki',
    model: 'Sidekick',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Suzuki',
    model: 'Swift',
    body_styles: ['Hatchback']
  },
  {
    year: 1997,
    make: 'Suzuki',
    model: 'X-90',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Hatchback', 'Coupe', 'Convertible']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'Paseo',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'Previa',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'Supra',
    body_styles: ['Hatchback']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'T100 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'T100 Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'Tacoma Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 1997,
    make: 'Toyota',
    model: 'Tercel',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Volkswagen',
    model: 'Cabrio',
    body_styles: ['Convertible']
  },
  {
    year: 1997,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 1997,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1997,
    make: 'Volvo',
    model: '850',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1997,
    make: 'Volvo',
    model: '960',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1997,
    make: 'Volvo',
    model: 'S90',
    body_styles: ['Sedan']
  },
  {
    year: 1997,
    make: 'Volvo',
    model: 'V90',
    body_styles: ['Wagon']
  },
  {
    year: 1998,
    make: 'Acura',
    model: 'CL',
    body_styles: ['Coupe']
  },
  {
    year: 1998,
    make: 'Acura',
    model: 'Integra',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1998,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1998,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Acura',
    model: 'SLX',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1998,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1998,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Audi',
    model: 'Cabriolet',
    body_styles: ['Convertible']
  },
  {
    year: 1998,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Coupe', 'Hatchback', 'Convertible', 'Sedan']
  },
  {
    year: 1998,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 1998,
    make: 'BMW',
    model: 'Z3',
    body_styles: ['Convertible']
  },
  {
    year: 1998,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Buick',
    model: 'Riviera',
    body_styles: ['Coupe']
  },
  {
    year: 1998,
    make: 'Buick',
    model: 'Skylark',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Cadillac',
    model: 'Catera',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Cadillac',
    model: 'Eldorado',
    body_styles: ['Coupe']
  },
  {
    year: 1998,
    make: 'Cadillac',
    model: 'Seville',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: '1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: '2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: '2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: '3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Hatchback', 'Sedan', 'Convertible']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'G-Series 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'G-Series 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'G-Series 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Lumina',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Metro',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Monte Carlo',
    body_styles: ['Coupe']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Prizm',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'S10 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'S10 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Tracker',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Venture Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Chevrolet',
    model: 'Venture Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Chrysler',
    model: 'Cirrus',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Chrysler',
    model: 'Concorde',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 1998,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Avenger',
    body_styles: ['Coupe']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Dakota Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Intrepid',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Neon',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram 1500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram 1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram 2500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram 2500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram 3500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram Van 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram Van 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram Van 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram Wagon 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram Wagon 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Ram Wagon 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Stratus',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 1998,
    make: 'Eagle',
    model: 'Talon',
    body_styles: ['Hatchback']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Club Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Contour',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Econoline E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Econoline E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Econoline E350 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Escort',
    body_styles: ['Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'F250 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'F250 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Windstar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Ford',
    model: 'Windstar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'GMC',
    model: '1500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'GMC',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'GMC',
    model: '2500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'GMC',
    model: '2500 HD Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'GMC',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'GMC',
    model: '3500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'GMC',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'GMC',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Envoy',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Jimmy',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Sonoma Club Coupe Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Sonoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1998,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Hatchback', 'Sedan']
  },
  {
    year: 1998,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Honda',
    model: 'Prelude',
    body_styles: ['Coupe']
  },
  {
    year: 1998,
    make: 'HUMMER',
    model: 'H1',
    body_styles: ['SUV', 'Wagon']
  },
  {
    year: 1998,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1998,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1998,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Hyundai',
    model: 'Tiburon',
    body_styles: ['Hatchback']
  },
  {
    year: 1998,
    make: 'INFINITI',
    model: 'I',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Isuzu',
    model: 'Amigo',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Isuzu',
    model: 'Hombre Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Isuzu',
    model: 'Hombre Spacecab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Isuzu',
    model: 'Oasis',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Isuzu',
    model: 'Rodeo',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Isuzu',
    model: 'Trooper',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1998,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Kia',
    model: 'Sephia',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Coupe']
  },
  {
    year: 1998,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Lincoln',
    model: 'Mark VIII',
    body_styles: ['Coupe']
  },
  {
    year: 1998,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'MAZDA',
    model: '626',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'MAZDA',
    model: 'B-Series Cab Plus',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'MAZDA',
    model: 'Millenia',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'MAZDA',
    model: 'Protege',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 1998,
    make: 'Mercedes-Benz',
    model: 'CLK-Class',
    body_styles: ['Coupe']
  },
  {
    year: 1998,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1998,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 1998,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 1998,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Mercury',
    model: 'Mystique',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1998,
    make: 'Mercury',
    model: 'Tracer',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1998,
    make: 'Mercury',
    model: 'Villager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Mitsubishi',
    model: '3000GT',
    body_styles: ['Hatchback']
  },
  {
    year: 1998,
    make: 'Mitsubishi',
    model: 'Diamante',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1998,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1998,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Mitsubishi',
    model: 'Montero Sport',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Nissan',
    model: '200SX',
    body_styles: ['Coupe']
  },
  {
    year: 1998,
    make: 'Nissan',
    model: '240SX',
    body_styles: ['Coupe']
  },
  {
    year: 1998,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Nissan',
    model: 'Frontier Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Oldsmobile',
    model: '88',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Oldsmobile',
    model: 'Achieva',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Oldsmobile',
    model: 'Aurora',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Oldsmobile',
    model: 'Bravada',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Oldsmobile',
    model: 'Cutlass',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Oldsmobile',
    model: 'Intrigue',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Oldsmobile',
    model: 'LSS',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Oldsmobile',
    model: 'Regency',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Oldsmobile',
    model: 'Silhouette',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Plymouth',
    model: 'Breeze',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Plymouth',
    model: 'Grand Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Plymouth',
    model: 'Neon',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1998,
    make: 'Plymouth',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Pontiac',
    model: 'Firebird',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1998,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1998,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1998,
    make: 'Pontiac',
    model: 'Sunfire',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 1998,
    make: 'Pontiac',
    model: 'Trans Sport',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1998,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 1998,
    make: 'Saab',
    model: '900',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1998,
    make: 'Saab',
    model: '9000',
    body_styles: ['Hatchback']
  },
  {
    year: 1998,
    make: 'Saturn',
    model: 'S-Series',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 1998,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon', 'Coupe']
  },
  {
    year: 1998,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1998,
    make: 'Suzuki',
    model: 'Esteem',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1998,
    make: 'Suzuki',
    model: 'Sidekick',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Suzuki',
    model: 'Swift',
    body_styles: ['Hatchback']
  },
  {
    year: 1998,
    make: 'Suzuki',
    model: 'X-90',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Coupe', 'Hatchback', 'Convertible']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: 'Supra',
    body_styles: ['Hatchback']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: 'T100 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: 'T100 Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: 'Tacoma Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 1998,
    make: 'Toyota',
    model: 'Tercel',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Volkswagen',
    model: 'Cabrio',
    body_styles: ['Convertible']
  },
  {
    year: 1998,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 1998,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Volkswagen',
    model: 'New Beetle',
    body_styles: ['Hatchback']
  },
  {
    year: 1998,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1998,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1998,
    make: 'Volvo',
    model: 'S70',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Volvo',
    model: 'S90',
    body_styles: ['Sedan']
  },
  {
    year: 1998,
    make: 'Volvo',
    model: 'V70',
    body_styles: ['Wagon']
  },
  {
    year: 1998,
    make: 'Volvo',
    model: 'V90',
    body_styles: ['Wagon']
  },
  {
    year: 1999,
    make: 'Acura',
    model: 'CL',
    body_styles: ['Coupe']
  },
  {
    year: 1999,
    make: 'Acura',
    model: 'Integra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 1999,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 1999,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Acura',
    model: 'SLX',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1999,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1999,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Coupe', 'Hatchback', 'Convertible', 'Sedan']
  },
  {
    year: 1999,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 1999,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1999,
    make: 'BMW',
    model: 'Z3',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 1999,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Buick',
    model: 'Riviera',
    body_styles: ['Coupe']
  },
  {
    year: 1999,
    make: 'Cadillac',
    model: 'Catera',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Cadillac',
    model: 'Eldorado',
    body_styles: ['Coupe']
  },
  {
    year: 1999,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Cadillac',
    model: 'Seville',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: '1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: '2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: '2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: '3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Lumina',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Metro',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Monte Carlo',
    body_styles: ['Coupe']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Prizm',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'S10 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'S10 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Silverado 2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Silverado 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Tracker',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Venture Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Chevrolet',
    model: 'Venture Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Chrysler',
    model: 'Cirrus',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Chrysler',
    model: 'Concorde',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Chrysler',
    model: 'LHS',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1999,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Daewoo',
    model: 'Lanos',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1999,
    make: 'Daewoo',
    model: 'Leganza',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Daewoo',
    model: 'Nubira',
    body_styles: ['Wagon', 'Sedan', 'Hatchback']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Avenger',
    body_styles: ['Coupe']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Dakota Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Intrepid',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Neon',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram 1500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram 1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram 2500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram 2500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram 3500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram Van 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram Van 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram Van 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram Wagon 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram Wagon 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Ram Wagon 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Stratus',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Contour',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Econoline E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Econoline E150 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Econoline E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Econoline E350 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Econoline E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Econoline E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Escort',
    body_styles: ['Sedan', 'Wagon', 'Coupe']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'F250 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'F250 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Windstar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Ford',
    model: 'Windstar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'GMC',
    model: '1500 Club Coupe',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: '2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: '2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: '3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Envoy',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Jimmy',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Sierra 2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Sierra 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Sonoma Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Sonoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1999,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Hatchback', 'Sedan']
  },
  {
    year: 1999,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Honda',
    model: 'Prelude',
    body_styles: ['Coupe']
  },
  {
    year: 1999,
    make: 'HUMMER',
    model: 'H1',
    body_styles: ['SUV', 'Wagon']
  },
  {
    year: 1999,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 1999,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1999,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Hyundai',
    model: 'Tiburon',
    body_styles: ['Hatchback']
  },
  {
    year: 1999,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'INFINITI',
    model: 'I',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Isuzu',
    model: 'Amigo',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Isuzu',
    model: 'Hombre Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Isuzu',
    model: 'Hombre Spacecab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Isuzu',
    model: 'Oasis',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Isuzu',
    model: 'Rodeo',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Isuzu',
    model: 'Trooper',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Isuzu',
    model: 'VehiCROSS',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1999,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Kia',
    model: 'Sephia',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Land Rover',
    model: 'Discovery Series II',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Coupe']
  },
  {
    year: 1999,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'MAZDA',
    model: '626',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'MAZDA',
    model: 'B-Series Cab Plus',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'MAZDA',
    model: 'Millenia',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 1999,
    make: 'MAZDA',
    model: 'Protege',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 1999,
    make: 'Mercedes-Benz',
    model: 'CLK-Class',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1999,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1999,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 1999,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 1999,
    make: 'Mercury',
    model: 'Cougar',
    body_styles: ['Coupe']
  },
  {
    year: 1999,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Mercury',
    model: 'Mystique',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1999,
    make: 'Mercury',
    model: 'Tracer',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1999,
    make: 'Mercury',
    model: 'Villager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Mitsubishi',
    model: '3000GT',
    body_styles: ['Hatchback']
  },
  {
    year: 1999,
    make: 'Mitsubishi',
    model: 'Diamante',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1999,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1999,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Mitsubishi',
    model: 'Montero Sport',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Nissan',
    model: 'Frontier Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Oldsmobile',
    model: '88',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Oldsmobile',
    model: 'Alero',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1999,
    make: 'Oldsmobile',
    model: 'Aurora',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Oldsmobile',
    model: 'Bravada',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Oldsmobile',
    model: 'Cutlass',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Oldsmobile',
    model: 'Intrigue',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Oldsmobile',
    model: 'LSS',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Oldsmobile',
    model: 'Silhouette',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Plymouth',
    model: 'Breeze',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Plymouth',
    model: 'Grand Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Plymouth',
    model: 'Neon',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 1999,
    make: 'Plymouth',
    model: 'Prowler',
    body_styles: ['Convertible']
  },
  {
    year: 1999,
    make: 'Plymouth',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Pontiac',
    model: 'Firebird',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 1999,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1999,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 1999,
    make: 'Pontiac',
    model: 'Montana',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Pontiac',
    model: 'Sunfire',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 1999,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1999,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 1999,
    make: 'Saab',
    model: '9-3',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1999,
    make: 'Saab',
    model: '9-5',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1999,
    make: 'Saturn',
    model: 'S-Series',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 1999,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 1999,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1999,
    make: 'Suzuki',
    model: 'Esteem',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1999,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Suzuki',
    model: 'Swift',
    body_styles: ['Hatchback']
  },
  {
    year: 1999,
    make: 'Suzuki',
    model: 'Vitara',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 1999,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 1999,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Toyota',
    model: 'Solara',
    body_styles: ['Coupe']
  },
  {
    year: 1999,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Toyota',
    model: 'Tacoma Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 1999,
    make: 'Volkswagen',
    model: 'Cabrio',
    body_styles: ['Convertible']
  },
  {
    year: 1999,
    make: 'Volkswagen',
    model: 'Cabrio (New)',
    body_styles: ['Convertible']
  },
  {
    year: 1999,
    make: 'Volkswagen',
    model: 'Eurovan',
    body_styles: ['Van/Minivan']
  },
  {
    year: 1999,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 1999,
    make: 'Volkswagen',
    model: 'Golf (New)',
    body_styles: ['Hatchback']
  },
  {
    year: 1999,
    make: 'Volkswagen',
    model: 'GTI (New)',
    body_styles: ['Hatchback']
  },
  {
    year: 1999,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Volkswagen',
    model: 'Jetta (New)',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Volkswagen',
    model: 'New Beetle',
    body_styles: ['Hatchback']
  },
  {
    year: 1999,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 1999,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 1999,
    make: 'Volvo',
    model: 'S70',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 1999,
    make: 'Volvo',
    model: 'V70',
    body_styles: ['Wagon']
  },
  {
    year: 2000,
    make: 'Acura',
    model: 'Integra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2000,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2000,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2000,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2000,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe']
  },
  {
    year: 2000,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Wagon', 'Convertible', 'Coupe']
  },
  {
    year: 2000,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2000,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'BMW',
    model: 'M',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2000,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'BMW',
    model: 'Z3',
    body_styles: ['Convertible', 'Hatchback']
  },
  {
    year: 2000,
    make: 'BMW',
    model: 'Z8',
    body_styles: ['Convertible']
  },
  {
    year: 2000,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Cadillac',
    model: 'Catera',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Cadillac',
    model: 'Eldorado',
    body_styles: ['Coupe']
  },
  {
    year: 2000,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Cadillac',
    model: 'Seville',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: '2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: '2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: '3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Lumina',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Metro',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Monte Carlo',
    body_styles: ['Coupe']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Prizm',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'S10 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'S10 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Silverado 2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Silverado 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Tahoe (New)',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Tracker',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Venture Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Chevrolet',
    model: 'Venture Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Chrysler',
    model: '300M',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Chrysler',
    model: 'Cirrus',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Chrysler',
    model: 'Concorde',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Chrysler',
    model: 'Grand Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Chrysler',
    model: 'LHS',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2000,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Chrysler',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Daewoo',
    model: 'Lanos',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2000,
    make: 'Daewoo',
    model: 'Leganza',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Daewoo',
    model: 'Nubira',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Avenger',
    body_styles: ['Coupe']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Dakota Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Dakota Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Intrepid',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Neon',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Ram 1500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Ram 1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Ram 2500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Ram 3500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Ram Van 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Ram Van 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Ram Van 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Ram Wagon 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Ram Wagon 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Ram Wagon 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Stratus',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Contour',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Econoline E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Econoline E150 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Econoline E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Econoline E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Econoline E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Escort',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Excursion',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Explorer Sport',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan', 'Hatchback', 'Wagon']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Windstar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Ford',
    model: 'Windstar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Envoy',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Jimmy',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sierra (Classic) 2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sierra (Classic) 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sierra (Classic) 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sierra (Classic) 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sierra (Classic) 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sierra (Classic) 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sierra 2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sierra 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sonoma Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Sonoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Yukon Denali',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2000,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Sedan', 'Hatchback']
  },
  {
    year: 2000,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Hatchback']
  },
  {
    year: 2000,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Honda',
    model: 'Prelude',
    body_styles: ['Coupe']
  },
  {
    year: 2000,
    make: 'Honda',
    model: 'S2000',
    body_styles: ['Convertible']
  },
  {
    year: 2000,
    make: 'HUMMER',
    model: 'H1',
    body_styles: ['SUV', 'Wagon']
  },
  {
    year: 2000,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2000,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2000,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Hyundai',
    model: 'Tiburon',
    body_styles: ['Hatchback']
  },
  {
    year: 2000,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'INFINITI',
    model: 'I',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Isuzu',
    model: 'Amigo',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Isuzu',
    model: 'Hombre Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Isuzu',
    model: 'Hombre Spacecab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Isuzu',
    model: 'Rodeo',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Isuzu',
    model: 'Trooper',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Isuzu',
    model: 'VehiCROSS',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Jaguar',
    model: 'S-Type',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2000,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Kia',
    model: 'Sephia',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Kia',
    model: 'Spectra',
    body_styles: ['Hatchback']
  },
  {
    year: 2000,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Land Rover',
    model: 'Discovery Series II',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Coupe']
  },
  {
    year: 2000,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Lincoln',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'MAZDA',
    model: '626',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'MAZDA',
    model: 'B-Series Cab Plus',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'MAZDA',
    model: 'Millenia',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2000,
    make: 'MAZDA',
    model: 'Protege',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2000,
    make: 'Mercedes-Benz',
    model: 'CLK-Class',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2000,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2000,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2000,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2000,
    make: 'Mercury',
    model: 'Cougar',
    body_styles: ['Coupe']
  },
  {
    year: 2000,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Mercury',
    model: 'Mystique',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2000,
    make: 'Mercury',
    model: 'Villager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Mitsubishi',
    model: 'Diamante',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe']
  },
  {
    year: 2000,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2000,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Mitsubishi',
    model: 'Montero Sport',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Nissan',
    model: 'Frontier Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Oldsmobile',
    model: 'Alero',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2000,
    make: 'Oldsmobile',
    model: 'Bravada',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Oldsmobile',
    model: 'Intrigue',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Oldsmobile',
    model: 'Silhouette',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Plymouth',
    model: 'Breeze',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Plymouth',
    model: 'Grand Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Plymouth',
    model: 'Neon',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Plymouth',
    model: 'Prowler',
    body_styles: ['Convertible']
  },
  {
    year: 2000,
    make: 'Plymouth',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Pontiac',
    model: 'Firebird',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2000,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2000,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2000,
    make: 'Pontiac',
    model: 'Montana',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Pontiac',
    model: 'Sunfire',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2000,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2000,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2000,
    make: 'Saab',
    model: '9-3',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2000,
    make: 'Saab',
    model: '9-5',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2000,
    make: 'Saturn',
    model: 'L-Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2000,
    make: 'Saturn',
    model: 'S-Series',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 2000,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Wagon', 'Sedan', 'Coupe']
  },
  {
    year: 2000,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2000,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2000,
    make: 'Suzuki',
    model: 'Esteem',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2000,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Suzuki',
    model: 'Swift',
    body_styles: ['Hatchback']
  },
  {
    year: 2000,
    make: 'Suzuki',
    model: 'Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Hatchback']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'Echo',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'MR2',
    body_styles: ['Convertible']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'Solara',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'Tacoma Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'Tundra Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2000,
    make: 'Volkswagen',
    model: 'Cabrio',
    body_styles: ['Convertible']
  },
  {
    year: 2000,
    make: 'Volkswagen',
    model: 'Eurovan',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2000,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2000,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2000,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Volkswagen',
    model: 'New Beetle',
    body_styles: ['Hatchback']
  },
  {
    year: 2000,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2000,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2000,
    make: 'Volvo',
    model: 'S40',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Volvo',
    model: 'S70',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2000,
    make: 'Volvo',
    model: 'V40',
    body_styles: ['Wagon']
  },
  {
    year: 2000,
    make: 'Volvo',
    model: 'V70',
    body_styles: ['Wagon']
  },
  {
    year: 2001,
    make: 'Acura',
    model: 'CL',
    body_styles: ['Coupe']
  },
  {
    year: 2001,
    make: 'Acura',
    model: 'Integra',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2001,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2001,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2001,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2001,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Audi',
    model: 'allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2001,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2001,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2001,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Coupe', 'Sedan', 'Wagon', 'Convertible']
  },
  {
    year: 2001,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2001,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'BMW',
    model: 'M',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2001,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2001,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'BMW',
    model: 'Z3',
    body_styles: ['Convertible', 'Hatchback']
  },
  {
    year: 2001,
    make: 'BMW',
    model: 'Z8',
    body_styles: ['Convertible']
  },
  {
    year: 2001,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Cadillac',
    model: 'Catera',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Cadillac',
    model: 'Eldorado',
    body_styles: ['Coupe']
  },
  {
    year: 2001,
    make: 'Cadillac',
    model: 'Seville',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Convertible', 'Coupe', 'Hatchback']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Lumina',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Metro',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Monte Carlo',
    body_styles: ['Coupe']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Prizm',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'S10 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'S10 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'S10 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Silverado 1500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Silverado 2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Silverado 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Silverado 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Silverado 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Silverado 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Tracker',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Chevrolet',
    model: 'Venture Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Chrysler',
    model: '300M',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Chrysler',
    model: 'Concorde',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Chrysler',
    model: 'LHS',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Chrysler',
    model: 'Prowler',
    body_styles: ['Convertible']
  },
  {
    year: 2001,
    make: 'Chrysler',
    model: 'PT Cruiser',
    body_styles: ['Wagon']
  },
  {
    year: 2001,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2001,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Chrysler',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Daewoo',
    model: 'Lanos',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2001,
    make: 'Daewoo',
    model: 'Leganza',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Daewoo',
    model: 'Nubira',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Dakota Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Dakota Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Intrepid',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Neon',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Ram 1500 Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Ram 1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Ram 2500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Ram 3500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Ram Van 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Ram Van 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Ram Van 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Ram Wagon 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Ram Wagon 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Ram Wagon 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Stratus',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2001,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Econoline E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Econoline E150 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Econoline E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Econoline E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Econoline E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Escort',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Excursion',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Explorer Sport',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Explorer Sport Trac',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan', 'Hatchback', 'Wagon']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Windstar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'Windstar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Ford',
    model: 'ZX2',
    body_styles: ['Coupe']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Jimmy',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sierra 1500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sierra 2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sierra 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sierra 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sierra 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sierra 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sonoma Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sonoma Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Sonoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2001,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2001,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Hatchback']
  },
  {
    year: 2001,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Honda',
    model: 'Prelude',
    body_styles: ['Coupe']
  },
  {
    year: 2001,
    make: 'Honda',
    model: 'S2000',
    body_styles: ['Convertible']
  },
  {
    year: 2001,
    make: 'HUMMER',
    model: 'H1',
    body_styles: ['Wagon', 'SUV']
  },
  {
    year: 2001,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2001,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2001,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Hyundai',
    model: 'Tiburon',
    body_styles: ['Hatchback']
  },
  {
    year: 2001,
    make: 'Hyundai',
    model: 'XG300',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'INFINITI',
    model: 'I',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Isuzu',
    model: 'Rodeo',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Isuzu',
    model: 'Rodeo Sport',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Isuzu',
    model: 'Trooper',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Isuzu',
    model: 'VehiCROSS',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Jaguar',
    model: 'S-Type',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2001,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Kia',
    model: 'Sephia',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Kia',
    model: 'Spectra',
    body_styles: ['Hatchback']
  },
  {
    year: 2001,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Land Rover',
    model: 'Discovery Series II',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Lincoln',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'MAZDA',
    model: '626',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'MAZDA',
    model: 'B-Series Cab Plus',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'MAZDA',
    model: 'Millenia',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2001,
    make: 'MAZDA',
    model: 'Protege',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'MAZDA',
    model: 'Tribute',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2001,
    make: 'Mercedes-Benz',
    model: 'CLK-Class',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2001,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2001,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2001,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2001,
    make: 'Mercury',
    model: 'Cougar',
    body_styles: ['Coupe']
  },
  {
    year: 2001,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2001,
    make: 'Mercury',
    model: 'Villager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Mitsubishi',
    model: 'Diamante',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2001,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2001,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Mitsubishi',
    model: 'Montero Sport',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Nissan',
    model: 'Frontier Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Oldsmobile',
    model: 'Alero',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2001,
    make: 'Oldsmobile',
    model: 'Aurora',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Oldsmobile',
    model: 'Bravada',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Oldsmobile',
    model: 'Intrigue',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Oldsmobile',
    model: 'Silhouette',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Plymouth',
    model: 'Neon',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Pontiac',
    model: 'Aztek',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Pontiac',
    model: 'Firebird',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2001,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2001,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2001,
    make: 'Pontiac',
    model: 'Montana',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Pontiac',
    model: 'Sunfire',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2001,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2001,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2001,
    make: 'Saab',
    model: '9-3',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2001,
    make: 'Saab',
    model: '9-5',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2001,
    make: 'Saturn',
    model: 'L-Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2001,
    make: 'Saturn',
    model: 'S-Series',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 2001,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Wagon', 'Sedan', 'Coupe']
  },
  {
    year: 2001,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2001,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2001,
    make: 'Suzuki',
    model: 'Esteem',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2001,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Suzuki',
    model: 'Swift',
    body_styles: ['Hatchback']
  },
  {
    year: 2001,
    make: 'Suzuki',
    model: 'Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Suzuki',
    model: 'XL-7',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Hatchback']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Echo',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'MR2',
    body_styles: ['Convertible']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Solara',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Tacoma Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Tundra Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2001,
    make: 'Volkswagen',
    model: 'Cabrio',
    body_styles: ['Convertible']
  },
  {
    year: 2001,
    make: 'Volkswagen',
    model: 'Eurovan',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2001,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2001,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2001,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2001,
    make: 'Volkswagen',
    model: 'New Beetle',
    body_styles: ['Hatchback']
  },
  {
    year: 2001,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2001,
    make: 'Volkswagen',
    model: 'Passat (New)',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2001,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2001,
    make: 'Volvo',
    model: 'S40',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2001,
    make: 'Volvo',
    model: 'V40',
    body_styles: ['Wagon']
  },
  {
    year: 2001,
    make: 'Volvo',
    model: 'V70',
    body_styles: ['Wagon']
  },
  {
    year: 2002,
    make: 'Acura',
    model: 'CL',
    body_styles: ['Coupe']
  },
  {
    year: 2002,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe']
  },
  {
    year: 2002,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Acura',
    model: 'RSX',
    body_styles: ['Coupe']
  },
  {
    year: 2002,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2002,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2002,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Audi',
    model: 'allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2002,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2002,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Wagon']
  },
  {
    year: 2002,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2002,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Coupe', 'Convertible', 'Wagon']
  },
  {
    year: 2002,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2002,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'BMW',
    model: 'M',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2002,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2002,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'BMW',
    model: 'Z3',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2002,
    make: 'BMW',
    model: 'Z8',
    body_styles: ['Convertible']
  },
  {
    year: 2002,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Buick',
    model: 'Rendezvous',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Cadillac',
    model: 'Eldorado',
    body_styles: ['Coupe']
  },
  {
    year: 2002,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Cadillac',
    model: 'Escalade EXT',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Cadillac',
    model: 'Seville',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Avalanche 1500',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Avalanche 2500',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Monte Carlo',
    body_styles: ['Coupe']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Prizm',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'S10 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'S10 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'S10 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Silverado 1500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Silverado 2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Silverado 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Silverado 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Silverado 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Silverado 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Tracker',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'TrailBlazer',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Venture Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Chevrolet',
    model: 'Venture Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Chrysler',
    model: '300M',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Chrysler',
    model: 'Concorde',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Chrysler',
    model: 'Prowler',
    body_styles: ['Convertible']
  },
  {
    year: 2002,
    make: 'Chrysler',
    model: 'PT Cruiser',
    body_styles: ['Wagon']
  },
  {
    year: 2002,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Sedan', 'Convertible', 'Coupe']
  },
  {
    year: 2002,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Chrysler',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Daewoo',
    model: 'Lanos',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2002,
    make: 'Daewoo',
    model: 'Leganza',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Daewoo',
    model: 'Nubira',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Dakota Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Dakota Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Intrepid',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Neon',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Ram 1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Ram 2500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Ram 3500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Ram Van 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Ram Van 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Ram Van 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Ram Wagon 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Ram Wagon 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Ram Wagon 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Stratus',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2002,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Econoline E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Econoline E150 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Econoline E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Econoline E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Econoline E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Escort',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Excursion',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Explorer Sport',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Explorer Sport Trac',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan', 'Wagon', 'Hatchback']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Thunderbird',
    body_styles: ['Convertible']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Windstar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'Windstar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Ford',
    model: 'ZX2',
    body_styles: ['Coupe']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Envoy',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Envoy XL',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sierra 1500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sierra 2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sierra 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sierra 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sierra 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sierra 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sonoma Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sonoma Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Sonoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2002,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan', 'Coupe', 'Hatchback']
  },
  {
    year: 2002,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Hatchback']
  },
  {
    year: 2002,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Honda',
    model: 'S2000',
    body_styles: ['Convertible']
  },
  {
    year: 2002,
    make: 'HUMMER',
    model: 'H1',
    body_styles: ['SUV', 'Wagon']
  },
  {
    year: 2002,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2002,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2002,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Hyundai',
    model: 'XG350',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'INFINITI',
    model: 'I',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Isuzu',
    model: 'Axiom',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Isuzu',
    model: 'Rodeo',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Isuzu',
    model: 'Rodeo Sport',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Isuzu',
    model: 'Trooper',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Jaguar',
    model: 'S-Type',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2002,
    make: 'Jaguar',
    model: 'X-Type',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Jeep',
    model: 'Liberty',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2002,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Kia',
    model: 'Spectra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2002,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Land Rover',
    model: 'Discovery Series II',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Land Rover',
    model: 'Freelander',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2002,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Convertible']
  },
  {
    year: 2002,
    make: 'Lincoln',
    model: 'Blackwood',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Lincoln',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'MAZDA',
    model: '626',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'MAZDA',
    model: 'B-Series Cab Plus',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'MAZDA',
    model: 'Millenia',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2002,
    make: 'MAZDA',
    model: 'Protege',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'MAZDA',
    model: 'Protege5',
    body_styles: ['Hatchback']
  },
  {
    year: 2002,
    make: 'MAZDA',
    model: 'Tribute',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 2002,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2002,
    make: 'Mercedes-Benz',
    model: 'CLK-Class',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2002,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2002,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2002,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2002,
    make: 'Mercury',
    model: 'Cougar',
    body_styles: ['Coupe']
  },
  {
    year: 2002,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2002,
    make: 'Mercury',
    model: 'Villager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'MINI',
    model: 'Cooper',
    body_styles: ['Hatchback']
  },
  {
    year: 2002,
    make: 'Mitsubishi',
    model: 'Diamante',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2002,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Coupe']
  },
  {
    year: 2002,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Mitsubishi',
    model: 'Montero Sport',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Oldsmobile',
    model: 'Alero',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2002,
    make: 'Oldsmobile',
    model: 'Aurora',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Oldsmobile',
    model: 'Bravada',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Oldsmobile',
    model: 'Intrigue',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Oldsmobile',
    model: 'Silhouette',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Pontiac',
    model: 'Aztek',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Pontiac',
    model: 'Firebird',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2002,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2002,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2002,
    make: 'Pontiac',
    model: 'Montana',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Pontiac',
    model: 'Sunfire',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2002,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2002,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2002,
    make: 'Saab',
    model: '9-3',
    body_styles: ['Convertible', 'Hatchback']
  },
  {
    year: 2002,
    make: 'Saab',
    model: '9-5',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2002,
    make: 'Saturn',
    model: 'L-Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2002,
    make: 'Saturn',
    model: 'S-Series',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2002,
    make: 'Saturn',
    model: 'VUE',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2002,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2002,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2002,
    make: 'Suzuki',
    model: 'Aerio',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2002,
    make: 'Suzuki',
    model: 'Esteem',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2002,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Suzuki',
    model: 'Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Suzuki',
    model: 'XL-7',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Hatchback']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Echo',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'MR2',
    body_styles: ['Convertible']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Solara',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Tacoma Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Tundra Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2002,
    make: 'Volkswagen',
    model: 'Cabrio',
    body_styles: ['Convertible']
  },
  {
    year: 2002,
    make: 'Volkswagen',
    model: 'Eurovan',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2002,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2002,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2002,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2002,
    make: 'Volkswagen',
    model: 'New Beetle',
    body_styles: ['Hatchback']
  },
  {
    year: 2002,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2002,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2002,
    make: 'Volvo',
    model: 'S40',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2002,
    make: 'Volvo',
    model: 'V40',
    body_styles: ['Wagon']
  },
  {
    year: 2002,
    make: 'Volvo',
    model: 'V70',
    body_styles: ['Wagon']
  },
  {
    year: 2003,
    make: 'Acura',
    model: 'CL',
    body_styles: ['Coupe']
  },
  {
    year: 2003,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe']
  },
  {
    year: 2003,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Acura',
    model: 'RSX',
    body_styles: ['Coupe']
  },
  {
    year: 2003,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Wagon', 'Sedan', 'Convertible']
  },
  {
    year: 2003,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2003,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Audi',
    model: 'allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2003,
    make: 'Audi',
    model: 'RS 6',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Wagon']
  },
  {
    year: 2003,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2003,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Convertible', 'Coupe', 'Wagon']
  },
  {
    year: 2003,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2003,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2003,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2003,
    make: 'BMW',
    model: 'Z8',
    body_styles: ['Convertible']
  },
  {
    year: 2003,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Buick',
    model: 'Rendezvous',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Cadillac',
    model: 'Escalade EXT',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Cadillac',
    model: 'Seville',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Avalanche 1500',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Avalanche 2500',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Monte Carlo',
    body_styles: ['Coupe']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'S10 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'S10 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'S10 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Silverado 1500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Silverado 2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Silverado 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Silverado 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Silverado 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Silverado 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'SSR',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Tracker',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'TrailBlazer',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Venture Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Chevrolet',
    model: 'Venture Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Chrysler',
    model: '300M',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Chrysler',
    model: 'Concorde',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Chrysler',
    model: 'PT Cruiser',
    body_styles: ['Wagon']
  },
  {
    year: 2003,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2003,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Chrysler',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Dakota Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Dakota Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Grand Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Intrepid',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Neon',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Ram 1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Ram 2500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Ram 3500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Ram Van 1500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Ram Van 2500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Ram Van 3500',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Stratus',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2003,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Convertible']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'E150 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'E150 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'E250 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Excursion',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Explorer Sport',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Explorer Sport Trac',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan', 'Wagon', 'Hatchback']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Thunderbird',
    body_styles: ['Convertible']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Windstar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'Windstar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Ford',
    model: 'ZX2',
    body_styles: ['Coupe']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Envoy',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Envoy XL',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sierra 1500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sierra 2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sierra 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sierra 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sierra 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sierra 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sonoma Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sonoma Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Sonoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2003,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Sedan', 'Hatchback']
  },
  {
    year: 2003,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Honda',
    model: 'Element',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Hatchback']
  },
  {
    year: 2003,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Honda',
    model: 'S2000',
    body_styles: ['Convertible']
  },
  {
    year: 2003,
    make: 'HUMMER',
    model: 'H1',
    body_styles: ['Wagon', 'SUV']
  },
  {
    year: 2003,
    make: 'HUMMER',
    model: 'H2',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2003,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2003,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Hyundai',
    model: 'Tiburon',
    body_styles: ['Coupe']
  },
  {
    year: 2003,
    make: 'Hyundai',
    model: 'XG350',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'INFINITI',
    model: 'FX',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2003,
    make: 'INFINITI',
    model: 'I',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'INFINITI',
    model: 'M',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Isuzu',
    model: 'Ascender',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Isuzu',
    model: 'Axiom',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Isuzu',
    model: 'Rodeo',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Isuzu',
    model: 'Rodeo Sport',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Jaguar',
    model: 'S-Type',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2003,
    make: 'Jaguar',
    model: 'X-Type',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Jeep',
    model: 'Liberty',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2003,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Kia',
    model: 'Spectra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2003,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Land Rover',
    model: 'Freelander',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2003,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Convertible']
  },
  {
    year: 2003,
    make: 'Lincoln',
    model: 'Aviator',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Lincoln',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'MAZDA',
    model: 'B-Series Cab Plus',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2003,
    make: 'MAZDA',
    model: 'Protege',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'MAZDA',
    model: 'Protege5',
    body_styles: ['Hatchback']
  },
  {
    year: 2003,
    make: 'MAZDA',
    model: 'Tribute',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Coupe', 'Sedan', 'Wagon']
  },
  {
    year: 2003,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2003,
    make: 'Mercedes-Benz',
    model: 'CLK-Class',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2003,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2003,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2003,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2003,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Mercury',
    model: 'Marauder',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2003,
    make: 'MINI',
    model: 'Cooper',
    body_styles: ['Hatchback']
  },
  {
    year: 2003,
    make: 'Mitsubishi',
    model: 'Diamante',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2003,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Mitsubishi',
    model: 'Montero Sport',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Nissan',
    model: '350Z',
    body_styles: ['Coupe']
  },
  {
    year: 2003,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Oldsmobile',
    model: 'Alero',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2003,
    make: 'Oldsmobile',
    model: 'Aurora',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Oldsmobile',
    model: 'Bravada',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Oldsmobile',
    model: 'Silhouette',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Pontiac',
    model: 'Aztek',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2003,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Pontiac',
    model: 'Montana',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Pontiac',
    model: 'Sunfire',
    body_styles: ['Coupe']
  },
  {
    year: 2003,
    make: 'Pontiac',
    model: 'Vibe',
    body_styles: ['Wagon']
  },
  {
    year: 2003,
    make: 'Porsche',
    model: '911',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2003,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2003,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Saab',
    model: '9-3',
    body_styles: ['Convertible', 'Sedan']
  },
  {
    year: 2003,
    make: 'Saab',
    model: '9-5',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2003,
    make: 'Saturn',
    model: 'Ion',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2003,
    make: 'Saturn',
    model: 'L-Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2003,
    make: 'Saturn',
    model: 'VUE',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Subaru',
    model: 'Baja',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2003,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2003,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2003,
    make: 'Suzuki',
    model: 'Aerio',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2003,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Suzuki',
    model: 'Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Suzuki',
    model: 'XL-7',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Hatchback']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Echo',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Matrix',
    body_styles: ['Wagon']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'MR2',
    body_styles: ['Convertible']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Solara',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Tacoma Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Tundra Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2003,
    make: 'Volkswagen',
    model: 'Eurovan',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2003,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2003,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2003,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2003,
    make: 'Volkswagen',
    model: 'New Beetle',
    body_styles: ['Convertible', 'Hatchback']
  },
  {
    year: 2003,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2003,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Convertible']
  },
  {
    year: 2003,
    make: 'Volvo',
    model: 'S40',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2003,
    make: 'Volvo',
    model: 'V40',
    body_styles: ['Wagon']
  },
  {
    year: 2003,
    make: 'Volvo',
    model: 'V70',
    body_styles: ['Wagon']
  },
  {
    year: 2003,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2003,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe']
  },
  {
    year: 2004,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Acura',
    model: 'RSX',
    body_styles: ['Coupe']
  },
  {
    year: 2004,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Acura',
    model: 'TSX',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Convertible', 'Wagon', 'Sedan']
  },
  {
    year: 2004,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2004,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Audi',
    model: 'allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2004,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Wagon', 'Convertible', 'Sedan']
  },
  {
    year: 2004,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2004,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Coupe', 'Convertible', 'Wagon']
  },
  {
    year: 2004,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2004,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2004,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2004,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Buick',
    model: 'Rainier',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Buick',
    model: 'Rendezvous',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Cadillac',
    model: 'Escalade EXT',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Cadillac',
    model: 'Seville',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Cadillac',
    model: 'SRX',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Cadillac',
    model: 'XLR',
    body_styles: ['Convertible']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Avalanche 1500',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Avalanche 2500',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Aveo',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Classic',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Colorado Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Monte Carlo',
    body_styles: ['Coupe']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'S10 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Silverado 2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Silverado 2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Silverado 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Silverado 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Silverado 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Silverado 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'SSR',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Tracker',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'TrailBlazer',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Venture Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Chevrolet',
    model: 'Venture Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Chrysler',
    model: '300M',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Chrysler',
    model: 'Concorde',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Chrysler',
    model: 'Crossfire',
    body_styles: ['Coupe']
  },
  {
    year: 2004,
    make: 'Chrysler',
    model: 'Pacifica',
    body_styles: ['Wagon']
  },
  {
    year: 2004,
    make: 'Chrysler',
    model: 'PT Cruiser',
    body_styles: ['Wagon']
  },
  {
    year: 2004,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 2004,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Dakota Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Dakota Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Grand Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Intrepid',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Neon',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Ram 1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Ram 2500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Ram 3500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Stratus',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2004,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Convertible']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'E150 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'E150 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'E250 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Excursion',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Explorer Sport Trac',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'F150 (Heritage) Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'F150 (Heritage) Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan', 'Hatchback', 'Wagon']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Freestar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Freestar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2004,
    make: 'Ford',
    model: 'Thunderbird',
    body_styles: ['Convertible']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Canyon Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Envoy',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Envoy XL',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Envoy XUV',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Sierra 2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Sierra 2500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Sierra 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Sierra 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Sierra 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Sierra 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Sonoma Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2004,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Sedan', 'Hatchback']
  },
  {
    year: 2004,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Honda',
    model: 'Element',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Hatchback']
  },
  {
    year: 2004,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Honda',
    model: 'S2000',
    body_styles: ['Convertible']
  },
  {
    year: 2004,
    make: 'HUMMER',
    model: 'H1',
    body_styles: ['Wagon', 'SUV']
  },
  {
    year: 2004,
    make: 'HUMMER',
    model: 'H2',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2004,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2004,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Hyundai',
    model: 'Tiburon',
    body_styles: ['Coupe']
  },
  {
    year: 2004,
    make: 'Hyundai',
    model: 'XG350',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'INFINITI',
    model: 'FX',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2004,
    make: 'INFINITI',
    model: 'I',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'INFINITI',
    model: 'M',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Isuzu',
    model: 'Ascender',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Isuzu',
    model: 'Axiom',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Isuzu',
    model: 'Rodeo',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Jaguar',
    model: 'S-Type',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2004,
    make: 'Jaguar',
    model: 'X-Type',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Jeep',
    model: 'Liberty',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Kia',
    model: 'Amanti',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2004,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Kia',
    model: 'Spectra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2004,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Land Rover',
    model: 'Freelander',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2004,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Convertible']
  },
  {
    year: 2004,
    make: 'Lincoln',
    model: 'Aviator',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Lincoln',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'MAZDA',
    model: 'B-Series Cab Plus',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2004,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan', 'Wagon', 'Hatchback']
  },
  {
    year: 2004,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2004,
    make: 'MAZDA',
    model: 'RX-8',
    body_styles: ['Coupe']
  },
  {
    year: 2004,
    make: 'MAZDA',
    model: 'Tribute',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 2004,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2004,
    make: 'Mercedes-Benz',
    model: 'CLK-Class',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2004,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2004,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2004,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2004,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Mercury',
    model: 'Marauder',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Mercury',
    model: 'Monterey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2004,
    make: 'MINI',
    model: 'Cooper',
    body_styles: ['Hatchback']
  },
  {
    year: 2004,
    make: 'Mitsubishi',
    model: 'Diamante',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2004,
    make: 'Mitsubishi',
    model: 'Endeavor',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2004,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Mitsubishi',
    model: 'Montero Sport',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Nissan',
    model: '350Z',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2004,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Nissan',
    model: 'Pathfinder Armada',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Oldsmobile',
    model: 'Alero',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2004,
    make: 'Oldsmobile',
    model: 'Bravada',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Oldsmobile',
    model: 'Silhouette',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Pontiac',
    model: 'Aztek',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2004,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Pontiac',
    model: 'GTO',
    body_styles: ['Coupe']
  },
  {
    year: 2004,
    make: 'Pontiac',
    model: 'Montana',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Pontiac',
    model: 'Sunfire',
    body_styles: ['Coupe']
  },
  {
    year: 2004,
    make: 'Pontiac',
    model: 'Vibe',
    body_styles: ['Wagon']
  },
  {
    year: 2004,
    make: 'Porsche',
    model: '911',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2004,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2004,
    make: 'Porsche',
    model: 'Carrera GT',
    body_styles: ['Convertible']
  },
  {
    year: 2004,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Saab',
    model: '9-3',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2004,
    make: 'Saab',
    model: '9-5',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2004,
    make: 'Saturn',
    model: 'Ion',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2004,
    make: 'Saturn',
    model: 'L-Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2004,
    make: 'Saturn',
    model: 'VUE',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Scion',
    model: 'xA',
    body_styles: ['Hatchback']
  },
  {
    year: 2004,
    make: 'Scion',
    model: 'xB',
    body_styles: ['Hatchback']
  },
  {
    year: 2004,
    make: 'Subaru',
    model: 'Baja',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2004,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2004,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2004,
    make: 'Suzuki',
    model: 'Aerio',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2004,
    make: 'Suzuki',
    model: 'Forenza',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Suzuki',
    model: 'Verona',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Suzuki',
    model: 'Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Suzuki',
    model: 'XL-7',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Hatchback']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Echo',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Matrix',
    body_styles: ['Hatchback', 'Wagon']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'MR2',
    body_styles: ['Convertible']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Solara',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Tacoma Xtracab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Tundra Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2004,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2004,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2004,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2004,
    make: 'Volkswagen',
    model: 'New Beetle',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2004,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2004,
    make: 'Volkswagen',
    model: 'Phaeton',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Volkswagen',
    model: 'R32',
    body_styles: ['Hatchback']
  },
  {
    year: 2004,
    make: 'Volkswagen',
    model: 'Touareg',
    body_styles: ['SUV']
  },
  {
    year: 2004,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Convertible']
  },
  {
    year: 2004,
    make: 'Volvo',
    model: 'S40',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Volvo',
    model: 'S40 (New)',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2004,
    make: 'Volvo',
    model: 'V40',
    body_styles: ['Wagon']
  },
  {
    year: 2004,
    make: 'Volvo',
    model: 'V70',
    body_styles: ['Wagon']
  },
  {
    year: 2004,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2004,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Acura',
    model: 'RSX',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Acura',
    model: 'TSX',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Aston Martin',
    model: 'DB9',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2005,
    make: 'Aston Martin',
    model: 'Vanquish S',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Convertible', 'Wagon', 'Sedan']
  },
  {
    year: 2005,
    make: 'Audi',
    model: 'A4 (2005.5)',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Audi',
    model: 'allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2005,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Convertible', 'Wagon', 'Sedan']
  },
  {
    year: 2005,
    make: 'Audi',
    model: 'S4 (2005.5)',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2005,
    make: 'Bentley',
    model: 'Arnage',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2005,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Coupe', 'Convertible', 'Wagon']
  },
  {
    year: 2005,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2005,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2005,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2005,
    make: 'Buick',
    model: 'Century',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Buick',
    model: 'LeSabre',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Buick',
    model: 'Park Avenue',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Buick',
    model: 'Rainier',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Buick',
    model: 'Rendezvous',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Buick',
    model: 'Terraza',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Cadillac',
    model: 'DeVille',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Cadillac',
    model: 'Escalade EXT',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Cadillac',
    model: 'SRX',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Cadillac',
    model: 'STS',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Cadillac',
    model: 'XLR',
    body_styles: ['Convertible']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Astro Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Astro Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Avalanche 1500',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Avalanche 2500',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Aveo',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Cavalier',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Classic',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Cobalt',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Colorado Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Monte Carlo',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Silverado 1500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Silverado 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Silverado 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Silverado 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'SSR',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'TrailBlazer',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Uplander Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Uplander Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Venture Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Chevrolet',
    model: 'Venture Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Chrysler',
    model: 'Crossfire',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2005,
    make: 'Chrysler',
    model: 'Pacifica',
    body_styles: ['Wagon']
  },
  {
    year: 2005,
    make: 'Chrysler',
    model: 'PT Cruiser',
    body_styles: ['Wagon', 'Convertible']
  },
  {
    year: 2005,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2005,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Dakota Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Grand Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Magnum',
    body_styles: ['Wagon']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Neon',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Ram 1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Ram 2500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Ram 3500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Stratus',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2005,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Convertible']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'E150 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'E150 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'E250 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Excursion',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Explorer Sport Trac',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Five Hundred',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Hatchback', 'Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Freestar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Freestar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Freestyle',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'GT',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Ford',
    model: 'Thunderbird',
    body_styles: ['Convertible']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Canyon Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Envoy',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Envoy XL',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Envoy XUV',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Safari Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Safari Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Sierra 1500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Sierra 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Sierra 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Sierra 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2005,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Sedan', 'Hatchback']
  },
  {
    year: 2005,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Honda',
    model: 'Element',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Hatchback']
  },
  {
    year: 2005,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Honda',
    model: 'S2000',
    body_styles: ['Convertible']
  },
  {
    year: 2005,
    make: 'HUMMER',
    model: 'H2',
    body_styles: ['SUV', 'Pickup']
  },
  {
    year: 2005,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2005,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2005,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Hyundai',
    model: 'Tiburon',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Hyundai',
    model: 'XG350',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'INFINITI',
    model: 'FX',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2005,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Isuzu',
    model: 'Ascender',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Jaguar',
    model: 'S-Type',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2005,
    make: 'Jaguar',
    model: 'X-Type',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Jeep',
    model: 'Liberty',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Kia',
    model: 'Amanti',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Kia',
    model: 'Spectra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2005,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Land Rover',
    model: 'Freelander',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Land Rover',
    model: 'LR3',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2005,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Convertible']
  },
  {
    year: 2005,
    make: 'Lincoln',
    model: 'Aviator',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Lincoln',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Lotus',
    model: 'Elise',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Maserati',
    model: 'Coupe',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Maserati',
    model: 'GranSport',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Maybach',
    model: '57',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Maybach',
    model: '62',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'MAZDA',
    model: 'B-Series Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2005,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan', 'Hatchback', 'Wagon']
  },
  {
    year: 2005,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2005,
    make: 'MAZDA',
    model: 'RX-8',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'MAZDA',
    model: 'Tribute',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 2005,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Mercedes-Benz',
    model: 'CLK-Class',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2005,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2005,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2005,
    make: 'Mercedes-Benz',
    model: 'SLR McLaren',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Mercury',
    model: 'Mariner',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Mercury',
    model: 'Montego',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Mercury',
    model: 'Monterey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2005,
    make: 'MINI',
    model: 'Cooper',
    body_styles: ['Hatchback']
  },
  {
    year: 2005,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2005,
    make: 'Mitsubishi',
    model: 'Endeavor',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Nissan',
    model: '350Z',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2005,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Panoz',
    model: 'Esperante',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2005,
    make: 'Pontiac',
    model: 'Aztek',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Pontiac',
    model: 'Bonneville',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Pontiac',
    model: 'G6',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Pontiac',
    model: 'Grand Am',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2005,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Pontiac',
    model: 'GTO',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Pontiac',
    model: 'Montana',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Pontiac',
    model: 'Montana SV6',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Pontiac',
    model: 'Sunfire',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Pontiac',
    model: 'Vibe',
    body_styles: ['Wagon']
  },
  {
    year: 2005,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2005,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2005,
    make: 'Porsche',
    model: 'Carrera GT',
    body_styles: ['Convertible']
  },
  {
    year: 2005,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Saab',
    model: '9-2X',
    body_styles: ['Wagon']
  },
  {
    year: 2005,
    make: 'Saab',
    model: '9-3',
    body_styles: ['Convertible', 'Sedan']
  },
  {
    year: 2005,
    make: 'Saab',
    model: '9-5',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Saab',
    model: '9-7X',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Saturn',
    model: 'Ion',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2005,
    make: 'Saturn',
    model: 'L-Series',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Saturn',
    model: 'Relay',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Saturn',
    model: 'VUE',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Scion',
    model: 'tC',
    body_styles: ['Coupe']
  },
  {
    year: 2005,
    make: 'Scion',
    model: 'xA',
    body_styles: ['Hatchback']
  },
  {
    year: 2005,
    make: 'Scion',
    model: 'xB',
    body_styles: ['Hatchback']
  },
  {
    year: 2005,
    make: 'Subaru',
    model: 'Baja',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2005,
    make: 'Suzuki',
    model: 'Aerio',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Suzuki',
    model: 'Forenza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Suzuki',
    model: 'Reno',
    body_styles: ['Hatchback']
  },
  {
    year: 2005,
    make: 'Suzuki',
    model: 'Verona',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Suzuki',
    model: 'XL-7',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Celica',
    body_styles: ['Hatchback']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Echo',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Matrix',
    body_styles: ['Wagon']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'MR2',
    body_styles: ['Convertible']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Solara',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Tundra Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2005,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2005,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2005,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Volkswagen',
    model: 'Jetta (New)',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Volkswagen',
    model: 'New Beetle',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2005,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2005,
    make: 'Volkswagen',
    model: 'Phaeton',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Volkswagen',
    model: 'Touareg',
    body_styles: ['SUV']
  },
  {
    year: 2005,
    make: 'Volvo',
    model: 'S40',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2005,
    make: 'Volvo',
    model: 'V50',
    body_styles: ['Wagon']
  },
  {
    year: 2005,
    make: 'Volvo',
    model: 'V70',
    body_styles: ['Wagon']
  },
  {
    year: 2005,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2005,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Acura',
    model: 'RSX',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Acura',
    model: 'TSX',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Aston Martin',
    model: 'DB9',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2006,
    make: 'Aston Martin',
    model: 'Vanquish S',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Wagon']
  },
  {
    year: 2006,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Convertible', 'Sedan', 'Wagon']
  },
  {
    year: 2006,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2006,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan', 'Wagon', 'Convertible']
  },
  {
    year: 2006,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2006,
    make: 'Bentley',
    model: 'Arnage',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2006,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Coupe', 'Sedan', 'Convertible', 'Wagon']
  },
  {
    year: 2006,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2006,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2006,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2006,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'BMW',
    model: 'M6',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2006,
    make: 'BMW',
    model: 'Z4 M',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2006,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Buick',
    model: 'Lucerne',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Buick',
    model: 'Rainier',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Buick',
    model: 'Rendezvous',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Buick',
    model: 'Terraza',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Cadillac',
    model: 'DTS',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Cadillac',
    model: 'Escalade EXT',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Cadillac',
    model: 'SRX',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Cadillac',
    model: 'STS',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Cadillac',
    model: 'XLR',
    body_styles: ['Convertible']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Avalanche 1500',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Avalanche 2500',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Aveo',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Cobalt',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Colorado Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'HHR',
    body_styles: ['Wagon']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Monte Carlo',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Silverado 1500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Silverado 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Silverado 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Silverado 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'SSR',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'TrailBlazer',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Uplander Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Chevrolet',
    model: 'Uplander Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Chrysler',
    model: 'Crossfire',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2006,
    make: 'Chrysler',
    model: 'Pacifica',
    body_styles: ['Wagon']
  },
  {
    year: 2006,
    make: 'Chrysler',
    model: 'PT Cruiser',
    body_styles: ['Wagon', 'Convertible']
  },
  {
    year: 2006,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2006,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Dakota Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Grand Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Magnum',
    body_styles: ['Wagon']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Ram 1500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Ram 1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Ram 2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Ram 2500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Ram 3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Ram 3500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Stratus',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2006,
    make: 'Ferrari',
    model: '612 Scaglietti',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Ferrari',
    model: 'F430',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'E150 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'E150 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'E250 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Five Hundred',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Hatchback', 'Sedan', 'Wagon']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Freestar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Freestar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Freestyle',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'GT',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Canyon Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Envoy',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Envoy XL',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Sierra 1500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Sierra 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Sierra 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Sierra 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2006,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2006,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Honda',
    model: 'Element',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Hatchback']
  },
  {
    year: 2006,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Honda',
    model: 'S2000',
    body_styles: ['Convertible']
  },
  {
    year: 2006,
    make: 'HUMMER',
    model: 'H1',
    body_styles: ['Wagon', 'SUV']
  },
  {
    year: 2006,
    make: 'HUMMER',
    model: 'H2',
    body_styles: ['SUV', 'Pickup']
  },
  {
    year: 2006,
    make: 'HUMMER',
    model: 'H3',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Hyundai',
    model: 'Azera',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2006,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Hyundai',
    model: 'Tiburon',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'INFINITI',
    model: 'FX',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2006,
    make: 'INFINITI',
    model: 'M',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'INFINITI',
    model: 'Q',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Isuzu',
    model: 'Ascender',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Isuzu',
    model: 'i-280 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Isuzu',
    model: 'i-350 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Jaguar',
    model: 'S-Type',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2006,
    make: 'Jaguar',
    model: 'X-Type',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2006,
    make: 'Jeep',
    model: 'Commander',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Jeep',
    model: 'Liberty',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Kia',
    model: 'Amanti',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Kia',
    model: 'Optima (2006.5)',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2006,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Kia',
    model: 'Spectra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2006,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Lamborghini',
    model: 'Gallardo',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2006,
    make: 'Lamborghini',
    model: 'Murcielago',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2006,
    make: 'Land Rover',
    model: 'LR3',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Convertible']
  },
  {
    year: 2006,
    make: 'Lincoln',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Lincoln',
    model: 'Mark LT',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Lincoln',
    model: 'Zephyr',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Lotus',
    model: 'Elise',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Lotus',
    model: 'Exige',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Maserati',
    model: 'Coupe',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Maserati',
    model: 'GranSport',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2006,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Maybach',
    model: '57',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Maybach',
    model: '62',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'MAZDA',
    model: 'B-Series Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2006,
    make: 'MAZDA',
    model: 'MAZDA5',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan', 'Hatchback', 'Wagon']
  },
  {
    year: 2006,
    make: 'MAZDA',
    model: 'MPV',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2006,
    make: 'MAZDA',
    model: 'RX-8',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'MAZDA',
    model: 'Tribute',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Mercedes-Benz',
    model: 'CLK-Class',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2006,
    make: 'Mercedes-Benz',
    model: 'CLS-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2006,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Mercedes-Benz',
    model: 'R-Class',
    body_styles: ['Wagon']
  },
  {
    year: 2006,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2006,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2006,
    make: 'Mercedes-Benz',
    model: 'SLR McLaren',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Mercury',
    model: 'Mariner',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Mercury',
    model: 'Milan',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Mercury',
    model: 'Montego',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Mercury',
    model: 'Monterey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2006,
    make: 'MINI',
    model: 'Cooper',
    body_styles: ['Hatchback']
  },
  {
    year: 2006,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Mitsubishi',
    model: 'Endeavor',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Mitsubishi',
    model: 'Montero',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Mitsubishi',
    model: 'Raider Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Mitsubishi',
    model: 'Raider Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Nissan',
    model: '350Z',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2006,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Panoz',
    model: 'Esperante',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2006,
    make: 'Pontiac',
    model: 'G6',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 2006,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Pontiac',
    model: 'GTO',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Pontiac',
    model: 'Montana SV6',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Pontiac',
    model: 'Solstice',
    body_styles: ['Convertible']
  },
  {
    year: 2006,
    make: 'Pontiac',
    model: 'Torrent',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Pontiac',
    model: 'Vibe',
    body_styles: ['Wagon']
  },
  {
    year: 2006,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2006,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2006,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Porsche',
    model: 'Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Saab',
    model: '9-2X',
    body_styles: ['Wagon']
  },
  {
    year: 2006,
    make: 'Saab',
    model: '9-3',
    body_styles: ['Sedan', 'Convertible', 'Wagon']
  },
  {
    year: 2006,
    make: 'Saab',
    model: '9-5',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2006,
    make: 'Saab',
    model: '9-7X',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Saturn',
    model: 'Ion',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2006,
    make: 'Saturn',
    model: 'Relay',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Saturn',
    model: 'VUE',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Scion',
    model: 'tC',
    body_styles: ['Coupe']
  },
  {
    year: 2006,
    make: 'Scion',
    model: 'xA',
    body_styles: ['Hatchback']
  },
  {
    year: 2006,
    make: 'Scion',
    model: 'xB',
    body_styles: ['Hatchback']
  },
  {
    year: 2006,
    make: 'Subaru',
    model: 'B9 Tribeca',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Subaru',
    model: 'Baja',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2006,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2006,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2006,
    make: 'Suzuki',
    model: 'Aerio',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2006,
    make: 'Suzuki',
    model: 'Forenza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2006,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Suzuki',
    model: 'Reno',
    body_styles: ['Hatchback']
  },
  {
    year: 2006,
    make: 'Suzuki',
    model: 'Verona',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Suzuki',
    model: 'XL-7',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Matrix',
    body_styles: ['Wagon']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Solara',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Tundra Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2006,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2006,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2006,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Volkswagen',
    model: 'New Beetle',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2006,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Volkswagen',
    model: 'Phaeton',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Volkswagen',
    model: 'Rabbit',
    body_styles: ['Hatchback']
  },
  {
    year: 2006,
    make: 'Volkswagen',
    model: 'Touareg',
    body_styles: ['SUV']
  },
  {
    year: 2006,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Convertible']
  },
  {
    year: 2006,
    make: 'Volvo',
    model: 'S40',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2006,
    make: 'Volvo',
    model: 'V50',
    body_styles: ['Wagon']
  },
  {
    year: 2006,
    make: 'Volvo',
    model: 'V70',
    body_styles: ['Wagon']
  },
  {
    year: 2006,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2006,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Acura',
    model: 'TSX',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Aston Martin',
    model: 'DB9',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2007,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2007,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Wagon']
  },
  {
    year: 2007,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan', 'Wagon', 'Convertible']
  },
  {
    year: 2007,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2007,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Audi',
    model: 'RS 4',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan', 'Wagon', 'Convertible']
  },
  {
    year: 2007,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Bentley',
    model: 'Arnage',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Bentley',
    model: 'Azure',
    body_styles: ['Convertible']
  },
  {
    year: 2007,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2007,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Coupe', 'Sedan', 'Convertible', 'Wagon']
  },
  {
    year: 2007,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2007,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2007,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'BMW',
    model: 'Alpina B7',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'BMW',
    model: 'M6',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2007,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2007,
    make: 'BMW',
    model: 'Z4 M',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2007,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Buick',
    model: 'Lucerne',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Buick',
    model: 'Rainier',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Buick',
    model: 'Rendezvous',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Buick',
    model: 'Terraza',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Cadillac',
    model: 'DTS',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Cadillac',
    model: 'Escalade EXT',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Cadillac',
    model: 'SRX',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Cadillac',
    model: 'STS',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Cadillac',
    model: 'XLR',
    body_styles: ['Convertible']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Avalanche',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Aveo',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Cobalt',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Colorado Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'HHR',
    body_styles: ['Wagon']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Monte Carlo',
    body_styles: ['Coupe']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado (Classic) 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado (Classic) 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado (Classic) 1500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado (Classic) 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado (Classic) 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado (Classic) 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado (Classic) 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado (Classic) 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado (Classic) 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado (Classic) 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'TrailBlazer',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Uplander Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Chevrolet',
    model: 'Uplander Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Chrysler',
    model: 'Aspen',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Chrysler',
    model: 'Crossfire',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2007,
    make: 'Chrysler',
    model: 'Pacifica',
    body_styles: ['Wagon']
  },
  {
    year: 2007,
    make: 'Chrysler',
    model: 'PT Cruiser',
    body_styles: ['Wagon', 'Convertible']
  },
  {
    year: 2007,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Caliber',
    body_styles: ['Wagon']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Dakota Club Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Dakota Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Grand Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Magnum',
    body_styles: ['Wagon']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Nitro',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Ram 1500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Ram 1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Ram 2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Ram 2500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Ram 3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Ram 3500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Dodge',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Ferrari',
    model: '599 GTB Fiorano',
    body_styles: ['Coupe']
  },
  {
    year: 2007,
    make: 'Ferrari',
    model: '612 Scaglietti',
    body_styles: ['Coupe']
  },
  {
    year: 2007,
    make: 'Ferrari',
    model: 'F430',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'E150 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'E150 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'E250 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Expedition EL',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Explorer Sport Trac',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Five Hundred',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Hatchback', 'Sedan', 'Wagon']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Freestar Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Freestar Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Freestyle',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Canyon Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Envoy',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra (Classic) 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra (Classic) 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra (Classic) 1500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra (Classic) 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra (Classic) 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra (Classic) 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra (Classic) 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra (Classic) 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra (Classic) 3500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra (Classic) 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2007,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2007,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Honda',
    model: 'Element',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Honda',
    model: 'Fit',
    body_styles: ['Hatchback']
  },
  {
    year: 2007,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Honda',
    model: 'S2000',
    body_styles: ['Convertible']
  },
  {
    year: 2007,
    make: 'HUMMER',
    model: 'H2',
    body_styles: ['SUV', 'Pickup']
  },
  {
    year: 2007,
    make: 'HUMMER',
    model: 'H3',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2007,
    make: 'Hyundai',
    model: 'Azera',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Hyundai',
    model: 'Entourage',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Hyundai',
    model: 'Tiburon',
    body_styles: ['Coupe']
  },
  {
    year: 2007,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Hyundai',
    model: 'Veracruz',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'INFINITI',
    model: 'FX',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2007,
    make: 'INFINITI',
    model: 'M',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Isuzu',
    model: 'Ascender',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Isuzu',
    model: 'i-290 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Isuzu',
    model: 'i-370 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Isuzu',
    model: 'i-370 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Jaguar',
    model: 'S-Type',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2007,
    make: 'Jaguar',
    model: 'X-Type',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2007,
    make: 'Jeep',
    model: 'Commander',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Jeep',
    model: 'Liberty',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Jeep',
    model: 'Patriot',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Kia',
    model: 'Amanti',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2007,
    make: 'Kia',
    model: 'Rondo',
    body_styles: ['Wagon']
  },
  {
    year: 2007,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Kia',
    model: 'Spectra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2007,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Lamborghini',
    model: 'Gallardo',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2007,
    make: 'Lamborghini',
    model: 'Murcielago',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2007,
    make: 'Land Rover',
    model: 'LR3',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Convertible']
  },
  {
    year: 2007,
    make: 'Lincoln',
    model: 'Mark LT',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Lincoln',
    model: 'MKX',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Lotus',
    model: 'Elise',
    body_styles: ['Coupe']
  },
  {
    year: 2007,
    make: 'Lotus',
    model: 'Exige S',
    body_styles: ['Coupe']
  },
  {
    year: 2007,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Maybach',
    model: '57',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Maybach',
    model: '62',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'MAZDA',
    model: 'B-Series Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'MAZDA',
    model: 'CX-7',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2007,
    make: 'MAZDA',
    model: 'MAZDA5',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan', 'Hatchback', 'Wagon']
  },
  {
    year: 2007,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2007,
    make: 'MAZDA',
    model: 'RX-8',
    body_styles: ['Coupe']
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    model: 'CLK-Class',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    model: 'CLS-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    model: 'GL-Class',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    model: 'R-Class',
    body_styles: ['Wagon']
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2007,
    make: 'Mercedes-Benz',
    model: 'SLR McLaren',
    body_styles: ['Coupe']
  },
  {
    year: 2007,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Mercury',
    model: 'Mariner',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Mercury',
    model: 'Milan',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Mercury',
    model: 'Montego',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Mercury',
    model: 'Monterey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2007,
    make: 'MINI',
    model: 'Cooper',
    body_styles: ['Hatchback']
  },
  {
    year: 2007,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2007,
    make: 'Mitsubishi',
    model: 'Endeavor',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Mitsubishi',
    model: 'Raider Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Mitsubishi',
    model: 'Raider Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: '350Z',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2007,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Pontiac',
    model: 'G5',
    body_styles: ['Coupe']
  },
  {
    year: 2007,
    make: 'Pontiac',
    model: 'G6',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2007,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Pontiac',
    model: 'Solstice',
    body_styles: ['Convertible']
  },
  {
    year: 2007,
    make: 'Pontiac',
    model: 'Torrent',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Pontiac',
    model: 'Vibe',
    body_styles: ['Hatchback']
  },
  {
    year: 2007,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2007,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2007,
    make: 'Porsche',
    model: 'Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2007,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Saab',
    model: '9-3',
    body_styles: ['Wagon', 'Sedan', 'Convertible']
  },
  {
    year: 2007,
    make: 'Saab',
    model: '9-5',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2007,
    make: 'Saab',
    model: '9-7X',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Saturn',
    model: 'Aura',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Saturn',
    model: 'Ion',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2007,
    make: 'Saturn',
    model: 'Outlook',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Saturn',
    model: 'Relay',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Saturn',
    model: 'SKY',
    body_styles: ['Convertible']
  },
  {
    year: 2007,
    make: 'Saturn',
    model: 'VUE',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Scion',
    model: 'tC',
    body_styles: ['Coupe']
  },
  {
    year: 2007,
    make: 'Subaru',
    model: 'B9 Tribeca',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2007,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2007,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2007,
    make: 'Suzuki',
    model: 'Aerio',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Suzuki',
    model: 'Forenza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2007,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Suzuki',
    model: 'Reno',
    body_styles: ['Hatchback']
  },
  {
    year: 2007,
    make: 'Suzuki',
    model: 'SX4',
    body_styles: ['Hatchback']
  },
  {
    year: 2007,
    make: 'Suzuki',
    model: 'XL7',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'FJ Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Matrix',
    body_styles: ['Wagon']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Solara',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2007,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2007,
    make: 'Volkswagen',
    model: 'Eos',
    body_styles: ['Convertible']
  },
  {
    year: 2007,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2007,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Volkswagen',
    model: 'New Beetle',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2007,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2007,
    make: 'Volkswagen',
    model: 'Rabbit',
    body_styles: ['Hatchback']
  },
  {
    year: 2007,
    make: 'Volkswagen',
    model: 'Touareg',
    body_styles: ['SUV']
  },
  {
    year: 2007,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Convertible']
  },
  {
    year: 2007,
    make: 'Volvo',
    model: 'S40',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2007,
    make: 'Volvo',
    model: 'V50',
    body_styles: ['Wagon']
  },
  {
    year: 2007,
    make: 'Volvo',
    model: 'V70',
    body_styles: ['Wagon']
  },
  {
    year: 2007,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2007,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Acura',
    model: 'TSX',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Aston Martin',
    model: 'DB9',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2008,
    make: 'Aston Martin',
    model: 'DBS',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Wagon']
  },
  {
    year: 2008,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Convertible', 'Sedan', 'Wagon']
  },
  {
    year: 2008,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2008,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Audi',
    model: 'R8',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Audi',
    model: 'RS 4',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2008,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan', 'Wagon', 'Convertible']
  },
  {
    year: 2008,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2008,
    make: 'Bentley',
    model: 'Arnage',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Bentley',
    model: 'Azure',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'BMW',
    model: '1 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Coupe', 'Wagon', 'Convertible']
  },
  {
    year: 2008,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2008,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2008,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'BMW',
    model: 'Alpina B7',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2008,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'BMW',
    model: 'M6',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2008,
    make: 'BMW',
    model: 'Z4 M',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Buick',
    model: 'Lucerne',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Cadillac',
    model: 'DTS',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Cadillac',
    model: 'Escalade EXT',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Cadillac',
    model: 'SRX',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Cadillac',
    model: 'STS',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Cadillac',
    model: 'XLR',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Avalanche',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Aveo',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Cobalt',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Colorado Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'HHR',
    body_styles: ['Wagon']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Malibu (Classic)',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'TrailBlazer',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Uplander Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Chevrolet',
    model: 'Uplander Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Chrysler',
    model: 'Aspen',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Chrysler',
    model: 'Crossfire',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'Chrysler',
    model: 'Pacifica',
    body_styles: ['Wagon']
  },
  {
    year: 2008,
    make: 'Chrysler',
    model: 'PT Cruiser',
    body_styles: ['Wagon', 'Convertible']
  },
  {
    year: 2008,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2008,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Avenger',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Caliber',
    body_styles: ['Wagon']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Dakota Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Dakota Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Grand Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Magnum',
    body_styles: ['Wagon']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Nitro',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Ram 1500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Ram 1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Ram 2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Ram 2500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Ram 3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Ram 3500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'Ferrari',
    model: '430 Scuderia',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Ferrari',
    model: '599 GTB Fiorano',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Ferrari',
    model: '612 Scaglietti',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Ferrari',
    model: 'F430',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'E150 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Expedition EL',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Explorer Sport Trac',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Ford',
    model: 'Taurus X',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Canyon Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Envoy',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Sierra 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2008,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2008,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Honda',
    model: 'Element',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Honda',
    model: 'Fit',
    body_styles: ['Hatchback']
  },
  {
    year: 2008,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Honda',
    model: 'S2000',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'HUMMER',
    model: 'H2',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'HUMMER',
    model: 'H3',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2008,
    make: 'Hyundai',
    model: 'Azera',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Hyundai',
    model: 'Entourage',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Hyundai',
    model: 'Tiburon',
    body_styles: ['Hatchback']
  },
  {
    year: 2008,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Hyundai',
    model: 'Veracruz',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'INFINITI',
    model: 'EX',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'INFINITI',
    model: 'FX',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2008,
    make: 'INFINITI',
    model: 'M',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Isuzu',
    model: 'Ascender',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Isuzu',
    model: 'i-290 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Isuzu',
    model: 'i-370 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Isuzu',
    model: 'i-370 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Jaguar',
    model: 'S-Type',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'Jaguar',
    model: 'X-Type',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2008,
    make: 'Jeep',
    model: 'Commander',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Jeep',
    model: 'Liberty',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Jeep',
    model: 'Patriot',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Kia',
    model: 'Amanti',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2008,
    make: 'Kia',
    model: 'Rondo',
    body_styles: ['Wagon']
  },
  {
    year: 2008,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Kia',
    model: 'Spectra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2008,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Lamborghini',
    model: 'Gallardo',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2008,
    make: 'Lamborghini',
    model: 'Murcielago LP640',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'Land Rover',
    model: 'LR2',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Land Rover',
    model: 'LR3',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Lexus',
    model: 'IS F',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'Lincoln',
    model: 'Mark LT',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Lincoln',
    model: 'MKX',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Lotus',
    model: 'Elise',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Lotus',
    model: 'Exige S',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Maserati',
    model: 'GranTurismo',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Maybach',
    model: '57',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Maybach',
    model: '62',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'MAZDA',
    model: 'B-Series Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'MAZDA',
    model: 'CX-7',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2008,
    make: 'MAZDA',
    model: 'MAZDA5',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2008,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'MAZDA',
    model: 'RX-8',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'MAZDA',
    model: 'Tribute',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    model: 'CLK-Class',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    model: 'CLS-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    model: 'GL-Class',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    model: 'R-Class',
    body_styles: ['Wagon']
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'Mercedes-Benz',
    model: 'SLR McLaren',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Mercury',
    model: 'Mariner',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Mercury',
    model: 'Milan',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2008,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'MINI',
    model: 'Cooper',
    body_styles: ['Hatchback']
  },
  {
    year: 2008,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2008,
    make: 'Mitsubishi',
    model: 'Endeavor',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Mitsubishi',
    model: 'Raider Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Mitsubishi',
    model: 'Raider Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: '350Z',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2008,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Pontiac',
    model: 'G5',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Pontiac',
    model: 'G6',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'Pontiac',
    model: 'G8',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Pontiac',
    model: 'Grand Prix',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Pontiac',
    model: 'Solstice',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'Pontiac',
    model: 'Torrent',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Pontiac',
    model: 'Vibe',
    body_styles: ['Wagon']
  },
  {
    year: 2008,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Porsche',
    model: 'Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 2008,
    make: 'Saab',
    model: '9-3',
    body_styles: ['Sedan', 'Convertible', 'Wagon']
  },
  {
    year: 2008,
    make: 'Saab',
    model: '9-5',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2008,
    make: 'Saab',
    model: '9-7X',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Saturn',
    model: 'Astra',
    body_styles: ['Hatchback']
  },
  {
    year: 2008,
    make: 'Saturn',
    model: 'Aura',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Saturn',
    model: 'Outlook',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Saturn',
    model: 'SKY',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'Saturn',
    model: 'VUE',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Scion',
    model: 'tC',
    body_styles: ['Coupe']
  },
  {
    year: 2008,
    make: 'Scion',
    model: 'xB',
    body_styles: ['Hatchback']
  },
  {
    year: 2008,
    make: 'Scion',
    model: 'xD',
    body_styles: ['Hatchback']
  },
  {
    year: 2008,
    make: 'smart',
    model: 'fortwo',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2008,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2008,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Wagon']
  },
  {
    year: 2008,
    make: 'Subaru',
    model: 'Tribeca',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Suzuki',
    model: 'Forenza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2008,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Suzuki',
    model: 'Reno',
    body_styles: ['Hatchback']
  },
  {
    year: 2008,
    make: 'Suzuki',
    model: 'SX4',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2008,
    make: 'Suzuki',
    model: 'XL7',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'FJ Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Matrix',
    body_styles: ['Wagon']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Solara',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2008,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2008,
    make: 'Volkswagen',
    model: 'Eos',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'Volkswagen',
    model: 'GLI',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2008,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Volkswagen',
    model: 'New Beetle',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2008,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2008,
    make: 'Volkswagen',
    model: 'R32',
    body_styles: ['Hatchback']
  },
  {
    year: 2008,
    make: 'Volkswagen',
    model: 'Rabbit',
    body_styles: ['Hatchback']
  },
  {
    year: 2008,
    make: 'Volkswagen',
    model: 'Touareg 2',
    body_styles: ['SUV']
  },
  {
    year: 2008,
    make: 'Volvo',
    model: 'C30',
    body_styles: ['Hatchback']
  },
  {
    year: 2008,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Convertible']
  },
  {
    year: 2008,
    make: 'Volvo',
    model: 'S40',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2008,
    make: 'Volvo',
    model: 'V50',
    body_styles: ['Wagon']
  },
  {
    year: 2008,
    make: 'Volvo',
    model: 'V70',
    body_styles: ['Wagon']
  },
  {
    year: 2008,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2008,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Acura',
    model: 'TSX',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Aston Martin',
    model: 'DB9',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2009,
    make: 'Aston Martin',
    model: 'DBS',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2009,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2009,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Convertible', 'Wagon', 'Sedan']
  },
  {
    year: 2009,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2009,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Audi',
    model: 'R8',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2009,
    make: 'Bentley',
    model: 'Arnage',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Bentley',
    model: 'Azure',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Bentley',
    model: 'Brooklands',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2009,
    make: 'BMW',
    model: '1 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2009,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Convertible', 'Coupe', 'Wagon']
  },
  {
    year: 2009,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2009,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2009,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2009,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'BMW',
    model: 'M6',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2009,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Buick',
    model: 'Lucerne',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Cadillac',
    model: 'DTS',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Cadillac',
    model: 'Escalade EXT',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Cadillac',
    model: 'SRX',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Cadillac',
    model: 'STS',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Cadillac',
    model: 'XLR',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Avalanche',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Aveo',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Cobalt',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Colorado Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'HHR',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'TrailBlazer',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Chrysler',
    model: 'Aspen',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Chrysler',
    model: 'PT Cruiser',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2009,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Avenger',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Caliber',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Dakota Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Dakota Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Grand Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Journey',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Nitro',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Ram 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Ram 1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Ram 2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Ram 2500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Ram 3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Ram 3500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2009,
    make: 'Ferrari',
    model: '430 Scuderia',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2009,
    make: 'Ferrari',
    model: '599 GTB Fiorano',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Ferrari',
    model: '612 Scaglietti',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Ferrari',
    model: 'California',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Ferrari',
    model: 'F430',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'E150 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Expedition EL',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Explorer Sport Trac',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Flex',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Ford',
    model: 'Taurus X',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Canyon Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Envoy',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Sierra 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2009,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2009,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Honda',
    model: 'Element',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Honda',
    model: 'Fit',
    body_styles: ['Hatchback']
  },
  {
    year: 2009,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Honda',
    model: 'S2000',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'HUMMER',
    model: 'H2',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'HUMMER',
    model: 'H3',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'HUMMER',
    model: 'H3T',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2009,
    make: 'Hyundai',
    model: 'Azera',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2009,
    make: 'Hyundai',
    model: 'Genesis',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Hyundai',
    model: 'Veracruz',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'INFINITI',
    model: 'EX',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'INFINITI',
    model: 'FX',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2009,
    make: 'INFINITI',
    model: 'M',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2009,
    make: 'Jeep',
    model: 'Commander',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Jeep',
    model: 'Liberty',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Jeep',
    model: 'Patriot',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Kia',
    model: 'Amanti',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Kia',
    model: 'Borrego',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2009,
    make: 'Kia',
    model: 'Rondo',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Kia',
    model: 'Spectra',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2009,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Lamborghini',
    model: 'Gallardo',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2009,
    make: 'Lamborghini',
    model: 'Murcielago',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2009,
    make: 'Land Rover',
    model: 'LR2',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Land Rover',
    model: 'LR3',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Lexus',
    model: 'IS F',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Lincoln',
    model: 'MKS',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Lincoln',
    model: 'MKX',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Lotus',
    model: 'Elise',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Lotus',
    model: 'Exige',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Maserati',
    model: 'GranTurismo',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Maybach',
    model: '57',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Maybach',
    model: '62',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'MAZDA',
    model: 'B-Series Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'MAZDA',
    model: 'B-Series Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'MAZDA',
    model: 'CX-7',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2009,
    make: 'MAZDA',
    model: 'MAZDA5',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'MAZDA',
    model: 'RX-8',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'MAZDA',
    model: 'Tribute',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    model: 'CLK-Class',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    model: 'CLS-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    model: 'GL-Class',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    model: 'R-Class',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Mercedes-Benz',
    model: 'SLR McLaren',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Mercury',
    model: 'Mariner',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Mercury',
    model: 'Milan',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Mercury',
    model: 'Sable',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2009,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'MINI',
    model: 'Hardtop',
    body_styles: ['Hatchback']
  },
  {
    year: 2009,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2009,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Mitsubishi',
    model: 'Raider Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Mitsubishi',
    model: 'Raider Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: '350Z',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: '370Z',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'cube',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'GT-R',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2009,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Pontiac',
    model: 'G3',
    body_styles: ['Hatchback']
  },
  {
    year: 2009,
    make: 'Pontiac',
    model: 'G5',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Pontiac',
    model: 'G6',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2009,
    make: 'Pontiac',
    model: 'G6 (2009.5)',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2009,
    make: 'Pontiac',
    model: 'G8',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Pontiac',
    model: 'Solstice',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2009,
    make: 'Pontiac',
    model: 'Torrent',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Pontiac',
    model: 'Vibe',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2009,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Porsche',
    model: 'Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Convertible', 'Coupe', 'Sedan']
  },
  {
    year: 2009,
    make: 'Saab',
    model: '9-3',
    body_styles: ['Sedan', 'Convertible', 'Wagon']
  },
  {
    year: 2009,
    make: 'Saab',
    model: '9-5',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2009,
    make: 'Saab',
    model: '9-7X',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Saturn',
    model: 'Aura',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Saturn',
    model: 'Outlook',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Saturn',
    model: 'SKY',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Saturn',
    model: 'VUE',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Scion',
    model: 'tC',
    body_styles: ['Coupe']
  },
  {
    year: 2009,
    make: 'Scion',
    model: 'xB',
    body_styles: ['Hatchback']
  },
  {
    year: 2009,
    make: 'Scion',
    model: 'xD',
    body_styles: ['Hatchback']
  },
  {
    year: 2009,
    make: 'smart',
    model: 'fortwo',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2009,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2009,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Subaru',
    model: 'Tribeca',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Suzuki',
    model: 'Equator Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Suzuki',
    model: 'Equator Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Suzuki',
    model: 'SX4',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2009,
    make: 'Suzuki',
    model: 'XL7',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'FJ Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Matrix',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Venza',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2009,
    make: 'Volkswagen',
    model: 'CC',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Volkswagen',
    model: 'Eos',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Volkswagen',
    model: 'GLI',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2009,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2009,
    make: 'Volkswagen',
    model: 'New Beetle',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2009,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2009,
    make: 'Volkswagen',
    model: 'Rabbit',
    body_styles: ['Hatchback']
  },
  {
    year: 2009,
    make: 'Volkswagen',
    model: 'Routan',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2009,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Volkswagen',
    model: 'Touareg 2',
    body_styles: ['SUV']
  },
  {
    year: 2009,
    make: 'Volvo',
    model: 'C30',
    body_styles: ['Hatchback']
  },
  {
    year: 2009,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Convertible']
  },
  {
    year: 2009,
    make: 'Volvo',
    model: 'S40',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2009,
    make: 'Volvo',
    model: 'V50',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Volvo',
    model: 'V70',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2009,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Acura',
    model: 'TSX',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Acura',
    model: 'ZDX',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Aston Martin',
    model: 'DB9',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'Aston Martin',
    model: 'DBS',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'Aston Martin',
    model: 'Rapide',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2010,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2010,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Audi',
    model: 'R8',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'Bentley',
    model: 'Azure T',
    body_styles: ['Convertible']
  },
  {
    year: 2010,
    make: 'Bentley',
    model: 'Brooklands',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 2010,
    make: 'BMW',
    model: '1 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Coupe', 'Convertible', 'Wagon']
  },
  {
    year: 2010,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2010,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2010,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'BMW',
    model: 'M6',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'BMW',
    model: 'X5 M',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'BMW',
    model: 'X6 M',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2010,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Buick',
    model: 'Lucerne',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2010,
    make: 'Cadillac',
    model: 'DTS',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Cadillac',
    model: 'Escalade EXT',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Cadillac',
    model: 'SRX',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Cadillac',
    model: 'STS',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Avalanche',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Aveo',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Cobalt',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Colorado Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'HHR',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Chrysler',
    model: 'PT Cruiser',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Chrysler',
    model: 'Sebring',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2010,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Avenger',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Caliber',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Dakota Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Dakota Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Grand Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Journey',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Nitro',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Ram 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Ram 1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Ram 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Ram 2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Ram 2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Ram 2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Ram 3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Ram 3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Ram 3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2010,
    make: 'Ferrari',
    model: '458 Italia',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Ferrari',
    model: '599 GTB Fiorano',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Ferrari',
    model: '612 Scaglietti',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Ferrari',
    model: 'California',
    body_styles: ['Convertible']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'E150 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Expedition EL',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Explorer Sport Trac',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Flex',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Transit Connect Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Ford',
    model: 'Transit Connect Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Canyon Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Sierra 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2010,
    make: 'Honda',
    model: 'Accord Crosstour',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2010,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Honda',
    model: 'Element',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Honda',
    model: 'Fit',
    body_styles: ['Hatchback']
  },
  {
    year: 2010,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Hatchback']
  },
  {
    year: 2010,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'HUMMER',
    model: 'H3',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'HUMMER',
    model: 'H3T',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2010,
    make: 'Hyundai',
    model: 'Azera',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2010,
    make: 'Hyundai',
    model: 'Genesis',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Hyundai',
    model: 'Genesis Coupe',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Hyundai',
    model: 'Veracruz',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'INFINITI',
    model: 'EX',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'INFINITI',
    model: 'FX',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2010,
    make: 'INFINITI',
    model: 'M',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'Jeep',
    model: 'Commander',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Jeep',
    model: 'Liberty',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Jeep',
    model: 'Patriot',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2010,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2010,
    make: 'Kia',
    model: 'Rondo',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Lamborghini',
    model: 'Gallardo',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'Lamborghini',
    model: 'Murcielago',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'Land Rover',
    model: 'LR2',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Land Rover',
    model: 'LR4',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Lexus',
    model: 'HS',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Convertible', 'Sedan']
  },
  {
    year: 2010,
    make: 'Lexus',
    model: 'IS F',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Lexus',
    model: 'SC',
    body_styles: ['Convertible']
  },
  {
    year: 2010,
    make: 'Lincoln',
    model: 'MKS',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Lincoln',
    model: 'MKT',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Lincoln',
    model: 'MKX',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Lotus',
    model: 'Elise',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Lotus',
    model: 'Evora',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Lotus',
    model: 'Exige',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Maserati',
    model: 'GranTurismo',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2010,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Maybach',
    model: '57',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Maybach',
    model: '62',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'MAZDA',
    model: 'CX-7',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2010,
    make: 'MAZDA',
    model: 'MAZDA5',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2010,
    make: 'MAZDA',
    model: 'RX-8',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'MAZDA',
    model: 'Tribute',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Mercedes-Benz',
    model: 'CLS-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2010,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Mercedes-Benz',
    model: 'GL-Class',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Mercedes-Benz',
    model: 'GLK-Class',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Mercedes-Benz',
    model: 'R-Class',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2010,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Mercury',
    model: 'Mariner',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Mercury',
    model: 'Milan',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Mercury',
    model: 'Mountaineer',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2010,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2010,
    make: 'MINI',
    model: 'Hardtop',
    body_styles: ['Hatchback']
  },
  {
    year: 2010,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'Mitsubishi',
    model: 'Endeavor',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2010,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: '370Z',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'cube',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'GT-R',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2010,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Pontiac',
    model: 'G3',
    body_styles: ['Hatchback']
  },
  {
    year: 2010,
    make: 'Pontiac',
    model: 'G6',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Pontiac',
    model: 'Vibe',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2010,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2010,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Porsche',
    model: 'Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Porsche',
    model: 'Panamera',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Rolls-Royce',
    model: 'Ghost',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 2010,
    make: 'Saab',
    model: '9-3',
    body_styles: ['Wagon', 'Convertible', 'Sedan']
  },
  {
    year: 2010,
    make: 'Saab',
    model: '9-5',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Saturn',
    model: 'Outlook',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Saturn',
    model: 'VUE',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Scion',
    model: 'tC',
    body_styles: ['Coupe']
  },
  {
    year: 2010,
    make: 'Scion',
    model: 'xB',
    body_styles: ['Hatchback']
  },
  {
    year: 2010,
    make: 'Scion',
    model: 'xD',
    body_styles: ['Hatchback']
  },
  {
    year: 2010,
    make: 'smart',
    model: 'fortwo',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2010,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2010,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Subaru',
    model: 'Tribeca',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Suzuki',
    model: 'Equator Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Suzuki',
    model: 'Equator Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Suzuki',
    model: 'Kizashi',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Suzuki',
    model: 'SX4',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'FJ Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Matrix',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Venza',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2010,
    make: 'Volkswagen',
    model: 'CC',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Volkswagen',
    model: 'Eos',
    body_styles: ['Convertible']
  },
  {
    year: 2010,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2010,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2010,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2010,
    make: 'Volkswagen',
    model: 'New Beetle',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2010,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2010,
    make: 'Volkswagen',
    model: 'Routan',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2010,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Volkswagen',
    model: 'Touareg',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Volvo',
    model: 'C30',
    body_styles: ['Hatchback']
  },
  {
    year: 2010,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Convertible']
  },
  {
    year: 2010,
    make: 'Volvo',
    model: 'S40',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2010,
    make: 'Volvo',
    model: 'V50',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Volvo',
    model: 'V70',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2010,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2010,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Acura',
    model: 'TSX',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2011,
    make: 'Acura',
    model: 'ZDX',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Aston Martin',
    model: 'DB9',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2011,
    make: 'Aston Martin',
    model: 'DBS',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2011,
    make: 'Aston Martin',
    model: 'Rapide',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2011,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Wagon']
  },
  {
    year: 2011,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2011,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2011,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2011,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Audi',
    model: 'R8',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2011,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2011,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2011,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 2011,
    make: 'Bentley',
    model: 'Mulsanne',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'BMW',
    model: '1 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2011,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Coupe', 'Convertible', 'Wagon']
  },
  {
    year: 2011,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Convertible', 'Coupe', 'Sedan']
  },
  {
    year: 2011,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'BMW',
    model: 'X5 M',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'BMW',
    model: 'X6 M',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2011,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Buick',
    model: 'Lucerne',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan', 'Wagon', 'Coupe']
  },
  {
    year: 2011,
    make: 'Cadillac',
    model: 'DTS',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Cadillac',
    model: 'Escalade EXT',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Cadillac',
    model: 'SRX',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Cadillac',
    model: 'STS',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Avalanche',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Aveo',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Colorado Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Cruze',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'HHR',
    body_styles: ['Wagon']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Chevrolet',
    model: 'Volt',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Chrysler',
    model: '200',
    body_styles: ['Convertible', 'Sedan']
  },
  {
    year: 2011,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Dodge',
    model: 'Avenger',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Dodge',
    model: 'Caliber',
    body_styles: ['Wagon']
  },
  {
    year: 2011,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Dodge',
    model: 'Grand Caravan Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Dodge',
    model: 'Journey',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Dodge',
    model: 'Nitro',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Ferrari',
    model: '458 Italia',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Ferrari',
    model: '599 GTB Fiorano',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Ferrari',
    model: '599 GTO',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Ferrari',
    model: '612 Scaglietti',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Ferrari',
    model: 'California',
    body_styles: ['Convertible']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Crown Victoria',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'E150 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Expedition EL',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Fiesta',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Flex',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Ranger Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Ranger Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Transit Connect Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Ford',
    model: 'Transit Connect Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Canyon Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Sierra 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2011,
    make: 'Honda',
    model: 'Accord Crosstour',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2011,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Honda',
    model: 'CR-Z',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Honda',
    model: 'Element',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Honda',
    model: 'Fit',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2011,
    make: 'Hyundai',
    model: 'Azera',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2011,
    make: 'Hyundai',
    model: 'Equus',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Hyundai',
    model: 'Genesis',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Hyundai',
    model: 'Genesis Coupe',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Hyundai',
    model: 'Veracruz',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'INFINITI',
    model: 'EX',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'INFINITI',
    model: 'FX',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2011,
    make: 'INFINITI',
    model: 'M',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2011,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Jeep',
    model: 'Liberty',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Jeep',
    model: 'Patriot',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan', 'Coupe', 'Hatchback']
  },
  {
    year: 2011,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2011,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2011,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Lamborghini',
    model: 'Gallardo',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2011,
    make: 'Land Rover',
    model: 'LR2',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Land Rover',
    model: 'LR4',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Lexus',
    model: 'CT',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Lexus',
    model: 'HS',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2011,
    make: 'Lexus',
    model: 'IS F',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Lincoln',
    model: 'MKS',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Lincoln',
    model: 'MKT',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Lincoln',
    model: 'MKX',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Lincoln',
    model: 'Town Car',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Lotus',
    model: 'Elise',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Lotus',
    model: 'Evora',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Lotus',
    model: 'Exige',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Maserati',
    model: 'GranTurismo',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2011,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Maybach',
    model: '57',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Maybach',
    model: '62',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'MAZDA',
    model: 'CX-7',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'MAZDA',
    model: 'MAZDA2',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2011,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2011,
    make: 'MAZDA',
    model: 'RX-8',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'MAZDA',
    model: 'Tribute',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'CLS-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Coupe', 'Convertible', 'Sedan', 'Wagon']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'GL-Class',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'GLK-Class',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'R-Class',
    body_styles: ['Wagon']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'SLS-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Mercury',
    model: 'Grand Marquis',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Mercury',
    model: 'Mariner',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Mercury',
    model: 'Milan',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2011,
    make: 'MINI',
    model: 'Countryman',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'MINI',
    model: 'Hardtop',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2011,
    make: 'Mitsubishi',
    model: 'Endeavor',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2011,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Mitsubishi',
    model: 'Outlander Sport',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: '370Z',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'cube',
    body_styles: ['Wagon']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'GT-R',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'JUKE',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'LEAF',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2011,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Porsche',
    model: '911',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2011,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2011,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Porsche',
    model: 'Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Porsche',
    model: 'Panamera',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Ram',
    model: '1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ram',
    model: '1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ram',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ram',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ram',
    model: '2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ram',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ram',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ram',
    model: '3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ram',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ram',
    model: 'Dakota Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Ram',
    model: 'Dakota Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Rolls-Royce',
    model: 'Ghost',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 2011,
    make: 'Saab',
    model: '9-3',
    body_styles: ['Sedan', 'Convertible', 'Wagon']
  },
  {
    year: 2011,
    make: 'Saab',
    model: '9-4X',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Saab',
    model: '9-5',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Scion',
    model: 'tC',
    body_styles: ['Coupe']
  },
  {
    year: 2011,
    make: 'Scion',
    model: 'xB',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'Scion',
    model: 'xD',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'smart',
    model: 'fortwo',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2011,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2011,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Wagon']
  },
  {
    year: 2011,
    make: 'Subaru',
    model: 'Tribeca',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Suzuki',
    model: 'Equator Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Suzuki',
    model: 'Equator Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Suzuki',
    model: 'Kizashi',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Suzuki',
    model: 'SX4',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'FJ Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Matrix',
    body_styles: ['Wagon']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Venza',
    body_styles: ['Wagon']
  },
  {
    year: 2011,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2011,
    make: 'Volkswagen',
    model: 'CC',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Volkswagen',
    model: 'Eos',
    body_styles: ['Convertible']
  },
  {
    year: 2011,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2011,
    make: 'Volkswagen',
    model: 'Routan',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2011,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Volkswagen',
    model: 'Touareg',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Volvo',
    model: 'C30',
    body_styles: ['Hatchback']
  },
  {
    year: 2011,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Convertible']
  },
  {
    year: 2011,
    make: 'Volvo',
    model: 'S40',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2011,
    make: 'Volvo',
    model: 'V50',
    body_styles: ['Wagon']
  },
  {
    year: 2011,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2011,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2011,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Acura',
    model: 'RL',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Acura',
    model: 'TSX',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2012,
    make: 'Acura',
    model: 'ZDX',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Aston Martin',
    model: 'DB9',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Aston Martin',
    model: 'DBS',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Aston Martin',
    model: 'Rapide',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Aston Martin',
    model: 'Virage',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Wagon']
  },
  {
    year: 2012,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2012,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2012,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Audi',
    model: 'A7',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Audi',
    model: 'R8',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2012,
    make: 'Bentley',
    model: 'Mulsanne',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'BMW',
    model: '1 Series',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2012,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Coupe', 'Convertible', 'Wagon', 'Sedan']
  },
  {
    year: 2012,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2012,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2012,
    make: 'BMW',
    model: 'M6',
    body_styles: ['Convertible']
  },
  {
    year: 2012,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'BMW',
    model: 'X5 M',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'BMW',
    model: 'X6 M',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2012,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Buick',
    model: 'Verano',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 2012,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Cadillac',
    model: 'Escalade EXT',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Cadillac',
    model: 'SRX',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Avalanche',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Captiva Sport',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Colorado Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Cruze',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Sonic',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Chevrolet',
    model: 'Volt',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Chrysler',
    model: '200',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2012,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Dodge',
    model: 'Avenger',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Dodge',
    model: 'Caliber',
    body_styles: ['Wagon']
  },
  {
    year: 2012,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Dodge',
    model: 'Journey',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Ferrari',
    model: '458 Italia',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Ferrari',
    model: 'California',
    body_styles: ['Convertible']
  },
  {
    year: 2012,
    make: 'Ferrari',
    model: 'FF',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'FIAT',
    model: '500',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2012,
    make: 'Fisker',
    model: 'Karma',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'E150 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'Expedition EL',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'Fiesta',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'Flex',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'Transit Connect Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Ford',
    model: 'Transit Connect Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Canyon Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Sierra 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2012,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2012,
    make: 'Honda',
    model: 'Crosstour',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Honda',
    model: 'CR-Z',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Honda',
    model: 'Fit',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2012,
    make: 'Hyundai',
    model: 'Azera',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2012,
    make: 'Hyundai',
    model: 'Equus',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Hyundai',
    model: 'Genesis',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Hyundai',
    model: 'Genesis Coupe',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Hyundai',
    model: 'Veloster',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Hyundai',
    model: 'Veracruz',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'INFINITI',
    model: 'EX',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'INFINITI',
    model: 'FX',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 2012,
    make: 'INFINITI',
    model: 'M',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Jeep',
    model: 'Liberty',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Jeep',
    model: 'Patriot',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan', 'Hatchback', 'Coupe']
  },
  {
    year: 2012,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2012,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2012,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Lamborghini',
    model: 'Aventador',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Lamborghini',
    model: 'Gallardo',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Land Rover',
    model: 'LR2',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Land Rover',
    model: 'LR4',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Land Rover',
    model: 'Range Rover Evoque',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Lexus',
    model: 'CT',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Lexus',
    model: 'HS',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2012,
    make: 'Lexus',
    model: 'LFA',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Lincoln',
    model: 'MKS',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Lincoln',
    model: 'MKT',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Lincoln',
    model: 'MKX',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Lotus',
    model: 'Evora',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Maserati',
    model: 'GranTurismo',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Maybach',
    model: '57',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Maybach',
    model: '62',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'MAZDA',
    model: 'CX-7',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'MAZDA',
    model: 'MAZDA2',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2012,
    make: 'MAZDA',
    model: 'MAZDA5',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2012,
    make: 'McLaren',
    model: 'MP4-12C',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'CLS-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Coupe', 'Convertible', 'Wagon']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'GL-Class',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'GLK-Class',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'R-Class',
    body_styles: ['Wagon']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'SLS-Class',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2012,
    make: 'MINI',
    model: 'Countryman',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'MINI',
    model: 'Coupe',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'MINI',
    model: 'Hardtop',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'MINI',
    model: 'Roadster',
    body_styles: ['Convertible']
  },
  {
    year: 2012,
    make: 'Mitsubishi',
    model: 'Eclipse',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Mitsubishi',
    model: 'Galant',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Mitsubishi',
    model: 'i-MiEV',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2012,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Mitsubishi',
    model: 'Outlander Sport',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: '370Z',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'cube',
    body_styles: ['Wagon']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'GT-R',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'JUKE',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'LEAF',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'NV1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'NV2500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'NV3500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'NV3500 HD Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2012,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2012,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2012,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Porsche',
    model: 'Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Porsche',
    model: 'Panamera',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Ram',
    model: '1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ram',
    model: '1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ram',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ram',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ram',
    model: '2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ram',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ram',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ram',
    model: '3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ram',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Ram',
    model: 'C/V',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Rolls-Royce',
    model: 'Ghost',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Convertible', 'Coupe', 'Sedan']
  },
  {
    year: 2012,
    make: 'Scion',
    model: 'iQ',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Scion',
    model: 'tC',
    body_styles: ['Coupe']
  },
  {
    year: 2012,
    make: 'Scion',
    model: 'xB',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Scion',
    model: 'xD',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'smart',
    model: 'fortwo',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2012,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2012,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Wagon']
  },
  {
    year: 2012,
    make: 'Subaru',
    model: 'Tribeca',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Suzuki',
    model: 'Equator Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Suzuki',
    model: 'Equator Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Suzuki',
    model: 'Kizashi',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Suzuki',
    model: 'SX4',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2012,
    make: 'Tesla',
    model: 'Model S',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'FJ Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Matrix',
    body_styles: ['Wagon']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Prius c',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Prius Plug-in Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Prius v',
    body_styles: ['Wagon']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Venza',
    body_styles: ['Wagon']
  },
  {
    year: 2012,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2012,
    make: 'Volkswagen',
    model: 'Beetle',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Volkswagen',
    model: 'CC',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Volkswagen',
    model: 'Eos',
    body_styles: ['Convertible']
  },
  {
    year: 2012,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Volkswagen',
    model: 'Jetta SportWagen',
    body_styles: ['Wagon']
  },
  {
    year: 2012,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Volkswagen',
    model: 'Routan',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2012,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Volkswagen',
    model: 'Touareg',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Volvo',
    model: 'C30',
    body_styles: ['Hatchback']
  },
  {
    year: 2012,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Convertible']
  },
  {
    year: 2012,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2012,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2012,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2012,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Acura',
    model: 'ILX',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Acura',
    model: 'TSX',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2013,
    make: 'Acura',
    model: 'ZDX',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Aston Martin',
    model: 'DB9',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Wagon']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'A7',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'RS 5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'S7',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2013,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Sedan', 'Convertible', 'Coupe']
  },
  {
    year: 2013,
    make: 'Bentley',
    model: 'Mulsanne',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'BMW',
    model: '1 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 2013,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2013,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'BMW',
    model: 'M6',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'BMW',
    model: 'X1',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'BMW',
    model: 'X5 M',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'BMW',
    model: 'X6 M',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2013,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Buick',
    model: 'Encore',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Buick',
    model: 'Verano',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Cadillac',
    model: 'ATS',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 2013,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Cadillac',
    model: 'Escalade EXT',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Cadillac',
    model: 'SRX',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Cadillac',
    model: 'XTS',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Avalanche',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Captiva Sport',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Cruze',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Silverado 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Sonic',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Spark',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Suburban 2500',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Chevrolet',
    model: 'Volt',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Chrysler',
    model: '200',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2013,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Dodge',
    model: 'Avenger',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Dodge',
    model: 'Dart',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Dodge',
    model: 'Journey',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Ferrari',
    model: '458 Italia',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Ferrari',
    model: '458 Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2013,
    make: 'Ferrari',
    model: 'California',
    body_styles: ['Convertible']
  },
  {
    year: 2013,
    make: 'Ferrari',
    model: 'F12berlinetta',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Ferrari',
    model: 'FF',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'FIAT',
    model: '500',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2013,
    make: 'FIAT',
    model: '500e',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'C-MAX Energi',
    body_styles: ['Wagon']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'C-MAX Hybrid',
    body_styles: ['Wagon']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'E150 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Expedition EL',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Fiesta',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Flex',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Focus ST',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Fusion Energi',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Transit Connect Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Ford',
    model: 'Transit Connect Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Freightliner',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Freightliner',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Freightliner',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Freightliner',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Sierra 1500 Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Sierra 2500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Sierra 3500 HD Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'GMC',
    model: 'Yukon XL 2500',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2013,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2013,
    make: 'Honda',
    model: 'Crosstour',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Honda',
    model: 'CR-Z',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Honda',
    model: 'Fit',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2013,
    make: 'Hyundai',
    model: 'Azera',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan', 'Coupe', 'Hatchback']
  },
  {
    year: 2013,
    make: 'Hyundai',
    model: 'Equus',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Hyundai',
    model: 'Genesis',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Hyundai',
    model: 'Genesis Coupe',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Hyundai',
    model: 'Santa Fe Sport',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Hyundai',
    model: 'Veloster',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'INFINITI',
    model: 'EX',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'INFINITI',
    model: 'FX',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'INFINITI',
    model: 'G',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'INFINITI',
    model: 'JX',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'INFINITI',
    model: 'M',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'INFINITI',
    model: 'QX',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Jeep',
    model: 'Patriot',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan', 'Hatchback', 'Coupe']
  },
  {
    year: 2013,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2013,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2013,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Lamborghini',
    model: 'Aventador',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Lamborghini',
    model: 'Gallardo',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Land Rover',
    model: 'LR2',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Land Rover',
    model: 'LR4',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Land Rover',
    model: 'Range Rover Evoque',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Lexus',
    model: 'CT',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2013,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Lincoln',
    model: 'MKS',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Lincoln',
    model: 'MKT',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Lincoln',
    model: 'MKX',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Lotus',
    model: 'Evora',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Maserati',
    model: 'GranTurismo',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'MAZDA',
    model: 'CX-5',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'MAZDA',
    model: 'MAZDA2',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2013,
    make: 'MAZDA',
    model: 'MAZDA5',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2013,
    make: 'McLaren',
    model: 'MP4-12C',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'CLS-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Coupe', 'Wagon', 'Convertible']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'GL-Class',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'GLK-Class',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'SLS-Class',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2013,
    make: 'MINI',
    model: 'Countryman',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'MINI',
    model: 'Coupe',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'MINI',
    model: 'Hardtop',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'MINI',
    model: 'Paceman',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'MINI',
    model: 'Roadster',
    body_styles: ['Convertible']
  },
  {
    year: 2013,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2013,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Mitsubishi',
    model: 'Outlander Sport',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: '370Z',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'cube',
    body_styles: ['Wagon']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'GT-R',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'JUKE',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'LEAF',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'NV1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'NV200',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'NV2500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'NV3500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'NV3500 HD Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2013,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Porsche',
    model: 'Panamera',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Ram',
    model: '1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ram',
    model: '1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ram',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ram',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ram',
    model: '2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ram',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ram',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ram',
    model: '3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ram',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Ram',
    model: 'C/V Tradesman',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Rolls-Royce',
    model: 'Ghost',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2013,
    make: 'Scion',
    model: 'FR-S',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Scion',
    model: 'iQ',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Scion',
    model: 'tC',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Scion',
    model: 'xB',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Scion',
    model: 'xD',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'smart',
    model: 'fortwo',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2013,
    make: 'smart',
    model: 'fortwo electric drive',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2013,
    make: 'SRT',
    model: 'Viper',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Subaru',
    model: 'BRZ',
    body_styles: ['Coupe']
  },
  {
    year: 2013,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2013,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Wagon']
  },
  {
    year: 2013,
    make: 'Subaru',
    model: 'Tribeca',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Subaru',
    model: 'XV Crosstrek',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Suzuki',
    model: 'Grand Vitara',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Suzuki',
    model: 'Kizashi',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Suzuki',
    model: 'SX4',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2013,
    make: 'Tesla',
    model: 'Model S',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'FJ Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Matrix',
    body_styles: ['Wagon']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Prius c',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Prius Plug-in Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Prius v',
    body_styles: ['Wagon']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Venza',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Volkswagen',
    model: 'Beetle',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2013,
    make: 'Volkswagen',
    model: 'CC',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Volkswagen',
    model: 'Eos',
    body_styles: ['Convertible']
  },
  {
    year: 2013,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Volkswagen',
    model: 'Jetta SportWagen',
    body_styles: ['Wagon']
  },
  {
    year: 2013,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Volkswagen',
    model: 'Routan',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2013,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Volkswagen',
    model: 'Touareg',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Volvo',
    model: 'C30',
    body_styles: ['Hatchback']
  },
  {
    year: 2013,
    make: 'Volvo',
    model: 'C70',
    body_styles: ['Convertible']
  },
  {
    year: 2013,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2013,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2013,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2013,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Acura',
    model: 'ILX',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Acura',
    model: 'RLX',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Acura',
    model: 'RLX Sport Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Acura',
    model: 'TL',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Acura',
    model: 'TSX',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2014,
    make: 'Aston Martin',
    model: 'DB9',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Aston Martin',
    model: 'Rapide S',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Aston Martin',
    model: 'Vanquish',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'A7',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'R8',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'RS 5',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'RS 7',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'S7',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'SQ5',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Bentley',
    model: 'Flying Spur',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Bentley',
    model: 'Mulsanne',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'BMW',
    model: '2 Series',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2014,
    make: 'BMW',
    model: '4 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'BMW',
    model: 'i3',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'BMW',
    model: 'i8',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'BMW',
    model: 'M6',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'BMW',
    model: 'X1',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'BMW',
    model: 'X6 M',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2014,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Buick',
    model: 'Encore',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Buick',
    model: 'Verano',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Cadillac',
    model: 'ATS',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 2014,
    make: 'Cadillac',
    model: 'ELR',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Cadillac',
    model: 'SRX',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Cadillac',
    model: 'XTS',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Captiva Sport',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Cruze',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Express 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Express 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Impala Limited',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Silverado 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Sonic',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Spark',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Spark EV',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'SS',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Suburban 1500',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Chevrolet',
    model: 'Volt',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Chrysler',
    model: '200',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2014,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Dodge',
    model: 'Avenger',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Dodge',
    model: 'Dart',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Dodge',
    model: 'Journey',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Ferrari',
    model: '458 Italia',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Ferrari',
    model: '458 Speciale',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Ferrari',
    model: '458 Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2014,
    make: 'Ferrari',
    model: 'California',
    body_styles: ['Convertible']
  },
  {
    year: 2014,
    make: 'Ferrari',
    model: 'F12berlinetta',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Ferrari',
    model: 'FF',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'FIAT',
    model: '500',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'FIAT',
    model: '500 Abarth',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2014,
    make: 'FIAT',
    model: '500c',
    body_styles: ['Convertible']
  },
  {
    year: 2014,
    make: 'FIAT',
    model: '500e',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'FIAT',
    model: '500L',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'C-MAX Energi',
    body_styles: ['Wagon']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'C-MAX Hybrid',
    body_styles: ['Wagon']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'E150 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'E150 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'E250 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'E350 Super Duty Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'E350 Super Duty Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Expedition EL',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Fiesta',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Flex',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Focus ST',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Fusion Energi',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Transit Connect Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Ford',
    model: 'Transit Connect Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Freightliner',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Freightliner',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Freightliner',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Freightliner',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Savana 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Savana 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Sierra 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'GMC',
    model: 'Yukon XL 1500',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2014,
    make: 'Honda',
    model: 'Accord Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2014,
    make: 'Honda',
    model: 'Crosstour',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Honda',
    model: 'CR-Z',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2014,
    make: 'Hyundai',
    model: 'Azera',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan', 'Hatchback', 'Coupe']
  },
  {
    year: 2014,
    make: 'Hyundai',
    model: 'Equus',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Hyundai',
    model: 'Genesis',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Hyundai',
    model: 'Genesis Coupe',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Hyundai',
    model: 'Santa Fe Sport',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Hyundai',
    model: 'Veloster',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'INFINITI',
    model: 'Q50',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'INFINITI',
    model: 'Q60',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'INFINITI',
    model: 'Q70',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'INFINITI',
    model: 'QX50',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'INFINITI',
    model: 'QX60',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'INFINITI',
    model: 'QX70',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'INFINITI',
    model: 'QX80',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Jaguar',
    model: 'F-TYPE',
    body_styles: ['Convertible']
  },
  {
    year: 2014,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Jeep',
    model: 'Patriot',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Kia',
    model: 'Cadenza',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan', 'Coupe', 'Hatchback']
  },
  {
    year: 2014,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2014,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2014,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Lamborghini',
    model: 'Aventador',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Lamborghini',
    model: 'Gallardo',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Land Rover',
    model: 'LR2',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Land Rover',
    model: 'LR4',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Land Rover',
    model: 'Range Rover Evoque',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Lexus',
    model: 'CT',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2014,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Lincoln',
    model: 'MKS',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Lincoln',
    model: 'MKT',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Lincoln',
    model: 'MKX',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Lotus',
    model: 'Evora',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Maserati',
    model: 'Ghibli',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Maserati',
    model: 'GranTurismo',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2014,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'MAZDA',
    model: 'CX-5',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'MAZDA',
    model: 'MAZDA2',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2014,
    make: 'MAZDA',
    model: 'MAZDA5',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2014,
    make: 'McLaren',
    model: 'MP4-12C',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'B-Class',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'CLA-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'CL-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'CLS-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon', 'Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'GL-Class',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'GLK-Class',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'SLS-Class',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2014,
    make: 'MINI',
    model: 'Countryman',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'MINI',
    model: 'Coupe',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'MINI',
    model: 'Hardtop',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'MINI',
    model: 'Paceman',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'MINI',
    model: 'Roadster',
    body_styles: ['Convertible']
  },
  {
    year: 2014,
    make: 'Mitsubishi',
    model: 'i-MiEV',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2014,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Mitsubishi',
    model: 'Outlander Sport',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: '370Z',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'cube',
    body_styles: ['Wagon']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'GT-R',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'JUKE',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'LEAF',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'NV1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'NV200',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'NV2500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'NV3500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'NV3500 HD Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Rogue Select',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2014,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2014,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Porsche',
    model: 'Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Porsche',
    model: 'Panamera',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Ram',
    model: '1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ram',
    model: '1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ram',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ram',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ram',
    model: '2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ram',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ram',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ram',
    model: '3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ram',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Ram',
    model: 'C/V Tradesman',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Ram',
    model: 'ProMaster 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Ram',
    model: 'ProMaster 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Ram',
    model: 'ProMaster 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Rolls-Royce',
    model: 'Ghost',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2014,
    make: 'Rolls-Royce',
    model: 'Wraith',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Scion',
    model: 'FR-S',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Scion',
    model: 'iQ',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Scion',
    model: 'tC',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Scion',
    model: 'xB',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Scion',
    model: 'xD',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'smart',
    model: 'fortwo',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2014,
    make: 'smart',
    model: 'fortwo electric drive',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2014,
    make: 'SRT',
    model: 'Viper',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Subaru',
    model: 'BRZ',
    body_styles: ['Coupe']
  },
  {
    year: 2014,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2014,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['Wagon']
  },
  {
    year: 2014,
    make: 'Subaru',
    model: 'Tribeca',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Subaru',
    model: 'XV Crosstrek',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Tesla',
    model: 'Model S',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'FJ Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Prius c',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Prius Plug-in Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Prius v',
    body_styles: ['Wagon']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Tacoma Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Venza',
    body_styles: ['Wagon']
  },
  {
    year: 2014,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Volkswagen',
    model: 'Beetle',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2014,
    make: 'Volkswagen',
    model: 'CC',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Volkswagen',
    model: 'Eos',
    body_styles: ['Convertible']
  },
  {
    year: 2014,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Volkswagen',
    model: 'GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2014,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Volkswagen',
    model: 'Jetta SportWagen',
    body_styles: ['Wagon']
  },
  {
    year: 2014,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Volkswagen',
    model: 'Routan',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2014,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Volkswagen',
    model: 'Touareg',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2014,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2014,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2014,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Acura',
    model: 'ILX',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Acura',
    model: 'RLX',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Acura',
    model: 'TLX',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Alfa Romeo',
    model: '4C',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Alfa Romeo',
    model: '4C Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2015,
    make: 'Aston Martin',
    model: 'DB9',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Aston Martin',
    model: 'Rapide S',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Aston Martin',
    model: 'Vanquish',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'A7',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'Q3',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'R8',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'RS 5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'RS 7',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'S3',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'S7',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'SQ5',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Bentley',
    model: 'Flying Spur',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Bentley',
    model: 'Mulsanne',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'BMW',
    model: '2 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2015,
    make: 'BMW',
    model: '4 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2015,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'i3',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'i8',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'M4',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'M6',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'X1',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'X4',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'X5 M',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'X6 M',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2015,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Buick',
    model: 'Encore',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Buick',
    model: 'Verano',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Cadillac',
    model: 'ATS',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2015,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2015,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Cadillac',
    model: 'SRX',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Cadillac',
    model: 'XTS',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Captiva Sport',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'City Express',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Cruze',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Impala Limited',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Silverado 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Sonic',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Spark',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Spark EV',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'SS',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Suburban',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Trax',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Chevrolet',
    model: 'Volt',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Chrysler',
    model: '200',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Dodge',
    model: 'Dart',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Dodge',
    model: 'Journey',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Ferrari',
    model: '458 Italia',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Ferrari',
    model: '458 Speciale',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Ferrari',
    model: '458 Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2015,
    make: 'Ferrari',
    model: 'California',
    body_styles: ['Convertible']
  },
  {
    year: 2015,
    make: 'Ferrari',
    model: 'F12berlinetta',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Ferrari',
    model: 'FF',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'FIAT',
    model: '500',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'FIAT',
    model: '500 Abarth',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2015,
    make: 'FIAT',
    model: '500c',
    body_styles: ['Convertible']
  },
  {
    year: 2015,
    make: 'FIAT',
    model: '500e',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'FIAT',
    model: '500L',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'C-MAX Energi',
    body_styles: ['Wagon']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'C-MAX Hybrid',
    body_styles: ['Wagon']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Expedition EL',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Fiesta',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Flex',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Focus ST',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Fusion Energi',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Transit 150 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Transit 150 Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Transit 250 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Transit 350 HD Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Transit 350 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Transit 350 Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Transit Connect Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Ford',
    model: 'Transit Connect Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Freightliner',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Freightliner',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Freightliner',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Freightliner',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Sierra 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Sierra 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Sierra 3500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'GMC',
    model: 'Yukon XL',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2015,
    make: 'Honda',
    model: 'Accord Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2015,
    make: 'Honda',
    model: 'Crosstour',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Honda',
    model: 'CR-Z',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Honda',
    model: 'Fit',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2015,
    make: 'Hyundai',
    model: 'Azera',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Hyundai',
    model: 'Elantra GT',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Hyundai',
    model: 'Equus',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Hyundai',
    model: 'Genesis',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Hyundai',
    model: 'Genesis Coupe',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Hyundai',
    model: 'Santa Fe Sport',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Hyundai',
    model: 'Tucson Fuel Cell',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Hyundai',
    model: 'Veloster',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'INFINITI',
    model: 'Q40',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'INFINITI',
    model: 'Q50',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'INFINITI',
    model: 'Q60',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'INFINITI',
    model: 'Q70',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'INFINITI',
    model: 'QX50',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'INFINITI',
    model: 'QX60',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'INFINITI',
    model: 'QX70',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'INFINITI',
    model: 'QX80',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Jaguar',
    model: 'F-TYPE',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Jaguar',
    model: 'XK',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Jeep',
    model: 'Patriot',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Jeep',
    model: 'Renegade',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Kia',
    model: 'Cadenza',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Kia',
    model: 'Forte Koup',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Kia',
    model: 'Forte5',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Kia',
    model: 'K900',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Kia',
    model: 'Optima Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2015,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2015,
    make: 'Kia',
    model: 'Soul EV',
    body_styles: ['Wagon']
  },
  {
    year: 2015,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Lamborghini',
    model: 'Aventador',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Lamborghini',
    model: 'Huracan',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Land Rover',
    model: 'Discovery Sport',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Land Rover',
    model: 'LR2',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Land Rover',
    model: 'LR4',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Land Rover',
    model: 'Range Rover Evoque',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Lexus',
    model: 'CT',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2015,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Lexus',
    model: 'NX',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Lexus',
    model: 'RC',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Lincoln',
    model: 'MKC',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Lincoln',
    model: 'MKS',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Lincoln',
    model: 'MKT',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Lincoln',
    model: 'MKX',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Maserati',
    model: 'Ghibli',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Maserati',
    model: 'GranTurismo',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'MAZDA',
    model: 'CX-5',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2015,
    make: 'MAZDA',
    model: 'MAZDA5',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2015,
    make: 'McLaren',
    model: '650S',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'B-Class',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'CLA-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'CLS-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Convertible', 'Wagon', 'Coupe', 'Sedan']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'GLA-Class',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'GL-Class',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'GLK-Class',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'M-Class',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'SL-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'SLK-Class',
    body_styles: ['Convertible']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'SLS-Class',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2015,
    make: 'MINI',
    model: 'Countryman',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'MINI',
    model: 'Coupe',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'MINI',
    model: 'Hardtop 2 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'MINI',
    model: 'Hardtop 4 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'MINI',
    model: 'Paceman',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'MINI',
    model: 'Roadster',
    body_styles: ['Convertible']
  },
  {
    year: 2015,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Mitsubishi',
    model: 'Lancer Evolution',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Mitsubishi',
    model: 'Outlander Sport',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: '370Z',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'GT-R',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'JUKE',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'LEAF',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'NV1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'NV200',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'NV200 Taxi',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'NV2500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'NV3500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'NV3500 HD Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Rogue Select',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2015,
    make: 'Nissan',
    model: 'Xterra',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2015,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2015,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Porsche',
    model: 'Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Porsche',
    model: 'Macan',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Porsche',
    model: 'Panamera',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Ram',
    model: '1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ram',
    model: '1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ram',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ram',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ram',
    model: '2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ram',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ram',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ram',
    model: '3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ram',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Ram',
    model: 'C/V Tradesman',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Ram',
    model: 'ProMaster Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Ram',
    model: 'ProMaster City',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Ram',
    model: 'ProMaster Window Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Rolls-Royce',
    model: 'Ghost',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 2015,
    make: 'Rolls-Royce',
    model: 'Wraith',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Scion',
    model: 'FR-S',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Scion',
    model: 'iQ',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Scion',
    model: 'tC',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Scion',
    model: 'xB',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'smart',
    model: 'fortwo',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2015,
    make: 'smart',
    model: 'fortwo electric drive',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2015,
    make: 'Subaru',
    model: 'BRZ',
    body_styles: ['Coupe']
  },
  {
    year: 2015,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2015,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Subaru',
    model: 'WRX',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Subaru',
    model: 'XV Crosstrek',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Tesla',
    model: 'Model S',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Prius c',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Prius Plug-in Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Prius v',
    body_styles: ['Wagon']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Venza',
    body_styles: ['Wagon']
  },
  {
    year: 2015,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Volkswagen',
    model: 'Beetle',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2015,
    make: 'Volkswagen',
    model: 'CC',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Volkswagen',
    model: 'e-Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Volkswagen',
    model: 'Eos',
    body_styles: ['Convertible']
  },
  {
    year: 2015,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Volkswagen',
    model: 'Golf GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Volkswagen',
    model: 'Golf R',
    body_styles: ['Hatchback']
  },
  {
    year: 2015,
    make: 'Volkswagen',
    model: 'Golf SportWagen',
    body_styles: ['Wagon']
  },
  {
    year: 2015,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Volkswagen',
    model: 'Touareg',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2015,
    make: 'Volvo',
    model: 'V60',
    body_styles: ['Wagon']
  },
  {
    year: 2015,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2015,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2016,
    make: 'Acura',
    model: 'ILX',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Acura',
    model: 'RLX',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Acura',
    model: 'RLX Sport Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Acura',
    model: 'TLX',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Alfa Romeo',
    model: '4C',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Alfa Romeo',
    model: '4C Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'Aston Martin',
    model: 'DB9 GT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Aston Martin',
    model: 'Rapide S',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Aston Martin',
    model: 'Vanquish',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'A3 Sportback e-tron',
    body_styles: ['Wagon']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'A7',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'Q3',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'RS 7',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'S3',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'S7',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'SQ5',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2016,
    make: 'Bentley',
    model: 'Flying Spur',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Bentley',
    model: 'Mulsanne',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'BMW',
    model: '2 Series',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2016,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2016,
    make: 'BMW',
    model: '4 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2016,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'i3',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'i8',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'M2',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'M4',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'M6',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'X1',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'X4',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'X5 M',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'X6 M',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'Buick',
    model: 'Cascada',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Buick',
    model: 'Encore',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Buick',
    model: 'Envision',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Buick',
    model: 'Verano',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Cadillac',
    model: 'ATS',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2016,
    make: 'Cadillac',
    model: 'ATS-V',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2016,
    make: 'Cadillac',
    model: 'CT6',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Cadillac',
    model: 'CTS-V',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Cadillac',
    model: 'ELR',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Cadillac',
    model: 'SRX',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Cadillac',
    model: 'XTS',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'City Express',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Cruze',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Cruze Limited',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Impala Limited',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Malibu Limited',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Silverado 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Sonic',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Spark',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Spark EV',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'SS',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Suburban',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Suburban 3500HD',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Trax',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Chevrolet',
    model: 'Volt',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Chrysler',
    model: '200',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Chrysler',
    model: 'Town & Country',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Dodge',
    model: 'Dart',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Dodge',
    model: 'Journey',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Ferrari',
    model: '488 GTB',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Ferrari',
    model: '488 Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'Ferrari',
    model: 'California',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'Ferrari',
    model: 'F12berlinetta',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Ferrari',
    model: 'FF',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'FIAT',
    model: '500',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'FIAT',
    model: '500 Abarth',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2016,
    make: 'FIAT',
    model: '500c',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'FIAT',
    model: '500e',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'FIAT',
    model: '500L',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'FIAT',
    model: '500X',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'C-MAX Energi',
    body_styles: ['Wagon']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'C-MAX Hybrid',
    body_styles: ['Wagon']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Expedition EL',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Fiesta',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Flex',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Fusion Energi',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Transit 150 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Transit 150 Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Transit 250 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Transit 350 HD Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Transit 350 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Transit 350 Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Transit Connect Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Ford',
    model: 'Transit Connect Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Freightliner',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Freightliner',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Freightliner',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Freightliner',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Sierra 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Sierra 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Sierra 3500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'GMC',
    model: 'Yukon XL',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2016,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2016,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Honda',
    model: 'CR-Z',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Honda',
    model: 'Fit',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Honda',
    model: 'HR-V',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Azera',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Elantra GT',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Equus',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Genesis',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Genesis Coupe',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Santa Fe Sport',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Sonata Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Sonata Plug-in Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Tucson Fuel Cell',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Hyundai',
    model: 'Veloster',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'INFINITI',
    model: 'Q50',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'INFINITI',
    model: 'Q70',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'INFINITI',
    model: 'QX50',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'INFINITI',
    model: 'QX60',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'INFINITI',
    model: 'QX70',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'INFINITI',
    model: 'QX80',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Jaguar',
    model: 'F-TYPE',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Jeep',
    model: 'Patriot',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Jeep',
    model: 'Renegade',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Kia',
    model: 'Cadenza',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Kia',
    model: 'Forte Koup',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Kia',
    model: 'Forte5',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Kia',
    model: 'K900',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Kia',
    model: 'Optima Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2016,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2016,
    make: 'Kia',
    model: 'Soul EV',
    body_styles: ['Wagon']
  },
  {
    year: 2016,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Lamborghini',
    model: 'Aventador',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2016,
    make: 'Lamborghini',
    model: 'Huracan',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Land Rover',
    model: 'Discovery Sport',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Land Rover',
    model: 'LR4',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Land Rover',
    model: 'Range Rover Evoque',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Lexus',
    model: 'CT',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Lexus',
    model: 'NX',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Lexus',
    model: 'RC',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Lincoln',
    model: 'MKC',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Lincoln',
    model: 'MKS',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Lincoln',
    model: 'MKT',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Lincoln',
    model: 'MKX',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Maserati',
    model: 'Ghibli',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Maserati',
    model: 'GranTurismo',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'MAZDA',
    model: 'CX-3',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'MAZDA',
    model: 'CX-5',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2016,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'McLaren',
    model: '570S',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'McLaren',
    model: '650S',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2016,
    make: 'McLaren',
    model: '675LT',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'B-Class',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'CLA',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'CLS-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Coupe', 'Wagon', 'Convertible']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'GLA',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'GLC',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'GL-Class',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'GLE',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'GLE Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG CLA',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLA',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLE',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLE Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GT',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG SL',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG SLK',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Mercedes-Maybach S 600',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Metris Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Metris Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'SL',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'SLK',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'MINI',
    model: 'Countryman',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'MINI',
    model: 'Hardtop 2 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'MINI',
    model: 'Hardtop 4 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'MINI',
    model: 'Paceman',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Mitsubishi',
    model: 'i-MiEV',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Mitsubishi',
    model: 'Outlander Sport',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: '370Z',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'GT-R',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'JUKE',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'LEAF',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'NV1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'NV200',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'NV200 Taxi',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'NV2500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'NV3500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'NV3500 HD Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'TITAN XD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Nissan',
    model: 'Versa Note',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2016,
    make: 'Porsche',
    model: 'Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Porsche',
    model: 'Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Porsche',
    model: 'Macan',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Porsche',
    model: 'Panamera',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Ram',
    model: '1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ram',
    model: '1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ram',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ram',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ram',
    model: '2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ram',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ram',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ram',
    model: '3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ram',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Ram',
    model: 'ProMaster Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Ram',
    model: 'ProMaster City',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Ram',
    model: 'ProMaster Window Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Rolls-Royce',
    model: 'Dawn',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'Rolls-Royce',
    model: 'Ghost',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Sedan', 'Convertible', 'Coupe']
  },
  {
    year: 2016,
    make: 'Rolls-Royce',
    model: 'Wraith',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Scion',
    model: 'FR-S',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Scion',
    model: 'iA',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Scion',
    model: 'iM',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Scion',
    model: 'tC',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'smart',
    model: 'fortwo',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'smart',
    model: 'fortwo electric drive',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Subaru',
    model: 'BRZ',
    body_styles: ['Coupe']
  },
  {
    year: 2016,
    make: 'Subaru',
    model: 'Crosstrek',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2016,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Subaru',
    model: 'WRX',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Tesla',
    model: 'Model S',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Tesla',
    model: 'Model X',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Mirai',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Prius c',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Prius v',
    body_styles: ['Wagon']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2016,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Volkswagen',
    model: 'Beetle',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2016,
    make: 'Volkswagen',
    model: 'CC',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Volkswagen',
    model: 'e-Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Volkswagen',
    model: 'Eos',
    body_styles: ['Convertible']
  },
  {
    year: 2016,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Volkswagen',
    model: 'Golf GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Volkswagen',
    model: 'Golf R',
    body_styles: ['Hatchback']
  },
  {
    year: 2016,
    make: 'Volkswagen',
    model: 'Golf SportWagen',
    body_styles: ['Wagon']
  },
  {
    year: 2016,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Volkswagen',
    model: 'Touareg',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Volvo',
    model: 'S80',
    body_styles: ['Sedan']
  },
  {
    year: 2016,
    make: 'Volvo',
    model: 'V60',
    body_styles: ['Wagon']
  },
  {
    year: 2016,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2016,
    make: 'Volvo',
    model: 'XC70',
    body_styles: ['Wagon']
  },
  {
    year: 2016,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Acura',
    model: 'ILX',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Acura',
    model: 'MDX Sport Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Acura',
    model: 'RLX',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Acura',
    model: 'RLX Sport Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Acura',
    model: 'TLX',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Alfa Romeo',
    model: '4C',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Alfa Romeo',
    model: 'Giulia',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Aston Martin',
    model: 'DB11',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Aston Martin',
    model: 'Rapide S',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Aston Martin',
    model: 'Vanquish',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'A3 Sportback e-tron',
    body_styles: ['Wagon']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'A4 allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'A5 Sport',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'A7',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'Q3',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'R8',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'RS 3',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'RS 7',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'S3',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'S7',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'SQ5',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'Bentley',
    model: 'Bentayga',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2017,
    make: 'Bentley',
    model: 'Flying Spur',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Bentley',
    model: 'Mulsanne',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'BMW',
    model: '2 Series',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2017,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2017,
    make: 'BMW',
    model: '4 Series',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2017,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'BMW',
    model: 'i3',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'BMW',
    model: 'i8',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'BMW',
    model: 'M2',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'BMW',
    model: 'M4',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2017,
    make: 'BMW',
    model: 'M6',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'BMW',
    model: 'X1',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'BMW',
    model: 'X4',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'BMW',
    model: 'X5 M',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'BMW',
    model: 'X6 M',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Buick',
    model: 'Cascada',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Buick',
    model: 'Encore',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Buick',
    model: 'Envision',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Buick',
    model: 'Regal',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Buick',
    model: 'Verano',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Cadillac',
    model: 'ATS',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2017,
    make: 'Cadillac',
    model: 'ATS-V',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2017,
    make: 'Cadillac',
    model: 'CT6',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Cadillac',
    model: 'CTS-V',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Cadillac',
    model: 'XT5',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Cadillac',
    model: 'XTS',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Bolt EV',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'City Express',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Cruze',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Silverado 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Sonic',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Spark',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'SS',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Suburban',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Suburban 3500HD',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Trax',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Chevrolet',
    model: 'Volt',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Chrysler',
    model: '200',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Chrysler',
    model: 'Pacifica',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Chrysler',
    model: 'Pacifica Hybrid',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Dodge',
    model: 'Journey',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Dodge',
    model: 'Viper',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Ferrari',
    model: '488 GTB',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Ferrari',
    model: '488 Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'Ferrari',
    model: 'California',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'Ferrari',
    model: 'F12berlinetta',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Ferrari',
    model: 'GTC4Lusso',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'FIAT',
    model: '124 Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'FIAT',
    model: '500',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'FIAT',
    model: '500 Abarth',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'FIAT',
    model: '500c',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'FIAT',
    model: '500c Abarth',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'FIAT',
    model: '500e',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'FIAT',
    model: '500L',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'FIAT',
    model: '500X',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'C-MAX Energi',
    body_styles: ['Wagon']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'C-MAX Hybrid',
    body_styles: ['Wagon']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Expedition EL',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Fiesta',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Flex',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Fusion Energi',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Transit 150 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Transit 150 Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Transit 250 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Transit 350 HD Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Transit 350 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Transit 350 Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Transit Connect Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Ford',
    model: 'Transit Connect Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Freightliner',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Freightliner',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Freightliner',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Freightliner',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Freightliner',
    model: 'Sprinter 3500XD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Freightliner',
    model: 'Sprinter WORKER Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Freightliner',
    model: 'Sprinter WORKER Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Genesis',
    model: 'G80',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Genesis',
    model: 'G90',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Acadia Limited',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Sierra 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Sierra 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Sierra 3500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'GMC',
    model: 'Yukon XL',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2017,
    make: 'Honda',
    model: 'Accord Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Sedan', 'Hatchback']
  },
  {
    year: 2017,
    make: 'Honda',
    model: 'Civic Type R',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Honda',
    model: 'Fit',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Honda',
    model: 'HR-V',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2017,
    make: 'Hyundai',
    model: 'Azera',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Hyundai',
    model: 'Elantra GT',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Hyundai',
    model: 'Ioniq Electric',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Hyundai',
    model: 'Ioniq Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Hyundai',
    model: 'Santa Fe Sport',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Hyundai',
    model: 'Sonata Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Hyundai',
    model: 'Sonata Plug-in Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Hyundai',
    model: 'Veloster',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'INFINITI',
    model: 'Q50',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'INFINITI',
    model: 'Q60',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'INFINITI',
    model: 'Q70',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'INFINITI',
    model: 'QX30',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'INFINITI',
    model: 'QX50',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'INFINITI',
    model: 'QX60',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'INFINITI',
    model: 'QX70',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'INFINITI',
    model: 'QX80',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Jaguar',
    model: 'F-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Jaguar',
    model: 'F-TYPE',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'Jaguar',
    model: 'XE',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Jeep',
    model: 'Patriot',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Jeep',
    model: 'Renegade',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Jeep',
    model: 'Wrangler Unlimited',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'Cadenza',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'Forte5',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'K900',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'Niro',
    body_styles: ['Wagon']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'Optima Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'Optima Plug-in Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'Soul EV',
    body_styles: ['Wagon']
  },
  {
    year: 2017,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Lamborghini',
    model: 'Aventador',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'Lamborghini',
    model: 'Huracan',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Land Rover',
    model: 'Discovery Sport',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Land Rover',
    model: 'Range Rover Evoque',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Lexus',
    model: 'CT',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Lexus',
    model: 'NX',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Lexus',
    model: 'RC',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Lincoln',
    model: 'MKC',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Lincoln',
    model: 'MKT',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Lincoln',
    model: 'MKX',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Lotus',
    model: 'Evora 400',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Maserati',
    model: 'Ghibli',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Maserati',
    model: 'GranTurismo',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'Maserati',
    model: 'Levante',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'MAZDA',
    model: 'CX-3',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'MAZDA',
    model: 'CX-5',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2017,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'MAZDA',
    model: 'MX-5 Miata RF',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'McLaren',
    model: '570GT',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'McLaren',
    model: '570S',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'B-Class',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'CLA',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'CLS',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Convertible', 'Sedan', 'Coupe', 'Wagon']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'GLA',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'GLC',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'GLC Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'GLE',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'GLS',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG C-Class',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG CLA',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG CLS',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG E-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLA',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLC',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLC Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLE',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLE Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLS',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GT',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG S-Class',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG SL',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG SLC',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Mercedes-Maybach S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Metris Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Metris Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Metris WORKER Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Metris WORKER Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan', 'Convertible', 'Coupe']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'SL',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'SLC',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 XD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Sprinter WORKER Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Mercedes-Benz',
    model: 'Sprinter WORKER Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'MINI',
    model: 'Countryman',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'MINI',
    model: 'Hardtop 2 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'MINI',
    model: 'Hardtop 4 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Mitsubishi',
    model: 'i-MiEV',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Mitsubishi',
    model: 'Lancer',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Mitsubishi',
    model: 'Mirage G4',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Mitsubishi',
    model: 'Outlander Sport',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: '370Z',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'GT-R',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'JUKE',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'LEAF',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'NV1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'NV200',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'NV2500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'NV3500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'NV3500 HD Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Quest',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Rogue Sport',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'TITAN Single Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'TITAN XD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'TITAN XD King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'TITAN XD Single Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Nissan',
    model: 'Versa Note',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Porsche',
    model: '718 Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'Porsche',
    model: '718 Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2017,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Porsche',
    model: 'Macan',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Porsche',
    model: 'Panamera',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Ram',
    model: '1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ram',
    model: '1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ram',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ram',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ram',
    model: '2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ram',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ram',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ram',
    model: '3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ram',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Ram',
    model: 'ProMaster Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Ram',
    model: 'ProMaster City',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Ram',
    model: 'ProMaster Window Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Rolls-Royce',
    model: 'Dawn',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'Rolls-Royce',
    model: 'Ghost',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Rolls-Royce',
    model: 'Wraith',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'smart',
    model: 'fortwo',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'smart',
    model: 'fortwo cabrio',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'smart',
    model: 'fortwo electric drive',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'smart',
    model: 'fortwo electric drive cabrio',
    body_styles: ['Convertible']
  },
  {
    year: 2017,
    make: 'Subaru',
    model: 'BRZ',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Subaru',
    model: 'Crosstrek',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2017,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Subaru',
    model: 'WRX',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Tesla',
    model: 'Model 3',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Tesla',
    model: 'Model S',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Tesla',
    model: 'Model X',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: '86',
    body_styles: ['Coupe']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Avalon Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Camry Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Corolla iM',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Highlander Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Mirai',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Prius c',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Prius Prime',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Prius v',
    body_styles: ['Wagon']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'RAV4 Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Tundra Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Toyota',
    model: 'Yaris iA',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Volkswagen',
    model: 'Beetle',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2017,
    make: 'Volkswagen',
    model: 'CC',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Volkswagen',
    model: 'e-Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Volkswagen',
    model: 'Golf Alltrack',
    body_styles: ['Wagon']
  },
  {
    year: 2017,
    make: 'Volkswagen',
    model: 'Golf GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Volkswagen',
    model: 'Golf R',
    body_styles: ['Hatchback']
  },
  {
    year: 2017,
    make: 'Volkswagen',
    model: 'Golf SportWagen',
    body_styles: ['Wagon']
  },
  {
    year: 2017,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Volkswagen',
    model: 'Tiguan Limited',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Volkswagen',
    model: 'Touareg',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Volvo',
    model: 'S90',
    body_styles: ['Sedan']
  },
  {
    year: 2017,
    make: 'Volvo',
    model: 'V60',
    body_styles: ['Wagon']
  },
  {
    year: 2017,
    make: 'Volvo',
    model: 'V90',
    body_styles: ['Wagon']
  },
  {
    year: 2017,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2017,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Acura',
    model: 'ILX',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Acura',
    model: 'MDX Sport Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Acura',
    model: 'RLX',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Acura',
    model: 'RLX Sport Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Acura',
    model: 'TLX',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Alfa Romeo',
    model: '4C',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Alfa Romeo',
    model: '4C Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'Alfa Romeo',
    model: 'Giulia',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Alfa Romeo',
    model: 'Stelvio',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Aston Martin',
    model: 'DB11',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Aston Martin',
    model: 'Vanquish S',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'A3 Sportback e-tron',
    body_styles: ['Wagon']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'A4 allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'A7',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'Q3',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'R8',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'RS 3',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'RS 5',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'RS 7',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'S3',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'S7',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'SQ5',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'Bentley',
    model: 'Bentayga',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Bentley',
    model: 'Continental',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'Bentley',
    model: 'Flying Spur',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Bentley',
    model: 'Mulsanne',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'BMW',
    model: '2 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2018,
    make: 'BMW',
    model: '4 Series',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2018,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Sedan', 'Convertible']
  },
  {
    year: 2018,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'i3',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'M2',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'M4',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'M6',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'X1',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'X2',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'X4',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'X5 M',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'BMW',
    model: 'X6 M',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Buick',
    model: 'Cascada',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Buick',
    model: 'Encore',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Buick',
    model: 'Envision',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Buick',
    model: 'Regal Sportback',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Buick',
    model: 'Regal TourX',
    body_styles: ['Wagon']
  },
  {
    year: 2018,
    make: 'Cadillac',
    model: 'ATS',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2018,
    make: 'Cadillac',
    model: 'ATS-V',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2018,
    make: 'Cadillac',
    model: 'CT6',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Cadillac',
    model: 'CTS-V',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Cadillac',
    model: 'XT5',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Cadillac',
    model: 'XTS',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Bolt EV',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'City Express',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Cruze',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Silverado 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Sonic',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Spark',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Suburban',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Suburban 3500HD',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Trax',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Chevrolet',
    model: 'Volt',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Chrysler',
    model: 'Pacifica',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Chrysler',
    model: 'Pacifica Hybrid',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Dodge',
    model: 'Journey',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Ferrari',
    model: '488 GTB',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Ferrari',
    model: '488 Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'Ferrari',
    model: '812 Superfast',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Ferrari',
    model: 'GTC4Lusso',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'FIAT',
    model: '124 Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'FIAT',
    model: '500',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'FIAT',
    model: '500 Abarth',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'FIAT',
    model: '500c',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'FIAT',
    model: '500c Abarth',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'FIAT',
    model: '500e',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'FIAT',
    model: '500L',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'FIAT',
    model: '500X',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'C-MAX Hybrid',
    body_styles: ['Wagon']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'EcoSport',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Expedition MAX',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Fiesta',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Flex',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Focus',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Fusion Energi',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Transit 150 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Transit 150 Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Transit 250 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Transit 350 HD Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Transit 350 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Transit 350 Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Transit Connect Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Ford',
    model: 'Transit Connect Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Freightliner',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Freightliner',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Freightliner',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Freightliner',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Freightliner',
    model: 'Sprinter 3500XD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Freightliner',
    model: 'Sprinter WORKER Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Genesis',
    model: 'G80',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Genesis',
    model: 'G90',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Sierra 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Sierra 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Sierra 3500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'GMC',
    model: 'Yukon XL',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Honda',
    model: 'Accord Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Hatchback', 'Sedan']
  },
  {
    year: 2018,
    make: 'Honda',
    model: 'Civic Type R',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Honda',
    model: 'Clarity Plug-in Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Honda',
    model: 'Fit',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Honda',
    model: 'HR-V',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Hyundai',
    model: 'Elantra GT',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Hyundai',
    model: 'Ioniq Electric',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Hyundai',
    model: 'Ioniq Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Hyundai',
    model: 'Ioniq Plug-in Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Hyundai',
    model: 'Kona',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Hyundai',
    model: 'Santa Fe Sport',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Hyundai',
    model: 'Sonata Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Hyundai',
    model: 'Sonata Plug-in Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'INFINITI',
    model: 'Q50',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'INFINITI',
    model: 'Q60',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'INFINITI',
    model: 'Q70',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'INFINITI',
    model: 'QX30',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'INFINITI',
    model: 'QX60',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'INFINITI',
    model: 'QX80',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Jaguar',
    model: 'E-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Jaguar',
    model: 'F-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Jaguar',
    model: 'F-TYPE',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'Jaguar',
    model: 'XE',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2018,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Jeep',
    model: 'Renegade',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Jeep',
    model: 'Wrangler Unlimited',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Cadenza',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Forte5',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Niro',
    body_styles: ['Wagon']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Niro Plug-in Hybrid',
    body_styles: ['Wagon']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Optima Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Optima Plug-in Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Soul EV',
    body_styles: ['Wagon']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Kia',
    model: 'Stinger',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Lamborghini',
    model: 'Aventador',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2018,
    make: 'Lamborghini',
    model: 'Huracan',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Land Rover',
    model: 'Discovery Sport',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Land Rover',
    model: 'Range Rover Evoque',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Land Rover',
    model: 'Range Rover Velar',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Lexus',
    model: 'LC',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Lexus',
    model: 'NX',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Lexus',
    model: 'RC',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Lincoln',
    model: 'MKC',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Lincoln',
    model: 'MKT',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Lincoln',
    model: 'MKX',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Lotus',
    model: 'Evora 400',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Maserati',
    model: 'Ghibli',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Maserati',
    model: 'GranTurismo',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'Maserati',
    model: 'Levante',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'MAZDA',
    model: 'CX-3',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'MAZDA',
    model: 'CX-5',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2018,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'MAZDA',
    model: 'MX-5 Miata RF',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'McLaren',
    model: '570GT',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'McLaren',
    model: '570S',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2018,
    make: 'McLaren',
    model: '720S',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Convertible', 'Coupe', 'Sedan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'CLA',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'CLS',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Coupe', 'Convertible', 'Wagon']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'GLA',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'GLC',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'GLC Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'GLE',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'GLS',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG C-Class',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG CLA',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG CLS',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG E-Class',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLA',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLC',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLC Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLE',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLE Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLS',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GT',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG S-Class',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG SL',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG SLC',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Mercedes-Maybach S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Metris Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Metris Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Metris WORKER Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Metris WORKER Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'SL',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'SLC',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 XD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Mercedes-Benz',
    model: 'Sprinter WORKER Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'MINI',
    model: 'Countryman',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'MINI',
    model: 'Hardtop 2 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'MINI',
    model: 'Hardtop 4 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Mitsubishi',
    model: 'Eclipse Cross',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Mitsubishi',
    model: 'Mirage G4',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mitsubishi',
    model: 'Outlander PHEV',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Mitsubishi',
    model: 'Outlander Sport',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: '370Z',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'GT-R',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Kicks',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'LEAF',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'NV1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'NV200',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'NV2500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'NV3500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'NV3500 HD Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Rogue Sport',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'TITAN Single Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'TITAN XD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'TITAN XD King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'TITAN XD Single Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Nissan',
    model: 'Versa Note',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Porsche',
    model: '718 Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'Porsche',
    model: '718 Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2018,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Porsche',
    model: 'Macan',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Porsche',
    model: 'Panamera',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Ram',
    model: '1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ram',
    model: '1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ram',
    model: '1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ram',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ram',
    model: '2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ram',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ram',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ram',
    model: '3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ram',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Ram',
    model: 'ProMaster Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Ram',
    model: 'ProMaster City',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Ram',
    model: 'ProMaster Window Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Rolls-Royce',
    model: 'Dawn',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'Rolls-Royce',
    model: 'Ghost',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Rolls-Royce',
    model: 'Wraith',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'smart',
    model: 'fortwo electric drive',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'smart',
    model: 'fortwo electric drive cabrio',
    body_styles: ['Convertible']
  },
  {
    year: 2018,
    make: 'Subaru',
    model: 'BRZ',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Subaru',
    model: 'Crosstrek',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2018,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Subaru',
    model: 'WRX',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Tesla',
    model: 'Model 3',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Tesla',
    model: 'Model S',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Tesla',
    model: 'Model X',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: '86',
    body_styles: ['Coupe']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Avalon Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Camry Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'C-HR',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Corolla iM',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Highlander Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Mirai',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Prius c',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Prius Prime',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'RAV4 Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Toyota',
    model: 'Yaris iA',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Volkswagen',
    model: 'Atlas',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Volkswagen',
    model: 'Beetle',
    body_styles: ['Hatchback', 'Convertible']
  },
  {
    year: 2018,
    make: 'Volkswagen',
    model: 'e-Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Volkswagen',
    model: 'Golf Alltrack',
    body_styles: ['Wagon']
  },
  {
    year: 2018,
    make: 'Volkswagen',
    model: 'Golf GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Volkswagen',
    model: 'Golf R',
    body_styles: ['Hatchback']
  },
  {
    year: 2018,
    make: 'Volkswagen',
    model: 'Golf SportWagen',
    body_styles: ['Wagon']
  },
  {
    year: 2018,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Volkswagen',
    model: 'Tiguan Limited',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Volvo',
    model: 'S90',
    body_styles: ['Sedan']
  },
  {
    year: 2018,
    make: 'Volvo',
    model: 'V60',
    body_styles: ['Wagon']
  },
  {
    year: 2018,
    make: 'Volvo',
    model: 'V90',
    body_styles: ['Wagon']
  },
  {
    year: 2018,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2018,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Acura',
    model: 'ILX',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Acura',
    model: 'MDX Sport Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Acura',
    model: 'RLX',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Acura',
    model: 'RLX Sport Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Acura',
    model: 'TLX',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Alfa Romeo',
    model: '4C Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'Alfa Romeo',
    model: 'Giulia',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Alfa Romeo',
    model: 'Stelvio',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Aston Martin',
    model: 'DB11',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'Aston Martin',
    model: 'DBS Superleggera',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Convertible', 'Sedan']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'A4 allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'A7',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'e-tron',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'Q3',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'Q8',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'RS 3',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'RS 5',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'S3',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'SQ5',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Bentley',
    model: 'Bentayga',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Bentley',
    model: 'Mulsanne',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'BMW',
    model: '2 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2019,
    make: 'BMW',
    model: '4 Series',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 2019,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'BMW',
    model: '6 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'BMW',
    model: '8 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'i3',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'i8',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'M2',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'M4',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'M6',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'X1',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'X2',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'X4',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'X6 M',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'X7',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'Buick',
    model: 'Cascada',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Buick',
    model: 'Encore',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Buick',
    model: 'Envision',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Buick',
    model: 'LaCrosse',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Buick',
    model: 'Regal Sportback',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Buick',
    model: 'Regal TourX',
    body_styles: ['Wagon']
  },
  {
    year: 2019,
    make: 'Cadillac',
    model: 'ATS',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Cadillac',
    model: 'ATS-V',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Cadillac',
    model: 'CT6',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Cadillac',
    model: 'CT6-V',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Cadillac',
    model: 'CTS',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Cadillac',
    model: 'CTS-V',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Cadillac',
    model: 'XT4',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Cadillac',
    model: 'XT5',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Cadillac',
    model: 'XTS',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Bolt EV',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Cruze',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Silverado 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Silverado 1500 LD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Sonic',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Spark',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Suburban',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Suburban 3500HD',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Trax',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Chevrolet',
    model: 'Volt',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Chrysler',
    model: 'Pacifica',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Chrysler',
    model: 'Pacifica Hybrid',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Dodge',
    model: 'Journey',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Ferrari',
    model: '488 GTB',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Ferrari',
    model: '488 Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'Ferrari',
    model: '812 Superfast',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Ferrari',
    model: 'GTC4Lusso',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'FIAT',
    model: '124 Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'FIAT',
    model: '500',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'FIAT',
    model: '500 Abarth',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'FIAT',
    model: '500c',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'FIAT',
    model: '500c Abarth',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'FIAT',
    model: '500e',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'FIAT',
    model: '500L',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'FIAT',
    model: '500X',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'EcoSport',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Expedition MAX',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'F450 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Fiesta',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Flex',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Fusion Energi',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Ranger SuperCab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Ranger SuperCrew',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Taurus',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Transit 150 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Transit 150 Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Transit 250 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Transit 350 HD Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Transit 350 Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Transit 350 Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Transit Connect Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Ford',
    model: 'Transit Connect Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Freightliner',
    model: 'Sprinter 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Freightliner',
    model: 'Sprinter 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Freightliner',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Freightliner',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Freightliner',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Freightliner',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Freightliner',
    model: 'Sprinter 3500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Freightliner',
    model: 'Sprinter 3500 XD Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Freightliner',
    model: 'Sprinter 3500XD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Freightliner',
    model: 'Sprinter 4500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Freightliner',
    model: 'Sprinter 4500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Genesis',
    model: 'G70',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Genesis',
    model: 'G80',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Genesis',
    model: 'G90',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Sierra 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Sierra 1500 Limited Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Sierra 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'GMC',
    model: 'Yukon XL',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'Accord Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Coupe', 'Sedan', 'Hatchback']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'Civic Type R',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'Clarity Electric',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'Clarity Fuel Cell',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'Clarity Plug-in Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'Fit',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'HR-V',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Elantra GT',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Ioniq Electric',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Ioniq Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Ioniq Plug-in Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Kona',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Kona Electric',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'NEXO',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Santa Fe XL',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Sonata Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Sonata Plug-in Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Hyundai',
    model: 'Veloster',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'INFINITI',
    model: 'Q50',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'INFINITI',
    model: 'Q60',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'INFINITI',
    model: 'Q70',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'INFINITI',
    model: 'QX30',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'INFINITI',
    model: 'QX50',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'INFINITI',
    model: 'QX60',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'INFINITI',
    model: 'QX80',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Jaguar',
    model: 'E-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Jaguar',
    model: 'F-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Jaguar',
    model: 'F-TYPE',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2019,
    make: 'Jaguar',
    model: 'I-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Jaguar',
    model: 'XE',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2019,
    make: 'Jaguar',
    model: 'XJ',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Jeep',
    model: 'Renegade',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Jeep',
    model: 'Wrangler Unlimited',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Cadenza',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'K900',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Niro',
    body_styles: ['Wagon']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Niro EV',
    body_styles: ['Wagon']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Niro Plug-in Hybrid',
    body_styles: ['Wagon']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Optima Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Optima Plug-in Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Soul EV',
    body_styles: ['Wagon']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Kia',
    model: 'Stinger',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Lamborghini',
    model: 'Aventador',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'Lamborghini',
    model: 'Huracan',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2019,
    make: 'Lamborghini',
    model: 'Urus',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Land Rover',
    model: 'Discovery Sport',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Land Rover',
    model: 'Range Rover Evoque',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Land Rover',
    model: 'Range Rover Velar',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Lexus',
    model: 'LC',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Lexus',
    model: 'NX',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Lexus',
    model: 'RC',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Lexus',
    model: 'UX',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Lincoln',
    model: 'MKC',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Lincoln',
    model: 'MKT',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Lincoln',
    model: 'Nautilus',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Maserati',
    model: 'Ghibli',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Maserati',
    model: 'GranTurismo',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'Maserati',
    model: 'Levante',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'MAZDA',
    model: 'CX-3',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'MAZDA',
    model: 'CX-5',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2019,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'MAZDA',
    model: 'MX-5 Miata RF',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'McLaren',
    model: '570GT',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'McLaren',
    model: '570S',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'McLaren',
    model: '600LT',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'McLaren',
    model: '720S',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'A-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'CLA',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'CLS',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Coupe', 'Sedan', 'Wagon', 'Convertible']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'GLA',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'GLC',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'GLC Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'GLE',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'GLS',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG C-Class',
    body_styles: ['Sedan', 'Convertible', 'Coupe']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG CLA',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG CLS',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG E-Class',
    body_styles: ['Coupe', 'Convertible', 'Sedan', 'Wagon']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLA',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLC',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLC Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLE',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLE Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLS',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GT',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG S-Class',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG SL',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG SLC',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Mercedes-Maybach S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Metris Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Metris Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Metris WORKER Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Metris WORKER Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'SL',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'SLC',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Sprinter 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Sprinter 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 XD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 XD Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Sprinter 4500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Mercedes-Benz',
    model: 'Sprinter 4500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'MINI',
    model: 'Countryman',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'MINI',
    model: 'Hardtop 2 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'MINI',
    model: 'Hardtop 4 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Mitsubishi',
    model: 'Eclipse Cross',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Mitsubishi',
    model: 'Mirage G4',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mitsubishi',
    model: 'Outlander PHEV',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Mitsubishi',
    model: 'Outlander Sport',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: '370Z',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'GT-R',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Kicks',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'LEAF',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'NV1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'NV200',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'NV200 Taxi',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'NV2500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'NV3500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'NV3500 HD Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Rogue Sport',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'TITAN Single Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'TITAN XD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'TITAN XD King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'TITAN XD Single Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Nissan',
    model: 'Versa Note',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Porsche',
    model: '718 Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'Porsche',
    model: '718 Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2019,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Porsche',
    model: 'Macan',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Porsche',
    model: 'Panamera',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Ram',
    model: '1500 Classic Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ram',
    model: '1500 Classic Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ram',
    model: '1500 Classic Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ram',
    model: '1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ram',
    model: '1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ram',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ram',
    model: '2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ram',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ram',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ram',
    model: '3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ram',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Ram',
    model: 'ProMaster Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Ram',
    model: 'ProMaster City',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Ram',
    model: 'ProMaster Window Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Rolls-Royce',
    model: 'Cullinan',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Rolls-Royce',
    model: 'Dawn',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'Rolls-Royce',
    model: 'Ghost',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Rolls-Royce',
    model: 'Wraith',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'smart',
    model: 'fortwo EQ cabrio',
    body_styles: ['Convertible']
  },
  {
    year: 2019,
    make: 'smart',
    model: 'fortwo EQ coupe',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Subaru',
    model: 'Ascent',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Subaru',
    model: 'BRZ',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Subaru',
    model: 'Crosstrek',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2019,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Subaru',
    model: 'WRX',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Tesla',
    model: 'Model 3',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Tesla',
    model: 'Model S',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Tesla',
    model: 'Model X',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: '86',
    body_styles: ['Coupe']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Avalon Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Camry Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'C-HR',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Corolla Hatchback',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Highlander Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Mirai',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Prius c',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Prius Prime',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'RAV4 Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2019,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Volkswagen',
    model: 'Arteon',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Volkswagen',
    model: 'Atlas',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Volkswagen',
    model: 'Beetle',
    body_styles: ['Convertible', 'Coupe', 'Hatchback']
  },
  {
    year: 2019,
    make: 'Volkswagen',
    model: 'e-Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Volkswagen',
    model: 'Golf Alltrack',
    body_styles: ['Wagon']
  },
  {
    year: 2019,
    make: 'Volkswagen',
    model: 'Golf GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Volkswagen',
    model: 'Golf R',
    body_styles: ['Hatchback']
  },
  {
    year: 2019,
    make: 'Volkswagen',
    model: 'Golf SportWagen',
    body_styles: ['Wagon']
  },
  {
    year: 2019,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Volkswagen',
    model: 'Jetta GLI',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Volvo',
    model: 'S90',
    body_styles: ['Sedan']
  },
  {
    year: 2019,
    make: 'Volvo',
    model: 'V60',
    body_styles: ['Wagon']
  },
  {
    year: 2019,
    make: 'Volvo',
    model: 'V90',
    body_styles: ['Wagon']
  },
  {
    year: 2019,
    make: 'Volvo',
    model: 'XC40',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2019,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Acura',
    model: 'ILX',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Acura',
    model: 'MDX Sport Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Acura',
    model: 'RLX',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Acura',
    model: 'RLX Sport Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Acura',
    model: 'TLX',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Alfa Romeo',
    model: '4C Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2020,
    make: 'Alfa Romeo',
    model: 'Giulia',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Alfa Romeo',
    model: 'Stelvio',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'A6 allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'A7',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'Q3',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'Q8',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'R8',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'SQ5',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'BMW',
    model: '2 Series',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2020,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'BMW',
    model: '4 Series',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2020,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'BMW',
    model: '8 Series',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 2020,
    make: 'BMW',
    model: 'M2',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'BMW',
    model: 'X1',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'BMW',
    model: 'X2',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'BMW',
    model: 'X3 M',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'BMW',
    model: 'X4',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'BMW',
    model: 'X7',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2020,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Buick',
    model: 'Encore',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Buick',
    model: 'Encore GX',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Buick',
    model: 'Envision',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Buick',
    model: 'Regal Sportback',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Buick',
    model: 'Regal TourX',
    body_styles: ['Wagon']
  },
  {
    year: 2020,
    make: 'Cadillac',
    model: 'CT4',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Cadillac',
    model: 'CT5',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Cadillac',
    model: 'CT6',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Cadillac',
    model: 'CT6-V',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Cadillac',
    model: 'XT4',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Cadillac',
    model: 'XT5',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Cadillac',
    model: 'XT6',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Bolt EV',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Impala',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Silverado 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Sonic',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Spark',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Suburban',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Chevrolet',
    model: 'Trax',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Chrysler',
    model: 'Pacifica',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Chrysler',
    model: 'Pacifica Hybrid',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Chrysler',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Dodge',
    model: 'Grand Caravan Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Dodge',
    model: 'Journey',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'FIAT',
    model: '124 Spider',
    body_styles: ['Convertible']
  },
  {
    year: 2020,
    make: 'FIAT',
    model: '500L',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'FIAT',
    model: '500X',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'EcoSport',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Expedition MAX',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Fusion',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Fusion Plug-in Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Ranger SuperCab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Ranger SuperCrew',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Transit 250 Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Transit 350 Passenger Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Transit Connect Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Ford',
    model: 'Transit Connect Passenger Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Freightliner',
    model: 'Sprinter 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Freightliner',
    model: 'Sprinter 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Freightliner',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Freightliner',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Freightliner',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Freightliner',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Freightliner',
    model: 'Sprinter 3500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Freightliner',
    model: 'Sprinter 3500 XD Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Freightliner',
    model: 'Sprinter 3500XD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Freightliner',
    model: 'Sprinter 4500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Freightliner',
    model: 'Sprinter 4500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Genesis',
    model: 'G70',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Genesis',
    model: 'G80',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Genesis',
    model: 'G90',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'GMC',
    model: 'Sierra 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'GMC',
    model: 'Yukon XL',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'Accord Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Hatchback', 'Coupe', 'Sedan']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'Civic Type R',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'Clarity Fuel Cell',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'Clarity Plug-in Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'CR-V Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'Fit',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'HR-V',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Elantra GT',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Ioniq Electric',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Ioniq Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Ioniq Plug-in Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Kona',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Kona Electric',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'NEXO',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Palisade',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Sonata Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Veloster',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'Hyundai',
    model: 'Venue',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'INFINITI',
    model: 'Q50',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'INFINITI',
    model: 'Q60',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'INFINITI',
    model: 'QX50',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'INFINITI',
    model: 'QX60',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'INFINITI',
    model: 'QX80',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Jaguar',
    model: 'E-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Jaguar',
    model: 'F-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Jaguar',
    model: 'F-TYPE',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2020,
    make: 'Jaguar',
    model: 'I-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Jaguar',
    model: 'XE',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2020,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Jeep',
    model: 'Gladiator',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Jeep',
    model: 'Renegade',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Jeep',
    model: 'Wrangler Unlimited',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Cadenza',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'K900',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Niro',
    body_styles: ['Wagon']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Niro EV',
    body_styles: ['Wagon']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Niro Plug-in Hybrid',
    body_styles: ['Wagon']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Optima',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Optima Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Optima Plug-in Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Stinger',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Kia',
    model: 'Telluride',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Land Rover',
    model: 'Defender 110',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Land Rover',
    model: 'Defender 90',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Land Rover',
    model: 'Discovery Sport',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Land Rover',
    model: 'Range Rover Evoque',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Land Rover',
    model: 'Range Rover Velar',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Lexus',
    model: 'GS',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Lexus',
    model: 'LC',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Lexus',
    model: 'NX',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Lexus',
    model: 'RC',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Lexus',
    model: 'UX',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Lincoln',
    model: 'Aviator',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Lincoln',
    model: 'Continental',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Lincoln',
    model: 'Corsair',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Lincoln',
    model: 'MKZ',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Lincoln',
    model: 'Nautilus',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'MAZDA',
    model: 'CX-3',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'MAZDA',
    model: 'CX-30',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'MAZDA',
    model: 'CX-5',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2020,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2020,
    make: 'MAZDA',
    model: 'MX-5 Miata RF',
    body_styles: ['Convertible']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'A-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Sedan', 'Convertible', 'Coupe']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'CLA',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'CLS',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Coupe', 'Wagon', 'Convertible']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'GLA',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'GLB',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'GLC',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'GLE',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'GLS',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG A-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG C-Class',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG CLS',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLC',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLC Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Metris WORKER Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Metris WORKER Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'SLC',
    body_styles: ['Convertible']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Sprinter 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Sprinter 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 XD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 XD Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Sprinter 4500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Mercedes-Benz',
    model: 'Sprinter 4500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'MINI',
    model: 'Countryman',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'MINI',
    model: 'Hardtop 2 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'MINI',
    model: 'Hardtop 4 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Mitsubishi',
    model: 'Eclipse Cross',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Mitsubishi',
    model: 'Mirage G4',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Mitsubishi',
    model: 'Outlander PHEV',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Mitsubishi',
    model: 'Outlander Sport',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: '370Z',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'GT-R',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Kicks',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'LEAF',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'NV1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'NV200',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'NV2500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'NV3500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'NV3500 HD Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Rogue Sport',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'TITAN XD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Porsche',
    model: '718 Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2020,
    make: 'Porsche',
    model: '718 Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'Porsche',
    model: '718 Spyder',
    body_styles: ['Convertible']
  },
  {
    year: 2020,
    make: 'Porsche',
    model: '911',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2020,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Porsche',
    model: 'Cayenne Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Porsche',
    model: 'Macan',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Porsche',
    model: 'Panamera',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Porsche',
    model: 'Taycan',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Ram',
    model: '1500 Classic Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ram',
    model: '1500 Classic Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ram',
    model: '1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ram',
    model: '1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ram',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ram',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Ram',
    model: 'ProMaster Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Ram',
    model: 'ProMaster City',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Subaru',
    model: 'Ascent',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Subaru',
    model: 'BRZ',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'Subaru',
    model: 'Crosstrek',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2020,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Subaru',
    model: 'WRX',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Tesla',
    model: 'Model 3',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Tesla',
    model: 'Model S',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Tesla',
    model: 'Model X',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Tesla',
    model: 'Model Y',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: '86',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Avalon Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Camry Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'C-HR',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Corolla Hatchback',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Corolla Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'GR Supra',
    body_styles: ['Coupe']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Highlander Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Mirai',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Prius Prime',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'RAV4 Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Yaris',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Toyota',
    model: 'Yaris Hatchback',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Volkswagen',
    model: 'Arteon',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Volkswagen',
    model: 'Atlas',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Volkswagen',
    model: 'Atlas Cross Sport',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Volkswagen',
    model: 'Golf GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2020,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Volkswagen',
    model: 'Jetta GLI',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Volvo',
    model: 'S90',
    body_styles: ['Sedan']
  },
  {
    year: 2020,
    make: 'Volvo',
    model: 'V60',
    body_styles: ['Wagon']
  },
  {
    year: 2020,
    make: 'Volvo',
    model: 'V90',
    body_styles: ['Wagon']
  },
  {
    year: 2020,
    make: 'Volvo',
    model: 'XC40',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2020,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Acura',
    model: 'ILX',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Acura',
    model: 'TLX',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Alfa Romeo',
    model: 'Giulia',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Alfa Romeo',
    model: 'Stelvio',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Aston Martin',
    model: 'DB11',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2021,
    make: 'Aston Martin',
    model: 'DBS Superleggera',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2021,
    make: 'Aston Martin',
    model: 'DBX',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'A4 allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'A6 allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'A7',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'e-tron',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'e-tron Sportback',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'Q3',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'Q5 Sportback',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'Q8',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'R8',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'RS 5',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'RS 6',
    body_styles: ['Wagon']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'RS 7',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'RS Q8',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Convertible', 'Coupe', 'Sedan']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'S7',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'SQ5',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'SQ5 Sportback',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'SQ7',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'SQ8',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Bentley',
    model: 'Bentayga',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Bentley',
    model: 'Continental GT',
    body_styles: ['Convertible']
  },
  {
    year: 2021,
    make: 'Bentley',
    model: 'Flying Spur',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'BMW',
    model: '2 Series',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2021,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'BMW',
    model: '4 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2021,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'BMW',
    model: '8 Series',
    body_styles: ['Sedan', 'Convertible', 'Coupe']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'i3',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'M2',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'M4',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'M8',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'X1',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'X2',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'X3 M',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'X4',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'X4 M',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'X5 M',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'X6 M',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'X7',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2021,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Buick',
    model: 'Encore',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Buick',
    model: 'Encore GX',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Buick',
    model: 'Envision',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Cadillac',
    model: 'CT4',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Cadillac',
    model: 'CT5',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Cadillac',
    model: 'XT4',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Cadillac',
    model: 'XT5',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Cadillac',
    model: 'XT6',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Bolt EV',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Silverado 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Spark',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Suburban',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Trailblazer',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Chevrolet',
    model: 'Trax',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Chrysler',
    model: 'Pacifica',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Chrysler',
    model: 'Pacifica Hybrid',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Chrysler',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Ferrari',
    model: '488 Pista',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2021,
    make: 'Ferrari',
    model: '812 GTS',
    body_styles: ['Convertible']
  },
  {
    year: 2021,
    make: 'Ferrari',
    model: '812 Superfast',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Ferrari',
    model: 'F8',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2021,
    make: 'Ferrari',
    model: 'Portofino',
    body_styles: ['Convertible']
  },
  {
    year: 2021,
    make: 'Ferrari',
    model: 'Roma',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Ferrari',
    model: 'SF90',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2021,
    make: 'FIAT',
    model: '500X',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Bronco',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Bronco Sport',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'EcoSport',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Escape Plug-in Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Expedition MAX',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'F450 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Mustang MACH-E',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Ranger SuperCab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Ranger SuperCrew',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Transit 150 Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Transit 150 Crew Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Transit 150 Passenger Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Transit 250 Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Transit 250 Crew Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Transit 350 Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Transit 350 Crew Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Transit 350 HD Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Transit 350 HD Crew Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Transit 350 Passenger Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Transit Connect Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Ford',
    model: 'Transit Connect Passenger Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Freightliner',
    model: 'Sprinter 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Freightliner',
    model: 'Sprinter 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Freightliner',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Freightliner',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Freightliner',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Freightliner',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Freightliner',
    model: 'Sprinter 3500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Freightliner',
    model: 'Sprinter 3500 XD Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Freightliner',
    model: 'Sprinter 3500XD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Freightliner',
    model: 'Sprinter 4500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Freightliner',
    model: 'Sprinter 4500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Genesis',
    model: 'G70',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Genesis',
    model: 'G80',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Genesis',
    model: 'G90',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Genesis',
    model: 'GV80',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Sierra 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Sierra 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Sierra 3500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'GMC',
    model: 'Yukon XL',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'Accord Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'Civic Type R',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'Clarity Fuel Cell',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'Clarity Plug-in Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'CR-V Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'HR-V',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Ioniq Electric',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Ioniq Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Ioniq Plug-in Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Kona',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Kona Electric',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'NEXO',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Palisade',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Santa Fe Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Sonata Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Veloster',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Hyundai',
    model: 'Venue',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'INFINITI',
    model: 'Q50',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'INFINITI',
    model: 'Q60',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'INFINITI',
    model: 'QX50',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'INFINITI',
    model: 'QX80',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Jaguar',
    model: 'E-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Jaguar',
    model: 'F-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Jaguar',
    model: 'F-TYPE',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2021,
    make: 'Jaguar',
    model: 'I-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Jeep',
    model: 'Gladiator',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Jeep',
    model: 'Grand Cherokee L',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Jeep',
    model: 'Renegade',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Jeep',
    model: 'Wrangler Unlimited',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Jeep',
    model: 'Wrangler Unlimited 4xe',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'K5',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'Niro',
    body_styles: ['Wagon']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'Niro EV',
    body_styles: ['Wagon']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'Niro Plug-in Hybrid',
    body_styles: ['Wagon']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'Sedona',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'Seltos',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'Sorento Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'Stinger',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Kia',
    model: 'Telluride',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Lamborghini',
    model: 'Aventador',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2021,
    make: 'Lamborghini',
    model: 'Huracan',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2021,
    make: 'Lamborghini',
    model: 'Urus',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Land Rover',
    model: 'Defender 110',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Land Rover',
    model: 'Defender 90',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Land Rover',
    model: 'Discovery Sport',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Land Rover',
    model: 'Range Rover Evoque',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Land Rover',
    model: 'Range Rover Velar',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Lexus',
    model: 'LC',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2021,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Lexus',
    model: 'NX',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Lexus',
    model: 'RC',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Lexus',
    model: 'UX',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Lincoln',
    model: 'Aviator',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Lincoln',
    model: 'Corsair',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Lincoln',
    model: 'Nautilus',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Lotus',
    model: 'Evora GT',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Maserati',
    model: 'Ghibli',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Maserati',
    model: 'Levante',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'MAZDA',
    model: 'CX-3',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'MAZDA',
    model: 'CX-30',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'MAZDA',
    model: 'CX-5',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Hatchback', 'Sedan']
  },
  {
    year: 2021,
    make: 'MAZDA',
    model: 'MAZDA6',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2021,
    make: 'MAZDA',
    model: 'MX-5 Miata RF',
    body_styles: ['Convertible']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'A-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'CLA',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'CLS',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Coupe', 'Wagon', 'Convertible']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'GLA',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'GLB',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'GLC',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'GLC Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'GLE',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'GLS',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG A-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG C-Class',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG CLA',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG CLS',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG E-Class',
    body_styles: ['Wagon', 'Convertible', 'Sedan', 'Coupe']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLA',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLB',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLC',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLC Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLE',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLE Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLS',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GT',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG S-Class',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-Maybach GLS',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Mercedes-Maybach S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Metris Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Metris Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Sprinter 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Sprinter 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 XD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 XD Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Sprinter 4500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Mercedes-Benz',
    model: 'Sprinter 4500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2021,
    make: 'MINI',
    model: 'Countryman',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'MINI',
    model: 'Hardtop 2 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'MINI',
    model: 'Hardtop 4 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Mitsubishi',
    model: 'Mirage G4',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Mitsubishi',
    model: 'Outlander PHEV',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Mitsubishi',
    model: 'Outlander Sport',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'GT-R',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'Kicks',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'LEAF',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'NV1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'NV200',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'NV2500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'NV3500 HD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'NV3500 HD Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'Rogue Sport',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'TITAN XD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Polestar',
    model: '1',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Polestar',
    model: '2',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Porsche',
    model: '718 Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2021,
    make: 'Porsche',
    model: '718 Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Porsche',
    model: '718 Spyder',
    body_styles: ['Convertible']
  },
  {
    year: 2021,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2021,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Porsche',
    model: 'Cayenne Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Porsche',
    model: 'Macan',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Porsche',
    model: 'Panamera',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Porsche',
    model: 'Taycan',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Porsche',
    model: 'Taycan Cross Turismo',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Ram',
    model: '1500 Classic Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ram',
    model: '1500 Classic Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ram',
    model: '1500 Classic Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ram',
    model: '1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ram',
    model: '1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ram',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ram',
    model: '2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ram',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ram',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ram',
    model: '3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ram',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Ram',
    model: 'ProMaster Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Ram',
    model: 'ProMaster City',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Ram',
    model: 'ProMaster Window Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Rivian',
    model: 'R1S',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Rivian',
    model: 'R1T',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Rolls-Royce',
    model: 'Cullinan',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Rolls-Royce',
    model: 'Dawn',
    body_styles: ['Convertible']
  },
  {
    year: 2021,
    make: 'Rolls-Royce',
    model: 'Ghost',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Rolls-Royce',
    model: 'Wraith',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Subaru',
    model: 'Ascent',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Subaru',
    model: 'Crosstrek',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Wagon', 'Sedan']
  },
  {
    year: 2021,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Subaru',
    model: 'WRX',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Tesla',
    model: 'Model 3',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Tesla',
    model: 'Model S',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Tesla',
    model: 'Model X',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Tesla',
    model: 'Model Y',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Avalon Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Camry Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'C-HR',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Corolla Hatchback',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Corolla Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'GR Supra',
    body_styles: ['Coupe']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Highlander Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Land Cruiser',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Mirai',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Prius Prime',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'RAV4 Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'RAV4 Prime',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2021,
    make: 'Toyota',
    model: 'Venza',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Volkswagen',
    model: 'Arteon',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Volkswagen',
    model: 'Atlas',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Volkswagen',
    model: 'Atlas Cross Sport',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Volkswagen',
    model: 'Golf',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Volkswagen',
    model: 'Golf GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2021,
    make: 'Volkswagen',
    model: 'ID.4',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Volkswagen',
    model: 'Jetta GLI',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Volvo',
    model: 'S90',
    body_styles: ['Sedan']
  },
  {
    year: 2021,
    make: 'Volvo',
    model: 'V60',
    body_styles: ['Wagon']
  },
  {
    year: 2021,
    make: 'Volvo',
    model: 'V90',
    body_styles: ['Wagon']
  },
  {
    year: 2021,
    make: 'Volvo',
    model: 'XC40',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Volvo',
    model: 'XC40 Recharge',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2021,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Acura',
    model: 'ILX',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Acura',
    model: 'NSX',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Acura',
    model: 'TLX',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Alfa Romeo',
    model: 'Giulia',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Alfa Romeo',
    model: 'Stelvio',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Aston Martin',
    model: 'DB11',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Aston Martin',
    model: 'DBS',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Aston Martin',
    model: 'DBX',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Aston Martin',
    model: 'Vantage',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'A4 allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'A6 allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'A7',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'e-tron',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'e-tron GT',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'e-tron S',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'e-tron S Sportback',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'e-tron Sportback',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'Q3',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'Q4 e-tron',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'Q4 Sportback e-tron',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'Q5 Sportback',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'Q8',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'R8',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'RS 3',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'RS 5',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'RS 6',
    body_styles: ['Wagon']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'RS 7',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'RS e-tron GT',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'RS Q8',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'S3',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Sedan', 'Convertible', 'Coupe']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'S7',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'SQ5',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'SQ5 Sportback',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'SQ7',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'SQ8',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Bentley',
    model: 'Bentayga',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Bentley',
    model: 'Continental GT',
    body_styles: ['Convertible']
  },
  {
    year: 2022,
    make: 'Bentley',
    model: 'Flying Spur',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'BMW',
    model: '2 Series',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2022,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'BMW',
    model: '4 Series',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'BMW',
    model: '8 Series',
    body_styles: ['Sedan', 'Convertible', 'Coupe']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'i4',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'iX',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'M4',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'M8',
    body_styles: ['Convertible', 'Hatchback', 'Coupe']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'X1',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'X2',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'X3 M',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'X4',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'X4 M',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'X5 M',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'X6 M',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'X7',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'BMW',
    model: 'Z4',
    body_styles: ['Convertible']
  },
  {
    year: 2022,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Buick',
    model: 'Encore',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Buick',
    model: 'Encore GX',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Buick',
    model: 'Envision',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Cadillac',
    model: 'CT4',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Cadillac',
    model: 'CT5',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Cadillac',
    model: 'XT4',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Cadillac',
    model: 'XT5',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Cadillac',
    model: 'XT6',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Bolt EUV',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Bolt EV',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Colorado Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Express 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Express 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Silverado 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Silverado 1500 Limited Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Silverado 1500 Limited Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Silverado 1500 Limited Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Spark',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Suburban',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Trailblazer',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Chevrolet',
    model: 'Trax',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Chrysler',
    model: 'Pacifica',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Chrysler',
    model: 'Pacifica Hybrid',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Chrysler',
    model: 'Voyager',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Ferrari',
    model: '296 GTB',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Ferrari',
    model: '812 Competizione',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Ferrari',
    model: '812 Competizione A',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Ferrari',
    model: '812 GTS',
    body_styles: ['Convertible']
  },
  {
    year: 2022,
    make: 'Ferrari',
    model: 'F8',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2022,
    make: 'Ferrari',
    model: 'Portofino',
    body_styles: ['Convertible']
  },
  {
    year: 2022,
    make: 'Ferrari',
    model: 'Roma',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Ferrari',
    model: 'SF90',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'FIAT',
    model: '500X',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Bronco',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Bronco Sport',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'EcoSport',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Escape Plug-in Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'E-Transit 350 Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Expedition MAX',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'F150 Lightning',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'F150 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'F150 Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'F250 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'F250 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'F250 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'F350 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'F350 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'F350 Super Duty Super Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'F450 Super Duty Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'F450 Super Duty Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Maverick',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Mustang MACH-E',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Ranger SuperCab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Ranger SuperCrew',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Transit 150 Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Transit 150 Crew Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Transit 150 Passenger Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Transit 250 Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Transit 250 Crew Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Transit 350 Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Transit 350 Crew Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Transit 350 HD Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Transit 350 HD Crew Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Transit 350 Passenger Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Transit Connect Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ford',
    model: 'Transit Connect Passenger Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Genesis',
    model: 'Electrified G80',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Genesis',
    model: 'G70',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Genesis',
    model: 'G80',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Genesis',
    model: 'G90',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Genesis',
    model: 'GV60',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Genesis',
    model: 'GV70',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Genesis',
    model: 'GV80',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Canyon Extended Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Hummer EV Pickup',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Sierra 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Sierra 1500 Limited Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Sierra 1500 Limited Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Sierra 1500 Limited Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Sierra 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Sierra 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Sierra 3500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Sierra 3500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'GMC',
    model: 'Yukon XL',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Honda',
    model: 'Accord Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2022,
    make: 'Honda',
    model: 'Civic Type R',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Honda',
    model: 'CR-V Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Honda',
    model: 'HR-V',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Accent',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Elantra N',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'IONIQ 5',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Ioniq Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Ioniq Plug-in Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Kona',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Kona Electric',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Kona N',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'NEXO',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Palisade',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Santa Cruz',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Santa Fe Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Santa Fe Plug-in Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Sonata Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Tucson Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Tucson Plug-in Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Veloster',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Hyundai',
    model: 'Venue',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'INFINITI',
    model: 'Q50',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'INFINITI',
    model: 'Q60',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'INFINITI',
    model: 'QX50',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'INFINITI',
    model: 'QX55',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'INFINITI',
    model: 'QX60',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'INFINITI',
    model: 'QX80',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jaguar',
    model: 'E-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jaguar',
    model: 'F-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jaguar',
    model: 'F-TYPE',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Jaguar',
    model: 'I-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Jeep',
    model: 'Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jeep',
    model: 'Gladiator',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jeep',
    model: 'Grand Cherokee 4xe',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jeep',
    model: 'Grand Cherokee L',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jeep',
    model: 'Grand Wagoneer',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jeep',
    model: 'Renegade',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jeep',
    model: 'Wagoneer',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jeep',
    model: 'Wrangler Unlimited',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Jeep',
    model: 'Wrangler Unlimited 4xe',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Carnival',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'EV6',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'K5',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Niro',
    body_styles: ['Wagon']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Niro EV',
    body_styles: ['Wagon']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Niro Plug-in Hybrid',
    body_styles: ['Wagon']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Seltos',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Sorento Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Sorento Plug-in Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Stinger',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Kia',
    model: 'Telluride',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Lamborghini',
    model: 'Aventador',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Lamborghini',
    model: 'Huracan',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Lamborghini',
    model: 'Urus',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Land Rover',
    model: 'Defender 110',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Land Rover',
    model: 'Defender 90',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Land Rover',
    model: 'Discovery Sport',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Land Rover',
    model: 'Range Rover Evoque',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Land Rover',
    model: 'Range Rover Velar',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Lexus',
    model: 'LC',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Lexus',
    model: 'NX',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Lexus',
    model: 'RC',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Lexus',
    model: 'UX',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Lincoln',
    model: 'Aviator',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Lincoln',
    model: 'Corsair',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Lincoln',
    model: 'Nautilus',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Lucid',
    model: 'Air',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Maserati',
    model: 'Ghibli',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Maserati',
    model: 'Levante',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Maserati',
    model: 'MC20',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Maserati',
    model: 'Quattroporte',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'MAZDA',
    model: 'CX-30',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'MAZDA',
    model: 'CX-5',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan', 'Hatchback']
  },
  {
    year: 2022,
    make: 'MAZDA',
    model: 'MX-30',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'MAZDA',
    model: 'MX-5 Miata',
    body_styles: ['Convertible']
  },
  {
    year: 2022,
    make: 'MAZDA',
    model: 'MX-5 Miata RF',
    body_styles: ['Convertible']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'A-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'CLA',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'CLS',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan', 'Wagon', 'Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'G-Class',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'GLA',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'GLB',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'GLC',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'GLC Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'GLE',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'GLS',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG C-Class',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG CLA',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG E-Class',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG EQS',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLA',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLB',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLC',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLC Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLE',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLE Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GLS',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG GT',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-AMG SL',
    body_styles: ['Convertible']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-EQ EQB',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-EQ EQS',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-Maybach GLS',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Mercedes-Maybach S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Metris Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Metris Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'S-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Sprinter 1500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Sprinter 1500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Sprinter 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 XD Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Sprinter 3500 XD Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Sprinter 4500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Mercedes-Benz',
    model: 'Sprinter 4500 Crew',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2022,
    make: 'MINI',
    model: 'Countryman',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'MINI',
    model: 'Hardtop 2 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'MINI',
    model: 'Hardtop 4 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Mitsubishi',
    model: 'Eclipse Cross',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Mitsubishi',
    model: 'Mirage G4',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mitsubishi',
    model: 'Outlander PHEV',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Mitsubishi',
    model: 'Outlander Sport',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: '400Z',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Kicks',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'LEAF',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Rogue Sport',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'TITAN XD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Polestar',
    model: '2',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Porsche',
    model: '718 Boxster',
    body_styles: ['Convertible']
  },
  {
    year: 2022,
    make: 'Porsche',
    model: '718 Cayman',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Porsche',
    model: '718 Spyder',
    body_styles: ['Convertible']
  },
  {
    year: 2022,
    make: 'Porsche',
    model: '911',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2022,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Porsche',
    model: 'Cayenne Coupe',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Porsche',
    model: 'Macan',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Porsche',
    model: 'Panamera',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Porsche',
    model: 'Taycan',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Porsche',
    model: 'Taycan Cross Turismo',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Ram',
    model: '1500 Classic Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ram',
    model: '1500 Classic Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ram',
    model: '1500 Classic Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ram',
    model: '1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ram',
    model: '1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ram',
    model: '2500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ram',
    model: '2500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ram',
    model: '2500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ram',
    model: '3500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ram',
    model: '3500 Mega Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ram',
    model: '3500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Ram',
    model: 'ProMaster Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ram',
    model: 'ProMaster City',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Ram',
    model: 'ProMaster Window Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Rivian',
    model: 'R1S',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Rivian',
    model: 'R1T',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Rolls-Royce',
    model: 'Cullinan',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Rolls-Royce',
    model: 'Ghost',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Rolls-Royce',
    model: 'Phantom',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Subaru',
    model: 'Ascent',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Subaru',
    model: 'BRZ',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Subaru',
    model: 'Crosstrek',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2022,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Subaru',
    model: 'WRX',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Tesla',
    model: 'Cybertruck',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Tesla',
    model: 'Model 3',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Tesla',
    model: 'Model S',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Tesla',
    model: 'Model X',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Tesla',
    model: 'Model Y',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: '86',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Avalon',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Avalon Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Camry Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'C-HR',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Corolla Cross',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Corolla Hatchback',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Corolla Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'GR Supra',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'GR86',
    body_styles: ['Coupe']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Highlander Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Mirai',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Prius Prime',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'RAV4 Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'RAV4 Prime',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Tacoma Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Tundra Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Tundra Hybrid CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2022,
    make: 'Toyota',
    model: 'Venza',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Volkswagen',
    model: 'Arteon',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Volkswagen',
    model: 'Atlas',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Volkswagen',
    model: 'Atlas Cross Sport',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Volkswagen',
    model: 'Golf GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Volkswagen',
    model: 'Golf R',
    body_styles: ['Hatchback']
  },
  {
    year: 2022,
    make: 'Volkswagen',
    model: 'ID.4',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Volkswagen',
    model: 'Jetta GLI',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Volkswagen',
    model: 'Passat',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Volkswagen',
    model: 'Taos',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Volvo',
    model: 'C40 Recharge',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Volvo',
    model: 'S90',
    body_styles: ['Sedan']
  },
  {
    year: 2022,
    make: 'Volvo',
    model: 'V60',
    body_styles: ['Wagon']
  },
  {
    year: 2022,
    make: 'Volvo',
    model: 'V90',
    body_styles: ['Wagon']
  },
  {
    year: 2022,
    make: 'Volvo',
    model: 'XC40',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Volvo',
    model: 'XC40 Recharge',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2022,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Acura',
    model: 'ILX',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Acura',
    model: 'Integra',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'Acura',
    model: 'MDX',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Acura',
    model: 'RDX',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Acura',
    model: 'TLX',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Alfa Romeo',
    model: 'Giulia',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Alfa Romeo',
    model: 'Stelvio',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Alfa Romeo',
    model: 'Tonale',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'A3',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'A4',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'A4 allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'A5',
    body_styles: ['Coupe', 'Convertible', 'Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'A6',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'A6 allroad',
    body_styles: ['Wagon']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'A7',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'A8',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'e-tron',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'e-tron GT',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'e-tron S',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'e-tron S Sportback',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'e-tron Sportback',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'Q3',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'Q5',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'Q5 Sportback',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'Q7',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'Q8',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'R8',
    body_styles: ['Convertible', 'Coupe']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'RS 3',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'RS 5',
    body_styles: ['Coupe', 'Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'RS 6',
    body_styles: ['Wagon']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'RS 7',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'RS e-tron GT',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'RS Q8',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'S3',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'S4',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'S5',
    body_styles: ['Coupe', 'Sedan', 'Convertible']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'S6',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'S7',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'S8',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'SQ5',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'SQ5 Sportback',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'SQ7',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'SQ8',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Audi',
    model: 'TT',
    body_styles: ['Coupe']
  },
  {
    year: 2023,
    make: 'BMW',
    model: '2 Series',
    body_styles: ['Sedan', 'Coupe']
  },
  {
    year: 2023,
    make: 'BMW',
    model: '3 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'BMW',
    model: '4 Series',
    body_styles: ['Coupe']
  },
  {
    year: 2023,
    make: 'BMW',
    model: '5 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'BMW',
    model: '7 Series',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'BMW',
    model: '8 Series',
    body_styles: ['Sedan', 'Coupe', 'Convertible']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'i4',
    body_styles: ['Coupe']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'i7',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'iX',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'M3',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'M4',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'M5',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'M8',
    body_styles: ['Hatchback', 'Coupe', 'Convertible']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'X1',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'X2',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'X3',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'X3 M',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'X4',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'X5',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'X5 M',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'X6',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'BMW',
    model: 'X7',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Buick',
    model: 'Enclave',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Buick',
    model: 'Encore',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Buick',
    model: 'Encore GX',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Buick',
    model: 'Envision',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Cadillac',
    model: 'CT4',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Cadillac',
    model: 'CT5',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Cadillac',
    model: 'Escalade',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Cadillac',
    model: 'Escalade ESV',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Cadillac',
    model: 'LYRIQ',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Cadillac',
    model: 'XT4',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Cadillac',
    model: 'XT5',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Cadillac',
    model: 'XT6',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Blazer',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Bolt EUV',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Bolt EV',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Camaro',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Colorado Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Corvette',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Equinox',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Express 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Express 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Malibu',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Silverado 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Silverado 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Silverado 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Silverado 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Spark',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Suburban',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Tahoe',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Trailblazer',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Traverse',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Chevrolet',
    model: 'Trax',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Chrysler',
    model: '300',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Chrysler',
    model: 'Pacifica',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'Chrysler',
    model: 'Pacifica Hybrid',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'Dodge',
    model: 'Challenger',
    body_styles: ['Coupe']
  },
  {
    year: 2023,
    make: 'Dodge',
    model: 'Charger',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Dodge',
    model: 'Durango',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Dodge',
    model: 'Hornet',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Bronco',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Bronco Sport',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Edge',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Escape',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'E-Transit 350 Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Expedition',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Expedition MAX',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Explorer',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'F150 Lightning',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'F150 SuperCrew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Maverick',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Mustang MACH-E',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Ranger SuperCab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Ranger SuperCrew',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Transit Connect Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'Ford',
    model: 'Transit Connect Passenger Wagon',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'Genesis',
    model: 'Electrified G80',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Genesis',
    model: 'G70',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Genesis',
    model: 'G80',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Genesis',
    model: 'G90',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Genesis',
    model: 'GV60',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Genesis',
    model: 'GV70',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Genesis',
    model: 'GV80',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Acadia',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Canyon Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Hummer EV Pickup',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Savana 2500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Savana 2500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Savana 3500 Cargo',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Savana 3500 Passenger',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Sierra 1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Sierra 1500 Double Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Sierra 1500 Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Sierra 2500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Sierra 3500 HD Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Terrain',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Yukon',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'GMC',
    model: 'Yukon XL',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Honda',
    model: 'Accord',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Honda',
    model: 'Civic',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Honda',
    model: 'CR-V',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Honda',
    model: 'HR-V',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Honda',
    model: 'Insight',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Honda',
    model: 'Odyssey',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'Honda',
    model: 'Passport',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Honda',
    model: 'Pilot',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Honda',
    model: 'Ridgeline',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Elantra',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Elantra Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'IONIQ 5',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Ioniq Hybrid',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Kona',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Kona Electric',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Kona N',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Palisade',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Santa Cruz',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Santa Fe',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Santa Fe Plug-in Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Sonata',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Sonata Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Tucson',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Tucson Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Tucson Plug-in Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Hyundai',
    model: 'Venue',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'INFINITI',
    model: 'Q50',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'INFINITI',
    model: 'QX50',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'INFINITI',
    model: 'QX55',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'INFINITI',
    model: 'QX60',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jaguar',
    model: 'E-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jaguar',
    model: 'F-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jaguar',
    model: 'F-TYPE',
    body_styles: ['Coupe', 'Convertible']
  },
  {
    year: 2023,
    make: 'Jaguar',
    model: 'I-PACE',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jaguar',
    model: 'XF',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Jeep',
    model: 'Compass',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jeep',
    model: 'Gladiator',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Jeep',
    model: 'Grand Cherokee',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jeep',
    model: 'Grand Cherokee 4xe',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jeep',
    model: 'Grand Cherokee L',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jeep',
    model: 'Grand Wagoneer',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jeep',
    model: 'Grand Wagoneer L',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jeep',
    model: 'Renegade',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jeep',
    model: 'Wagoneer',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jeep',
    model: 'Wagoneer L',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jeep',
    model: 'Wrangler',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jeep',
    model: 'Wrangler Unlimited',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Jeep',
    model: 'Wrangler Unlimited 4xe',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Carnival',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'EV6',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Forte',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'K5',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Niro',
    body_styles: ['Wagon']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Niro EV',
    body_styles: ['Wagon']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Niro Plug-in Hybrid',
    body_styles: ['Wagon']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Rio',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Seltos',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Sorento',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Sorento Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Sorento Plug-in Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Soul',
    body_styles: ['Wagon']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Sportage',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Sportage Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Sportage Plug-in Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Stinger',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Kia',
    model: 'Telluride',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Land Rover',
    model: 'Defender 110',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Land Rover',
    model: 'Defender 130',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Land Rover',
    model: 'Defender 90',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Land Rover',
    model: 'Discovery',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Land Rover',
    model: 'Discovery Sport',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Land Rover',
    model: 'Range Rover',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Land Rover',
    model: 'Range Rover Evoque',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Land Rover',
    model: 'Range Rover Sport',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Land Rover',
    model: 'Range Rover Velar',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Lexus',
    model: 'ES',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Lexus',
    model: 'GX',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Lexus',
    model: 'IS',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Lexus',
    model: 'LS',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Lexus',
    model: 'LX',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Lexus',
    model: 'NX',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Lexus',
    model: 'RX',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Lexus',
    model: 'RZ',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Lexus',
    model: 'TX',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Lexus',
    model: 'UX',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Lincoln',
    model: 'Aviator',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Lincoln',
    model: 'Corsair',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Lincoln',
    model: 'Nautilus',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Lincoln',
    model: 'Navigator',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Lincoln',
    model: 'Navigator L',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Maserati',
    model: 'Grecale',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'MAZDA',
    model: 'CX-30',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'MAZDA',
    model: 'CX-5',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'MAZDA',
    model: 'CX-50',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'MAZDA',
    model: 'CX-9',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'MAZDA',
    model: 'CX-90',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'MAZDA',
    model: 'MAZDA3',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Mercedes-Benz',
    model: 'C-Class',
    body_styles: ['Coupe']
  },
  {
    year: 2023,
    make: 'Mercedes-Benz',
    model: 'E-Class',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Mercedes-Benz',
    model: 'GLA',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Mercedes-Benz',
    model: 'GLB',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Mercedes-Benz',
    model: 'GLC',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Mercedes-Benz',
    model: 'GLE',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Mercedes-Benz',
    model: 'GLS',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Mercedes-Benz',
    model: 'Mercedes-EQ EQB',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Mercedes-Benz',
    model: 'Mercedes-EQ EQE',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Mercedes-Benz',
    model: 'Mercedes-EQ EQE SUV',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Mercedes-Benz',
    model: 'Mercedes-EQ EQS',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Mercedes-Benz',
    model: 'Mercedes-EQ EQS SUV',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'MINI',
    model: 'Clubman',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'MINI',
    model: 'Convertible',
    body_styles: ['Convertible']
  },
  {
    year: 2023,
    make: 'MINI',
    model: 'Countryman',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'MINI',
    model: 'Hardtop 2 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'MINI',
    model: 'Hardtop 4 Door',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'Mitsubishi',
    model: 'Eclipse Cross',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Mitsubishi',
    model: 'Mirage',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'Mitsubishi',
    model: 'Mirage G4',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Mitsubishi',
    model: 'Outlander',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Mitsubishi',
    model: 'Outlander PHEV',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Mitsubishi',
    model: 'Outlander Sport',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Altima',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Ariya',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Armada',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Frontier Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Frontier King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Kicks',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'LEAF',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Maxima',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Murano',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Pathfinder',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Rogue',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Rogue Sport',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Sentra',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Titan Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Titan King Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'TITAN XD Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Versa',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Nissan',
    model: 'Z',
    body_styles: ['Coupe']
  },
  {
    year: 2023,
    make: 'Polestar',
    model: '2',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'Polestar',
    model: '3',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Porsche',
    model: '718 Spyder',
    body_styles: ['Convertible']
  },
  {
    year: 2023,
    make: 'Porsche',
    model: 'Cayenne',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Porsche',
    model: 'Macan',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Ram',
    model: '1500 Classic Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Ram',
    model: '1500 Classic Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Ram',
    model: '1500 Classic Regular Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Ram',
    model: '1500 Crew Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Ram',
    model: '1500 Quad Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Ram',
    model: 'ProMaster Cargo Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'Ram',
    model: 'ProMaster Window Van',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'Subaru',
    model: 'Ascent',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Subaru',
    model: 'BRZ',
    body_styles: ['Coupe']
  },
  {
    year: 2023,
    make: 'Subaru',
    model: 'Crosstrek',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Subaru',
    model: 'Forester',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Subaru',
    model: 'Impreza',
    body_styles: ['Sedan', 'Wagon']
  },
  {
    year: 2023,
    make: 'Subaru',
    model: 'Legacy',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Subaru',
    model: 'Outback',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Subaru',
    model: 'Solterra',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Subaru',
    model: 'WRX',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Tesla',
    model: 'Cybertruck',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Tesla',
    model: 'Model 3',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Tesla',
    model: 'Model S',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Tesla',
    model: 'Model X',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Tesla',
    model: 'Model Y',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: '4Runner',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: '86',
    body_styles: ['Coupe']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'bZ4X',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Camry',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Camry Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'C-HR',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Corolla',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Corolla Cross',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Corolla Cross Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Corolla Hatchback',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Corolla Hybrid',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Crown',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'GR Supra',
    body_styles: ['Coupe']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'GR86',
    body_styles: ['Coupe']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Highlander',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Highlander Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Prius',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'RAV4',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'RAV4 Hybrid',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'RAV4 Prime',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Sequoia',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Sienna',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Tacoma Access Cab',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Tundra CrewMax',
    body_styles: ['Pickup']
  },
  {
    year: 2023,
    make: 'Toyota',
    model: 'Venza',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'VinFast',
    model: 'VF 8',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'VinFast',
    model: 'VF 9',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Volkswagen',
    model: 'Arteon',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Volkswagen',
    model: 'Atlas',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Volkswagen',
    model: 'Atlas Cross Sport',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Volkswagen',
    model: 'Golf GTI',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'Volkswagen',
    model: 'Golf R',
    body_styles: ['Hatchback']
  },
  {
    year: 2023,
    make: 'Volkswagen',
    model: 'ID.4',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Volkswagen',
    model: 'Jetta',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Volkswagen',
    model: 'Jetta GLI',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Volkswagen',
    model: 'Taos',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Volkswagen',
    model: 'Tiguan',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Volvo',
    model: 'C40 Recharge',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Volvo',
    model: 'S60',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Volvo',
    model: 'S90',
    body_styles: ['Sedan']
  },
  {
    year: 2023,
    make: 'Volvo',
    model: 'V60',
    body_styles: ['Wagon']
  },
  {
    year: 2023,
    make: 'Volvo',
    model: 'V90',
    body_styles: ['Wagon']
  },
  {
    year: 2023,
    make: 'Volvo',
    model: 'XC40',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Volvo',
    model: 'XC40 Recharge',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Volvo',
    model: 'XC60',
    body_styles: ['SUV']
  },
  {
    year: 2023,
    make: 'Volvo',
    model: 'XC90',
    body_styles: ['SUV']
  },
  {
    year: 2024,
    make: 'Chevrolet',
    model: 'Blazer EV',
    body_styles: ['SUV']
  },
  {
    year: 2024,
    make: 'Chevrolet',
    model: 'Equinox EV',
    body_styles: ['SUV']
  },
  {
    year: 2024,
    make: 'Chevrolet',
    model: 'Silverado EV',
    body_styles: ['Pickup']
  },
  {
    year: 2024,
    make: 'Ford',
    model: 'Mustang',
    body_styles: ['Coupe']
  },
  {
    year: 2024,
    make: 'GMC',
    model: 'Hummer EV SUV',
    body_styles: ['SUV']
  },
  {
    year: 2024,
    make: 'Honda',
    model: 'Prologue',
    body_styles: ['SUV']
  },
  {
    year: 2024,
    make: 'Hyundai',
    model: 'IONIQ 6',
    body_styles: ['Sedan']
  },
  {
    year: 2024,
    make: 'Hyundai',
    model: 'IONIQ 7',
    body_styles: ['SUV']
  },
  {
    year: 2024,
    make: 'Jeep',
    model: 'Recon',
    body_styles: ['SUV']
  },
  {
    year: 2024,
    make: 'Kia',
    model: 'EV9',
    body_styles: ['SUV']
  },
  {
    year: 2024,
    make: 'Lexus',
    model: 'TX',
    body_styles: ['SUV']
  },
  {
    year: 2024,
    make: 'MAZDA',
    model: 'CX-70',
    body_styles: ['SUV']
  },
  {
    year: 2024,
    make: 'Polestar',
    model: '5',
    body_styles: ['Sedan']
  },
  {
    year: 2024,
    make: 'Subaru',
    model: 'Crosstrek',
    body_styles: ['SUV']
  },
  {
    year: 2024,
    make: 'Volkswagen',
    model: 'ID.Buzz',
    body_styles: ['Van/Minivan']
  },
  {
    year: 2024,
    make: 'Volvo',
    model: 'EX90',
    body_styles: ['SUV']
  }
];
